<pds-module-header title="Transportadoras" ctaPath="/transportadoras" ctaLabel="Voltar" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <ng-container *ngIf="carrier$ | async; let carrier">
    <ng-container *ngIf="carrier.loading">
      <span>carregando...</span>
    </ng-container>

    <ng-container *ngIf="carrier.failure">
      <strong>Ocorreu um erro ao obter os dados da transportadora.</strong>
    </ng-container>

    <ng-container *ngIf="carrier.data">
      <pds-tabs [tabs]="tabs" (selected)="handleTabSelectEvent($event)">
        <ng-container *ngFor="let tab of tabs">
          <section [hidden]="!tab.active">
            <ng-container *ngIf="tab.target === 'details'">
              <peca-carrier-form [form]="form"></peca-carrier-form>
              <hr />
              <pds-form-actions>
                <ng-container *ngIf="form.instance.disabled">
                  <button pdsButton="primary" (click)="onClickChangeStatus()">
                    {{ form.value.active ? 'Desativar' : 'Ativar' }}
                  </button>

                  <button (click)="handleEnableClickEvent()" pdsButton>editar</button>
                </ng-container>
                <ng-container *ngIf="form.instance.enabled">
                  <button (click)="handleSubmitEvent()" [disabled]="form.instance.invalid" pdsButton>salvar</button>
                </ng-container>
              </pds-form-actions>
            </ng-container>

            <ng-container *ngIf="tab.target === 'deliveryTypes'">
              <peca-carrier-delivery-types></peca-carrier-delivery-types>
            </ng-container>
          </section>
        </ng-container>
      </pds-tabs>
    </ng-container>
  </ng-container>
</pds-module-header>
