import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbItem } from '@peca/design-system';
import { ToastService } from '@peca/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { BuyerNaturalPersonForm } from '../../../forms/buyer-natural-person.form';
import { BuyerNaturalPersonService } from '../../../services/buyer-natural-person.service';
import { Subject } from 'rxjs';
import { BuyerNaturalPersonResponse } from '../../../models/buyer-natural-person-response.model';
import { Async } from '@peca/backoffice/utils/async.util';

@Component({
  selector: 'peca-buyer-natural-person-form',
  templateUrl: './buyer-natural-person-form.component.html',
  styleUrls: ['./buyer-natural-person-form.component.scss'],
  standalone: false,
})
export class BuyerNaturalPersonFormComponent implements OnInit, OnDestroy {
  breadcrumb: BreadcrumbItem[];
  form: BuyerNaturalPersonForm;
  buyerService: BuyerNaturalPersonService;
  toast: ToastService;
  router: Router;
  activatedRoute: ActivatedRoute;
  destroyRef$: Subject<void>;
  buyerDetails: Async<void>;

  constructor() {
    this.breadcrumb = [
      { label: 'Compradores', path: '/compradores' },
      { label: 'Pessoa Física', path: '/compradores/pessoa-fisica' },
    ];
    this.form = new BuyerNaturalPersonForm();
    this.buyerService = inject(BuyerNaturalPersonService);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.activatedRoute = inject(ActivatedRoute);
    this.destroyRef$ = new Subject<void>();
    this.buyerDetails = new Async<void>();
  }

  get id() {
    return this.activatedRoute.snapshot.params['id'] as string;
  }

  ngOnInit() {
    this.buyerDetails.loading();

    if (!this.id) {
      this.breadcrumb.push({ label: 'Novo' });
      this.buyerDetails.loaded();
      return;
    }

    this.fetchDetails();
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  fetchDetails() {
    this.buyerDetails.loading();
    this.buyerService.read(this.id).subscribe({
      next: (response: BuyerNaturalPersonResponse) => {
        this.buyerDetails.loaded();
        this.breadcrumb.push({ label: response.name });
        this.form.value = response;
        this.form.group.disable();
        this.form.group.updateValueAndValidity();
      },
      error: () => {
        this.breadcrumb.push({ label: this.id });
        this.buyerDetails.failed();
      },
    });
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;
    const payload = this.form.value;

    this.form.group.disable();
    this.buyerService.save(payload).subscribe({
      next: () => {
        this.toast.success('Comprador cadastrado com sucesso.');
        this.router.navigate(['compradores', 'pessoa-fisica']);
      },
      error: () => {
        this.toast.failure('Falha ao cadastrar comprador.');
        this.form.group.enable();
      },
    });
  }

  onClickEnable() {
    this.form.group.enable();
    this.form.group.updateValueAndValidity();
  }

  onClickCancel() {
    this.router.navigate(['compradores', 'pessoa-fisica']);
  }

  onEventRetry() {
    this.fetchDetails();
  }
}
