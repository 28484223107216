export const shippingTimeValues = [
  {
    id: 'ABOUT_TO_EXPIRE',
    label: 'Para vencer',
  },
  {
    id: 'EXPIRED',
    label: 'Atrasado',
  },
];
