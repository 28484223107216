<ng-container *ngIf="settings$ | async; let settings">
  <ng-container *ngIf="settings.data">
    <pds-form [formGroup]="settingForm.group">
      <pds-form-section title="API">
        <pds-form-row grid="1-1">
          <pds-form-field [errors]="this.settingForm.controls.clientId.invalid ? [{ message: 'Somente letras, espaços e hífens são permitidos.' }] : null" label="Client ID">
            <input type="text" formControlName="clientId" />
          </pds-form-field>
          <pds-form-field action="refresh" label="Client Secret" (clicked)="onClickRefreshClientSecret()">
            <input [type]="this.settingForm.enabled ? 'text' : 'password'" readonly formControlName="clientSecret" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="FTP (Estoque/Preço)">
        <pds-form-row grid="1-1-1-1">
          <pds-form-field label="Host">
            <input type="text" formControlName="host" (change)="valiFTPCredentialsdRequired($event)" (input)="valiFTPCredentialsdRequired($event)" />
          </pds-form-field>
          <pds-form-field label="Usuário">
            <input type="text" formControlName="username" (change)="valiFTPCredentialsdRequired($event)" (input)="valiFTPCredentialsdRequired($event)" />
          </pds-form-field>
          <pds-form-field label="Senha">
            <input [type]="this.settingForm.enabled ? 'text' : 'password'" formControlName="password" (change)="valiFTPCredentialsdRequired($event)" (input)="valiFTPCredentialsdRequired($event)" />
          </pds-form-field>
          <pds-form-field label="Tipo de Integração">
            <select formControlName="type" (change)="valiFTPCredentialsdRequired($event)" (input)="valiFTPCredentialsdRequired($event)">
              <option value="">Selecione</option>
              <option value="FULL">{{ 'FULL' | typeIntegration }}</option>
              <option value="INCREMENTAL">{{ 'INCREMENTAL' | typeIntegration }}</option>
            </select>
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Webhooks" formGroupName="webhooks">
        <pds-form-row grid="1-2">
          <pds-form-field class="service" label="Serviço">
            <input type="text" formControlName="service" [pdsFormImmutable]="true" />
          </pds-form-field>
          <pds-form-field class="url" label="URL">
            <input type="text" formControlName="url" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <hr />

      <pds-form-actions>
        <ng-container>
          @if (settingForm.enabled) {
          <button pdsButton="primary" (click)="onClickCancel()">Cancelar</button>
          <button pdsButton [disabled]="settingForm.group.invalid" (click)="onClickSave()">Salvar</button>
          }@else {
          <button pdsButton (click)="onClickEnable()">Editar</button>
          }
        </ng-container>
      </pds-form-actions>
    </pds-form>
  </ng-container>

  <ng-container *ngIf="!settings.failure && !settings.loading && !settings.data">
    <pds-alert type="info" title="Integração não configurada">
      <span>O vendedor não possui cadastro de configuração de integração.</span>
    </pds-alert>
    <hr />
    <pds-form-actions>
      <button pdsButton (click)="onClickInitSettings()">Configurar</button>
    </pds-form-actions>
  </ng-container>

  <ng-container *ngIf="settings.loading">
    <strong>carregando...</strong>
  </ng-container>

  <ng-container *ngIf="settings.failure">
    <ng-container>
      <peca-retry context="dados do vendedor" (retry)="onRetry()" />
    </ng-container>
  </ng-container>
</ng-container>
