import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reason',
  standalone: false,
})
export class OrderReturnReasonPipe implements PipeTransform {
  transform(reason: string) {
    switch (reason) {
      case 'PURCHASE_REGRET':
        return 'Arrependimento da Compra';
      case 'APPLICATION_ERROR':
        return 'Erro de Aplicação';
      case 'PART_WARRANTY':
        return 'Garantia da Peça';
      case 'LATE_DELIVERY':
        return 'Atraso de Entrega';
      case 'STOCK_BREAK':
        return 'Quebra de Estoque';
      case 'OTHERS':
        return 'Outros';
      default:
        return reason;
    }
  }
}
