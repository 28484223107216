import { Component, OnInit, inject } from '@angular/core';
import { ToastService } from './toast.service';
import { switchMap, tap, timer } from 'rxjs';
import { Toast } from './toast.model';

@Component({
  selector: 'peca-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: false,
})
export class ToastComponent implements OnInit {
  readonly service: ToastService;

  toast: Toast | null;
  active: boolean;

  constructor() {
    this.service = inject(ToastService);
    this.toast = null;
    this.active = false;
  }

  ngOnInit() {
    this.service.listen
      .pipe(
        tap((toast: Toast) => {
          this.toast = toast;
          this.active = true;
        }),
        switchMap(() => timer(3000)),
        tap(() => (this.active = false)),
        switchMap(() => timer(500))
      )
      .subscribe(() => (this.toast = null));
  }

  onClickClose() {
    this.active = false;
  }
}
