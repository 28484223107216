import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges, inject } from '@angular/core';
import { environment } from 'apps/backoffice/src/environments/environment';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { OrderPayment } from '../../../models/order-payments.model';
import { OrderProduct } from '../../../models/order-product.modal';
import { AsyncState } from '@peca/backoffice/models/async-state.model';

type Details = { payments: OrderPayment[]; products: OrderProduct[] };

@Component({
  selector: 'peca-order-list-details',
  templateUrl: './order-list-item-details.html',
  styleUrls: ['./order-list-item-details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OrderListItemDetailsComponent implements OnChanges, OnDestroy {
  @Input()
  expanded = false;

  @Input()
  orderId: string | number | undefined;

  http = inject(HttpClient);

  details$ = new BehaviorSubject<AsyncState<Details>>({ loading: true });

  destroyRef$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges) {
    const { expanded } = changes;

    if (!expanded || !expanded.currentValue || this.details$.value.data) return;

    this.fetchDetails();
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  fetchDetails() {
    if (!this.orderId) return;

    const orderId = this.orderId;
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}`;
    const payments = this.http.get<OrderPayment[]>(`${resource}/payments`);
    const products = this.http.get<OrderProduct[]>(`${resource}/products`);
    const request = combineLatest({ payments, products });

    request.pipe(takeUntil(this.destroyRef$)).subscribe({
      next: (data: Details) => this.details$.next({ data }),
      error: (e: unknown) => {
        this.details$.next({ failure: true });
      },
    });
  }
}
