import { inject } from '@angular/core';
import { Validators } from '@peca/commons';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SettingsSeller, WebhookSetting } from '../../models/seller-settings.model';

export class SellerSettingForm {
  readonly group: FormGroup;

  constructor() {
    this.group = new FormGroup({
      clientId: new FormControl('', [Validators.required]),
      clientSecret: new FormControl(''),
      host: new FormControl(''),
      username: new FormControl(''),
      password: new FormControl(''),
      type: new FormControl(''),
      webhooks: new FormGroup({
        id: new FormControl(''),
        service: new FormControl('ORDER_STATUS_UPDATED', [Validators.required]),
        url: new FormControl('', [Validators.url()]),
      }),
    });
  }

  setFTPCredentialsRequired(required: boolean) {
    const controlsName = ['host', 'username', 'password', 'type'];

    for (const controlName of controlsName) {
      const control = this.group.get(controlName) as FormControl;
      if (required) {
        control.setValidators([Validators.required]);
      } else {
        control.removeValidators([Validators.required]);
      }
      control.updateValueAndValidity();
    }
  }

  get disabled() {
    return this.group.disabled;
  }

  disableForm() {
    this.group.disable();
  }

  get enabled() {
    return this.group.enabled;
  }

  enableForm() {
    this.group.enable();

    if (!this.controls.clientId.value) {
      return this.controls.clientSecret.disable();
    }

    this.controls.clientId.disable();
  }

  get controls() {
    return {
      clientId: this.group.get('clientId') as FormControl,
      clientSecret: this.group.get('clientSecret') as FormControl,
      host: this.group.get('host') as FormControl,
      username: this.group.get('username') as FormControl,
      password: this.group.get('password') as FormControl,
      type: this.group.get('type') as FormControl,
      webhooks: this.group.get('webhooks') as FormGroup,
    };
  }

  get values() {
    const formValue = this.group.value;
    const webhook = this.controls.webhooks.value?.url ? [this.controls.webhooks.value] : [];

    return {
      clientId: formValue?.clientId,
      clientSecret: formValue?.clientSecret,
      host: formValue?.host,
      username: formValue?.username,
      password: formValue?.password,
      type: formValue?.type,
      webhooks: webhook,
    };
  }

  setValue(value: SettingsSeller) {
    this.controls.clientId.setValue(value?.apiCredential?.clientId);
    this.controls.clientSecret.setValue(value?.apiCredential?.clientSecret);
    this.controls.host.setValue(value.ftpCredential?.host);
    this.controls.username.setValue(value.ftpCredential?.username);
    this.controls.password.setValue(value.ftpCredential?.password);
    this.controls.type.setValue(value.ftpCredential?.type);
    this.webhooks = value?.webhooks;
  }

  set webhooks(webhooks: WebhookSetting[]) {
    const [webhook] = webhooks || [];
    if (!webhook) return;
    this.controls.webhooks.setValue({ id: webhook.id, service: webhook.service, url: webhook.url });
  }
}
