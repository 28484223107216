<pds-table>
  <table>
    <thead>
      <th>Produto</th>
      <th>Quantidade</th>
      <th>PN</th>
      <th>Marca</th>
      <th>Valor total</th>
    </thead>
    <tbody *ngFor="let product of trackingDetails.products">
      <td>{{ product.description }}</td>
      <td>{{ product.quantity }}</td>
      <td>{{ product.partNumber }}</td>
      <td>{{ product.brand }}</td>
      <td>{{ product.totalPrice | currency : 'BRL' }}</td>
    </tbody>
  </table>
</pds-table>
