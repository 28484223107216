import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DesignSystemModule } from '@peca/design-system';
import { AllowToDirective, KeycloakCredentialsService } from '@peca/keycloak';
import { MainNavigationGroup } from '../main-navigation.config';

@Component({
  selector: 'pds-main-navigation-menu',
  templateUrl: './main-navigation-menu.component.html',
  styleUrls: ['./main-navigation-menu.component.scss'],
  imports: [CommonModule, RouterLink, RouterLinkActive, AllowToDirective, DesignSystemModule],
})
export class MainNavigationMenuComponent implements OnInit {
  @Input()
  opened = false;
  @Input()
  groups: Array<MainNavigationGroup> = [];
  expanded = true;
  keycloakCredentialsService = inject(KeycloakCredentialsService);

  ngOnInit(): void {
    const expandMenuPreference = localStorage.getItem('@peca.ai/backoffice/preferences/expand-menu');

    if (expandMenuPreference) {
      this.expanded = JSON.parse(expandMenuPreference);
    }
  }

  onClickToogleMenu() {
    this.expanded = !this.expanded;
    localStorage.setItem('@peca.ai/backoffice/preferences/expand-menu', `${this.expanded}`);
  }
}
