import { Pipe, PipeTransform } from '@angular/core';
import { ProductApplication } from '../models/product-search-state.model';

@Pipe({
  name: 'group',
  standalone: false,
})
export class ProductGroupPipe implements PipeTransform {
  transform(applications: ProductApplication[]) {
    if (!applications) return '-';

    return applications
      .filter((application) => !!application && !!application.group)
      .map(({ group }) => group.name)
      .join(',');
  }
}
