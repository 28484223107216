import { AbstractControl } from '@angular/forms';

export function url() {
  return (control: AbstractControl) => {
    const value = control.value as string;

    if (!value) {
      return null;
    }

    try {
      const url = new URL(value);

      if (url.hostname === 'localhost' || url.hostname === '127.0.0.1') {
        return { url: { message: `Não é possível cadastrar um webhook para ${url.hostname}` } };
      }

      if (!value.startsWith('https://') && !value.startsWith('http://')) {
        return { url: { message: `Informe o protocolo http ou https` } };
      }

      return null;
    } catch (error) {
      return { url: { message: `${value} não é uma url válida` } };
    }
  };
}
