<div *ngIf="branches$ | async as branches" class="seller-details-branches">
  <pds-table *ngIf="branches.data && branches.data.length">
    <table>
      <thead>
        <tr>
          <th>CNPJ</th>
          <th>Razão Social</th>
          <th>Código</th>
          <th>Und. Negócio</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let branch of branches.data">
          <tr [class.--is-headoffice]="branch.headOffice">
            <td>{{ branch.cnpj }}</td>
            <td>{{ branch.commercialName }}</td>
            <td>{{ branch.externalReference }}</td>
            <td>{{ branch.businessUnit | businessUnit }}</td>
            <td pdsColActions>
              <pds-switch [(ngModel)]="branch.active" (ngModelChange)="onClickSwitchStatus(branch)"></pds-switch>
            </td>
            <td pdsColActions>
              <button pdsButton="icon" title="Ver detalhes" (click)="onClickGoToDetails(branch)">
                <span class="icon">visibility</span>
              </button>

              <button pdsButton="icon" title="Adicionar produto" (click)="onClickOpenAddProductManually(branch)">
                <span class="icon">box_add</span>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <hr />

    <pds-form-actions>
      <button pdsButton (click)="onClickNewBranch()">Adicionar filial</button>
    </pds-form-actions>

    <peca-add-product-manully #addProductsManuallyModal />
  </pds-table>
  <ng-container *ngIf="branches.loading"><strong>carregando...</strong></ng-container>
  <ng-container *ngIf="branches.failed"><strong>Não foi possível carregar as filiais.</strong></ng-container>
</div>
