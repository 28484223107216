import { Pipe, PipeTransform } from '@angular/core';
import { OrderFreightStatus } from '../models/order-freight-status.model';

@Pipe({
  name: 'freightStatus',
  standalone: false,
})
export class OrderFreightStatusPipe implements PipeTransform {
  transform(value: OrderFreightStatus | undefined) {
    if (!value) return 'Aguardando Frete';

    switch (value) {
      case 'PENDING':
        return 'Pendente';
      case 'SEARCHING_CARRIER':
        return 'Aguardando Frete';
      case 'COLLECTION_ROUTE':
        return 'Rota de Coleta';
      case 'SHIPPING_ROUTE':
        return 'Rota de entrega';
      case 'CANCELED':
        return 'Cancelado';
      case 'FAILED':
        return 'Falha';
      case 'DELIVERED':
        return 'Entregue';
      default:
        return value;
    }
  }
}
