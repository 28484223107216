import { BreadcrumbItem, Pagination, PaginationInitialState } from '@peca/design-system';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, last, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { DeliveryAggregationComponent } from '../delivery-aggregation/delivery-aggregation.component';
import { Delivery } from '../../models/delivery.model';
import { FreightService } from '../../services/freight.service';
import { ActivatedRoute } from '@angular/router';
import { DeliveryQuery } from '../../models/delivery-query.model';
import { PersistQueryService } from '@peca/commons';

@Component({
  templateUrl: './delivery-list.component.html',
  styleUrls: ['./delivery-list.component.scss'],
  standalone: false,
})
export class DeliveryListComponent implements OnInit, OnDestroy {
  @ViewChild('aggregation')
  aggregation!: DeliveryAggregationComponent;
  search: FormGroup;
  breadcrumb: BreadcrumbItem[];
  deliveries$: BehaviorSubject<AsyncState<Delivery[]>>;
  destroyRef$: Subject<void>;
  pagination$: BehaviorSubject<Pagination>;
  http: HttpClient;
  selecteds$: BehaviorSubject<Delivery[]>;
  service: FreightService;
  salesChannelSource: any[];
  persistQueryService: PersistQueryService;
  route: ActivatedRoute;
  lastQuery: DeliveryQuery;
  cols = {
    createdAt: {
      field: 'createdAt',
      active: true,
      direction: '',
    },
    origin: {
      field: 'origin',
      active: false,
      direction: '',
    },
    destination: {
      field: 'destination',
      active: false,
      direction: '',
    },
  };
  private searchFormInitialValue = { salesChannel: [] };

  constructor() {
    this.breadcrumb = [{ label: 'Fretes', path: '/fretes' }];
    this.deliveries$ = new BehaviorSubject<AsyncState<Delivery[]>>({
      loading: true,
    });
    this.destroyRef$ = new Subject();
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
    this.http = inject(HttpClient);
    this.selecteds$ = new BehaviorSubject<Delivery[]>([]);
    this.service = inject(FreightService);
    this.persistQueryService = inject(PersistQueryService);
    this.route = inject(ActivatedRoute);
    this.salesChannelSource = [];
    this.search = new FormGroup({ salesChannel: new FormControl('') });
    this.lastQuery = {};
  }

  get controls() {
    return {
      salesChannel: this.search.get('salesChannel') as FormControl,
    };
  }

  get query(): DeliveryQuery {
    return this.search.value;
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  ngOnInit() {
    this.deliveries$.next({ loading: true });
    this.initialize();
  }

  initialize() {
    this.search.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.service.fetchSalesChannels().subscribe((res) => {
      this.search.updateValueAndValidity();

      this.route.queryParams.subscribe((params) => {
        if (!params['q']) return;
        const { salesChannel } = JSON.parse(atob(params['q']));
        this.search.patchValue({
          salesChannel: salesChannel ? salesChannel : [res[0].id],
        });
      });

      this.salesChannelSource = res;
      const persitedQuery = this.persistQueryService.read<DeliveryQuery>('peca-delivery-list');
      const query = persitedQuery || this.lastQuery;
      this.fetchFreights(query);
    });
  }

  onSelectDelivery(delivery: Delivery) {
    const selecteds = this.selecteds$.value;

    if (!delivery.selected) {
      this.selecteds$.next([...selecteds.filter(({ id }) => id !== delivery.id)]);
      return;
    }

    this.selecteds$.next([...selecteds, delivery]);
  }

  onClickGroupFreights() {
    if (this.selecteds$.value.length < 1) return;
    this.aggregation.open(this.selecteds$.value);
  }

  onSuccess() {
    this.selecteds$.next([]);
    this.aggregation.close();
    this.fetchFreights(this.query);
  }

  fetchFreights(query: DeliveryQuery) {
    this.deliveries$.next({ loading: true });
    this.lastQuery = query;
    this.persistQueryService.rewriteQueryString('peca-delivery-list', query);

    this.service
      .fetchFreights({ page: 0, size: 100 })
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: (response: Pageable<Delivery>) => {
          this.deliveries$.next({
            data: response.items.filter(({ salesChannel }) => !query?.salesChannel?.length || query?.salesChannel?.includes(salesChannel.id)),
          });
        },
        error: () => {
          this.deliveries$.next({ failure: true });
        },
      });
  }

  onClickSearch() {
    this.fetchFreights(this.query);
  }

  clearFilters() {
    this.search.reset(this.searchFormInitialValue);
    this.onClickSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.search.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.search.markAsPristine();
    return this.search.markAsDirty();
  }

  onClickSort(col: any) {
    const deliveries = this.deliveries$.value.data || [];
    const sorted = deliveries.sort((a, b) => {
      switch (col.field) {
        case 'origin':
          return a.origin.branchName.localeCompare(b.origin.branchName);
        case 'destination':
          return a.buyer.companyName.localeCompare(b.buyer.companyName);
        case 'createdAt':
          return a.createdAt.localeCompare(b.createdAt);
        default:
          return 0;
      }
    });

    Object.values(this.cols).forEach((col) => (col.active = false));
    col.active = true;
    this.deliveries$.next({ data: sorted });
  }
}
