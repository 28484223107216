import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';

import { environment } from '../../../../environments/environment';
import PlatformQuery from '../models/platforms/platform-query.model';
import Platform from '../models/platforms/platform.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PlatformService extends BaseHttpClient {
  private resource: string;

  constructor() {
    super();
    this.resource = `${environment.gateway.url}/backoffice/v2/platforms`;
  }

  fetchPlatforms(query: PlatformQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Platform[]>(`${this.resource}`, { params });
  }

  findById(platformId: string) {
    return this.http.get<Platform>(`${this.resource}/${platformId}`);
  }

  delete(platformId: string) {
    return this.http.delete(`${this.resource}/${platformId}`);
  }

  save(platform: Platform, platformId: string) {
    const { name, description, uniqueName } = platform;
    if (platform?.id) {
      return this.http.put(`${this.resource}/${platformId}`, {
        name,
        description,
        uniqueName,
      });
    } else {
      return this.http.post(this.resource, {
        name,
        description,
        uniqueName,
      });
    }
  }
}
