import { map, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { Log } from '@peca/backoffice/models/logs/log.model';

import { Order } from '../models/order.model';
import { OrderQuery } from '../models/order-query.model';
import { OrderFreight } from '../models/order-freight.model';
import { OrderProduct } from '../models/order-product.modal';
import { OrderPayment } from '../models/order-payments.model';
import { OrderPurchase } from '../models/order-purchase.model';
import { Pageable } from '../../../core/models/pageable.model';
import { environment } from '../../../../environments/environment';
import { OrderSimilarProduct } from '../models/order-similar-product.model';
import { OrderProductRequest } from '../models/order-product-request.model';
import { OrderPartialDelivered } from '../models/order-partial-delivered.model';
import { OrderChangePricesRequest } from '../models/order-change-prices-request.model';

@Injectable({ providedIn: 'root' })
export class OrderService extends BaseHttpClient {
  fetch(query: OrderQuery) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders`;
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<Order>>(resource, { params }).pipe(
      map<Pageable<Order>, Pageable<Order>>((response) => {
        return {
          ...response,
          items: response.items.map((order) => ({ ...order, expanded: false })),
        };
      })
    );
  }

  fetchSummaries(query: OrderQuery) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/summaries`;
    const params = this.prepareQuery(query);
    return this.http.get<any>(resource, { params });
  }

  fetchDetails(orderId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}`;
    return this.http.get<Order>(resource);
  }

  fetchSimilarProductsByBranch(orderId: string, productId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/products/${productId}/similar`;
    return this.http.get<OrderSimilarProduct[]>(resource);
  }

  fetchProductsByCatalogId(orderId: string, productId: string, catalogId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/products/${productId}/substitution?catalogId=${catalogId}`;
    return this.http.get<OrderSimilarProduct[]>(resource);
  }

  fetchSelersProductsByBranch(orderId: string, productId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/products/${productId}/branches`;
    return this.http.get<OrderSimilarProduct[]>(resource);
  }

  fetchSimilarProducts(orderId: string, partNumber: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/products/substitutions`;
    const params = { partNumber };
    return this.http.get<OrderSimilarProduct[]>(resource, { params });
  }

  updateProductBranch(product: OrderProductRequest) {
    const { orderId, productId, branchId, brandId, reason, quantity } = product;
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/products/${productId}/branches`;
    return this.http.put(resource, { branchId, brandId, reason, quantity });
  }

  updateProduct(product: OrderProductRequest) {
    const { orderId, productId, partNumber, quantity, branchId, brandId, reason } = product;
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/products/${productId}`;
    return this.http.put(resource, { partNumber, quantity, branchId, brandId, reason });
  }

  addProduct(product: OrderProductRequest) {
    const { orderId, partNumber, quantity, branchId, brandId } = product;
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/products`;
    return this.http.post(resource, { partNumber, quantity, branchId, brandId });
  }

  removeProduct(orderId: string, productId: string, reason: string, justification: string) {
    const params = { reason, justification };
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/products/${productId}`;
    return this.http.delete(resource, { params });
  }

  fetchOrderLogs(orderId: string | number) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/logs`;
    return this.http.get<Log[]>(resource);
  }

  fetchOrderFreights(orderId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/freights`;
    return this.http.get<OrderFreight[]>(resource);
  }

  suggestBuyers(search: string) {
    const params = { search };
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/suggestions`;
    return this.http.get<any[]>(resource, { params }).pipe(map((items) => items.map(({ id, name }) => ({ id, label: name }))));
  }

  suggestSalesChannels(search: string) {
    const params = { search };
    const resource = `${environment.gateway.url}/backoffice/v1/sales-channel/suggestions`;
    return this.http
      .get<any[]>(resource, { params })
      .pipe(map((items) => items.map(({ id, name }) => ({ id, label: name })).sort((x, y) => (x.label > y.label ? 1 : -1))));
  }

  fetchProductsFromOrder(orderId: string | number) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/products`;
    return this.http.get<OrderProduct[]>(resource);
  }

  fetchPaymentsFromOrder(orderId: string | number) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/payments`;
    return this.http.get<OrderPayment[]>(resource);
  }

  fetchPuchasesFromOrder(orderId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/purchases`;
    return this.http.get<OrderPurchase[]>(resource);
  }

  cancelOrder(orderId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}`;
    return this.http.delete(resource);
  }

  createComplementaryOrder(orderId: string, payload: OrderPartialDelivered) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/partial-delivered`;
    return this.http.post<{ id: string }>(resource, payload);
  }

  createOrderReturn(orderId: string, payload: any) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/returns`;
    return this.http.post(resource, payload);
  }

  updateStatus(orderId: string, payload: { status: string; parentOrder: string }) {
    if (payload.status === 'MISPLACED') {
      const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/misplaced`;
      return this.http.post(resource, { misplacedOrderId: payload.parentOrder });
    } else {
      const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/status`;
      return this.http.patch(resource, { status: payload.status });
    }
  }

  updateObservations(orderId: string, { publicObservations, invoiceObservations }: { invoiceObservations: string; publicObservations: string }) {
    const observations = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/observations`;
    const invoice = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/invoice-observations`;

    return this.http.patch(observations, { publicObservations }).pipe(switchMap(() => this.http.patch(invoice, { invoiceObservations })));
  }

  issueInvoice(id: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${id}/issue-invoice`;
    return this.http.post(resource, {});
  }

  suggestOrders(search: string) {
    const params = { search, status: 'DELIVERED' };
    const resource = `${environment.gateway.url}/backoffice/v1/orders/suggestions`;
    return this.http.get<any[]>(resource, { params }).pipe(map((items) => items.map(({ id, commerceReference }) => ({ id, label: commerceReference }))));
  }

  updatePrices(id: string, payload: OrderChangePricesRequest) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${id}/prices`;
    return this.http.patch(resource, payload);
  }

  dowloadInvoice(id: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/invoices/${id}/danfe`;
    return this.download(resource);
  }
}
