<pds-modal #modal [width]="640" title="Adicionar Produto">
  <pds-form *ngIf="branch" [formGroup]="form">
    <pds-form-row grid="1-1">
      <pds-form-field label="Vendedor">
        <input type="text" [value]="branch.tradingName" [disabled]="true" />
      </pds-form-field>
      <pds-form-field label="Filial">
        <input type="text" [value]="branch.externalReference" [disabled]="true" />
      </pds-form-field>
    </pds-form-row>
    <pds-form-section title="Produto">
      <pds-form-row grid="1-1-1">
        <pds-form-field label="Referência externa">
          <input type="text" formControlName="externalReference" />
        </pds-form-field>
        <pds-form-field label="Estoque">
          <input type="number" formControlName="stock" />
        </pds-form-field>
        <pds-form-field label="Preço unitário">
          <input type="text" formControlName="price" pdsCurrencyMask />
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>

    <pds-form-actions>
      <button pdsButton type="button" [disabled]="form.invalid || form.disabled" (click)="onClickSubmit()">adicionar</button>
    </pds-form-actions>
  </pds-form>
</pds-modal>
