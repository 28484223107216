import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { CarrierService } from '../../services/carrier.service';
import { AsyncState } from '../../../../core/models/async-state.model';
import { DeliveryType } from '../../models/delivery-type.model';

@Component({
  selector: 'peca-carrier-delivery-types',
  templateUrl: './carrier-delivery-types.component.html',
  styleUrls: ['./carrier-delivery-types.component.scss'],
  standalone: false,
})
export class CarrierDeliveryTypesComponent implements OnInit {
  readonly service: CarrierService;
  readonly route: ActivatedRoute;
  readonly deliveryTypes$: BehaviorSubject<AsyncState<DeliveryType[]>>;

  constructor() {
    this.service = inject(CarrierService);
    this.route = inject(ActivatedRoute);
    this.deliveryTypes$ = new BehaviorSubject<AsyncState<DeliveryType[]>>({
      loading: true,
    });
  }

  get carrierId() {
    return this.route.snapshot.params['carrierId'] as string;
  }

  ngOnInit() {
    this.fetchDeliveryTypes();
  }

  //#region fetchDeliveryTypes
  fetchDeliveryTypes() {
    this.service.fetchDeliveryTypes(this.carrierId).subscribe({
      next: this.onFetchDeliveryTypesSuccess.bind(this),
      error: this.onFetchDeliveryTypesFailure.bind(this),
    });
  }

  onFetchDeliveryTypesSuccess(types: DeliveryType[]) {
    this.deliveryTypes$.next({ data: types });
  }

  onFetchDeliveryTypesFailure(e: unknown) {
    this.deliveryTypes$.next({ failure: true });
  }
  //#endregion
}
