import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@peca/commons';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ActingRegion } from '../../models/acting-region.model';
import { AsyncState } from '../../../../core/models/async-state.model';
import { ActingRegionService } from '../../services/acting-region.service';
import { BreadcrumbItem } from '@peca/design-system';
import { DeliveryTypeService } from '../../services/delivery-type.service';
import { CarrierService } from '../../services/carrier.service';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { FileUploadComponent } from '@peca/backoffice/components/file-upload/file-upload.component';

@Component({
  selector: 'peca-delivery-type-regions',
  templateUrl: './delivery-type-regions.component.html',
  styleUrls: ['./delivery-type-regions.component.scss'],
  standalone: false,
})
export class DeliveryTypeRegionsComponent implements OnInit {
  @ViewChild('upload')
  upload!: FileUploadComponent;
  form: FormGroup;
  regions$: BehaviorSubject<AsyncState<ActingRegion[]>>;
  route: ActivatedRoute;
  service: ActingRegionService;
  deliveryTypeService: DeliveryTypeService;
  carrierService: CarrierService;
  breadcrumbItems: BreadcrumbItem[];
  confirmable: ConfirmableActionService;

  constructor() {
    this.service = inject(ActingRegionService);
    this.deliveryTypeService = inject(DeliveryTypeService);
    this.carrierService = inject(CarrierService);
    this.route = inject(ActivatedRoute);
    this.confirmable = inject(ConfirmableActionService);
    this.regions$ = new BehaviorSubject<AsyncState<ActingRegion[]>>({
      loading: true,
    });
    this.form = new FormGroup({
      id: new FormControl(''),
      description: new FormControl('', [Validators.required]),
      originZipCodeStart: new FormControl('', [Validators.required, Validators.cep]),
      originZipCodeEnd: new FormControl('', [Validators.required, Validators.cep]),
      destinationZipCodeStart: new FormControl('', [Validators.required, Validators.cep]),
      destinationZipCodeEnd: new FormControl('', [Validators.required, Validators.cep]),
    });
    this.breadcrumbItems = [
      {
        label: 'Listagem de Tranportadoras',
        path: '/transportadoras',
      },
      {
        label: 'Transportadora',
        path: `/transportadoras/${this.carrierId}`,
      },
      {
        label: 'Tipo de Entrega',
        path: `/transportadoras/${this.carrierId}/tipo-de-entrega/${this.deliveryTypeId}`,
      },
      {
        label: 'Regiões de Atuação',
      },
    ];
  }

  get carrierId() {
    return this.route.snapshot.params['carrierId'] as string;
  }

  get deliveryTypeId() {
    return this.route.snapshot.params['id'] as string;
  }

  get breadcrumb() {
    const { carrierId, id } = this.route.snapshot.params;

    return [
      { label: 'Transportadoras', path: '/transportadoras' },
      { label: carrierId, path: `/transportadoras/${carrierId}` },
      {
        label: id,
        path: `/transportadoras/${carrierId}/tipo-de-entrega/${id}`,
      },
      {
        label: 'Regiões de atuação',
        path: '',
      },
    ];
  }
  get value() {
    return {
      id: this.form.value.id,
      active: this.form.value.active,
      description: this.form.value.description,
      originZipCodeStart: this.form.value.originZipCodeStart.replace(/-/, ''),
      originZipCodeEnd: this.form.value.originZipCodeEnd.replace(/-/, ''),
      destinationZipCodeStart: this.form.value.destinationZipCodeStart.replace(/-/, ''),
      destinationZipCodeEnd: this.form.value.destinationZipCodeEnd.replace(/-/, ''),
    };
  }
  set value(value: ActingRegion) {
    this.form.setValue(value);
  }

  ngOnInit() {
    this.list();
  }

  //#region list
  list() {
    const { carrierId, id } = this.route.snapshot.params;
    this.regions$.next({ loading: true });
    this.form.disable();
    this.service.list(carrierId, id).subscribe({
      next: this.onListSuccess.bind(this),
      error: this.onListFailure.bind(this),
    });

    this.deliveryTypeService.details(this.carrierId, this.deliveryTypeId).subscribe((value) => {
      this.breadcrumbItems[2].label = value.publicName;
    });

    this.carrierService.fetchCarrierDetails(this.carrierId).subscribe({
      next: (carrierDetails) => (this.breadcrumbItems[1].label = carrierDetails.commercialName),
    });
  }
  onListSuccess(regions: ActingRegion[]) {
    this.form.enable();
    this.regions$.next({ data: regions });
  }
  onListFailure() {
    this.form.enable();
    this.regions$.next({ failure: true });
  }
  //#endregion

  //#region create
  create(region: ActingRegion) {
    const { carrierId, id } = this.route.snapshot.params;
    this.regions$.next({ loading: true });
    this.form.disable();
    this.service.create(carrierId, id, region).subscribe({
      next: this.onCreateSuccess.bind(this),
      error: this.onCreateFailure.bind(this),
    });
  }
  onCreateSuccess(regions: ActingRegion[]) {
    this.form.enable();
    this.clear();
    this.regions$.next({ data: regions });
  }
  onCreateFailure() {
    this.form.enable();
    this.regions$.next({ failure: true });
  }
  //#endregion

  //#region update
  updateStatus(region: ActingRegion, active: boolean) {
    const { carrierId, id } = this.route.snapshot.params;
    this.regions$.next({ loading: true });
    this.form.disable();
    this.service.updateStatus(carrierId, id, region.id, active).subscribe({
      next: this.onUpdateStatusSuccess.bind(this),
      error: this.onUpdateStatusFailure.bind(this),
    });
  }
  onUpdateStatusSuccess(regions: ActingRegion[]) {
    this.form.enable();
    this.regions$.next({ data: regions });
  }
  onUpdateStatusFailure() {
    this.form.enable();
    this.regions$.next({ failure: true });
  }
  //#endregion

  //#region delete
  delete(region: ActingRegion) {
    const { carrierId, id } = this.route.snapshot.params;
    this.regions$.next({ loading: true });
    this.form.disable();
    this.service.delete(carrierId, id, region.id).subscribe({
      next: this.onDeleteSuccess.bind(this),
      error: this.onDeleteFailure.bind(this),
    });
  }
  onDeleteSuccess(regions: ActingRegion[]) {
    this.form.enable();
    this.regions$.next({ data: regions });
  }
  onDeleteFailure() {
    this.form.enable();
    this.regions$.next({ failure: true });
  }
  //#endregion

  //#region upload
  upload2(file: FormData) {
    const { carrierId, id } = this.route.snapshot.params;
    this.service.upload(carrierId, id, file).subscribe({
      next: this.onListSuccess.bind(this),
      error: this.onListFailure.bind(this),
    });
  }
  //#region

  handleSubmitForm() {
    if (this.form.invalid) return;
    this.create(this.value);
  }

  handleStatusChanges(region: ActingRegion, active: boolean) {
    this.confirmable.confirm('Você está alterando o status de uma área de atuação.').subscribe(({ confirmed }) => {
      if (!confirmed) return;
      this.updateStatus(region, active);
    });
  }

  handleRemoveEvent(region: ActingRegion) {
    this.confirmable.confirm('Você está removendo uma área de atuação.').subscribe(({ confirmed }) => {
      if (!confirmed) return;
      this.delete(region);
    });
  }

  handleUpload(file: File) {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    this.confirmable
      .confirm('Importar um arquivos de areas de regiões apagará todas as regiões cadastradas para esse tipo de entrega.')
      .subscribe(({ confirmed }) => {
        if (!confirmed) return;
        this.upload2(formData);
      });
  }

  clear() {
    this.form.get('id')?.setValue('');
    this.form.get('description')?.setValue('');
    this.form.get('originZipCodeStart')?.setValue('');
    this.form.get('originZipCodeEnd')?.setValue('');
    this.form.get('destinationZipCodeStart')?.setValue('');
    this.form.get('destinationZipCodeEnd')?.setValue('');
  }

  onClickImport() {
    this.upload.open();
  }
}
