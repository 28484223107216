<pds-form [formGroup]="form.group" (ngSubmit)="onSubmit()">
  <pds-form-row grid="1-1-1-1">
    <pds-form-field label="ID do Pedido">
      <input type="text" formControlName="orderId" placeholder="PA-002668" />
    </pds-form-field>

    <pds-form-field label="Comprador">
      <pds-autocomplete placeholder="Nome fantasia ou razão social" formControlName="buyer" [source]="buyers$" (autocomplete)="onAutocompleteBuyers($event)" />
    </pds-form-field>

    <pds-form-field label="Data">
      <pds-range-form formControlName="date" />
    </pds-form-field>

    <pds-form-field label="Status">
      <pds-select formControlName="status" [options]="statusSource" />
    </pds-form-field>
  </pds-form-row>

  <pds-form-row grid="1-1-1-1">
    <pds-form-field label="Código de rastreio">
      <input type="text" formControlName="trackingCode" placeholder="Código" />
    </pds-form-field>

    @if (salesChannels.length) {
    <pds-form-field label="Canal de Venda">
      <pds-select formControlName="salesChannelIds" [options]="salesChannels" />
    </pds-form-field>
    } @if (carriers.length) {
    <pds-form-row>
      <pds-form-field label="Transportadora">
        <pds-select formControlName="carrierIds" [options]="carriers" />
      </pds-form-field>
    </pds-form-row>
    }

    <pds-form-field label="Tempo de entrega">
      <pds-select formControlName="deliveryTime" [options]="shippingtimes" />
    </pds-form-field>
  </pds-form-row>

  <pds-form-actions>
    <button pdsButton="primary" modifier="outlined" (click)="clearFilters()" type="button" [disabled]="form.group.pristine">Limpar filtros</button>
    <button pdsButton title="Buscar" type="submit">Buscar</button>
  </pds-form-actions>
</pds-form>
