<ng-container *ngIf="permissions$ | async as permissions">
  @if (permissions.loading) {
  <strong>carregando...</strong>

  }@else if (permissions.failure) {
  <peca-retry context="permissões" (retry)="onRetry()" />
  } @else if (permissions.platforms && permissions.platforms.length <= 0) {
  <strong>Nenhuma plataforma encontrada.</strong>

  } @else if (permissions.platforms) {
  <ul class="permission-groups">
    <li *ngFor="let platform of permissions.platforms; let i = index" class="permission-groups__item">
      <h3 role="button" (click)="onClickExpandPlatform(platform, i)">
        <span>{{ platform.name }}</span>
        <span class="icon">{{ platform.expanded ? 'unfold_less' : 'unfold_more' }}</span>
      </h3>

      <ul class="permission-modules" [hidden]="!platform.expanded">
        @if (platform.loading) {

        <strong>carregando...</strong>
        }@else if (platform.failure) {
        <strong>falha ao carregar módulos.</strong>

        } @else if (platform.modules && platform.modules.length <= 0) {

        <strong>Nenhum módulo encontrado.</strong>
        } @else if (platform.modules) {
        <li *ngFor="let module of platform.modules" class="permission-modules__item">
          <h4 role="button" (click)="onClickExpandModule(platform.id, module)">
            <span>{{ module.name }}</span>
            <span class="icon">{{ module.expanded ? 'unfold_less' : 'unfold_more' }}</span>
          </h4>
          <ul class="permission-feature" *ngIf="module.expanded" [hidden]="!module.expanded">
            @if (module.loading) {
            <strong>carregando...</strong>
            } @else if (module.failure) {
            <strong>falha ao carregar funcionalidades.</strong>
            } @else if (module.features && module.features.length <= 0) {
            <strong>Nenhuma funcionalidade encontrada.</strong>
            } @else if (module.features) {
            <li *ngFor="let feature of module.features">
              <pds-switch [disabled]="disabled" [label]="feature.role" [(ngModel)]="feature.active" (ngModelChange)="onFeatureToggle(feature, $event)" />
            </li>

            <div class="permission-feature__profiles">
              <button (click)="onClickAllFeatures(module)" [disabled]="disabled">Todas</button>
              <button (click)="onClickNoFeatures(module)" [disabled]="disabled">Nenhuma</button>
            </div>
            }
          </ul>
        </li>
        }
      </ul>
    </li>
  </ul>
  }
</ng-container>
