import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService, numeric } from '@peca/commons';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { VoucherService } from '../../services/voucher.service';
import { ConfirmableActionService } from '../../../../core/services/confirmable-action.service';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateVoucher } from '../../models/voucher.model';
import { DatePipe } from '@angular/common';
import { BreadcrumbItem } from '@peca/design-system';

type SubmitOperation = 'create' | 'update';

@Component({
  selector: 'peca-vouchers-form',
  templateUrl: './vouchers-form.component.html',
  styleUrls: ['./vouchers-form.component.scss'],
  standalone: false,
})
export class VouchersFormComponent implements OnInit {
  private readonly destroyRef$ = new Subject<void>();
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly toast = inject(ToastService);
  private readonly service = inject(VoucherService);
  private readonly confirmable = inject(ConfirmableActionService);
  breadcrumbItems: BreadcrumbItem[] = [{ label: 'Listagem de Cupons', path: '/cupons' }];

  get submitOperation() {
    return this.route.snapshot.data['submitOperation'] as SubmitOperation;
  }

  // Update only
  readonly readOnlyMode = new BehaviorSubject<boolean>(false);
  readonly isPublished = new BehaviorSubject<boolean>(false);
  readonly isActive = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    if (this.submitOperation === 'create') {
      this.breadcrumbItems.push({ label: 'Novo Cupom' });
      return;
    }

    this.breadcrumbItems.push({ label: 'Ver Cupom' });

    this.readOnlyMode.subscribe((current) => {
      this.fetchDetails(); // reload latest info

      if (current) return this.form.disable();

      if (this.isPublished.value) {
        this.form.get('description')?.enable();
        this.form.get('campaignEnd')?.enable();
      } else {
        this.form.enable();
        this.form.get('published')?.disable();
      }
    });

    this.readOnlyMode.next(true);
  }

  fetchDetails() {
    const { id } = this.route.snapshot.params;
    if (!id) return this.toast.failure('Falha ao buscar informações do Cupom');

    this.service
      .fetchDetails(id)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: (data) => {
          const datePipe = new DatePipe('pt-BR');
          data.campaignInit = datePipe.transform(data.campaignInit, 'yyyy-MM-dd') || '';

          data.campaignEnd = datePipe.transform(data.campaignEnd, 'yyyy-MM-dd') || '';
          this.isPublished.next(data.published);
          this.isActive.next(data.active);
          this.form.patchValue(data);
          this.breadcrumbItems[1].label = data.code;
        },
        error: (err) => {
          return this.toast.failure('Falha ao buscar informações do Cupom');
        },
      });
  }

  readonly form = new FormGroup({
    // Cadastro de Cupom
    code: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    assignKind: new FormControl('', [Validators.required, Validators.pattern(/\b(SHIPPING|PRODUCTS)\b/)]),
    description: new FormControl('', []),
    type: new FormControl('FIXED', [Validators.required, Validators.pattern(/\b(FIXED|PERCENT)\b/)]),
    value: new FormControl('', [Validators.required]), // Valor <big decimal>
    limitAmountValue: new FormControl(''), // Valor máximo

    // Campanha
    campaignInit: new FormControl(''),
    campaignEnd: new FormControl(''),
    published: new FormControl({ value: false, disabled: true }),

    // Código Legado
    legacyCode: new FormControl(''),
  });

  onClickToggleEditMode() {
    this.readOnlyMode.next(!this.readOnlyMode.value);
  }

  onClickToggleStatus() {
    const { id } = this.route.snapshot.params;
    if (!id) return this.toast.failure('Falha ao buscar informações do Cupom');

    this.confirmable.confirm('Você está alterando o status de um cupom.').subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        return;
      }

      this.service
        .updateStatus(id, !this.isActive.value)
        .pipe(takeUntil(this.destroyRef$))
        .subscribe({
          error: (err) => {
            this.toast.failure('Ocorreu um erro ao alterar o status do cupom');
          },
          complete: () => {
            this.toast.success('Status do cupom alterado com sucesso');
            this.router.navigate(['/cupons']);
          },
        });
      return;
    });
  }

  onClickSubmit() {
    if (this.form.invalid) return this.toast.failure('Formulário incorreto');

    switch (this.submitOperation) {
      case 'create': {
        const payload = {
          ...this.form.value,
          limitAmountValue: numeric(this.form.value.limitAmountValue || ''),
          value: numeric(this.form.value.value || ''),
          campaignInit: this.form.value.campaignInit ? new Date(this.form.value.campaignInit) : '',
          campaignEnd: this.form.value.campaignEnd ? new Date(this.form.value.campaignEnd) : '',
        } as CreateVoucher;

        this.service
          .create(payload)
          .pipe(takeUntil(this.destroyRef$))
          .subscribe({
            next: () => {
              this.toast.success('Rascunho criado com sucesso!');
              this.router.navigate(['/cupons']);
            },
            error: (err) => {
              this.toast.failure('Ocorreu um erro ao criar o rascunho!');
            },
          });
        break;
      }

      case 'update': {
        const { id } = this.route.snapshot.params;
        const toastFailure = () => this.toast.failure('Ocorreu um erro ao editar o rascunho!');

        if (!id) return toastFailure();

        const formRawValue = this.form.getRawValue();

        const payload = {
          ...formRawValue,
          limitAmountValue: numeric(formRawValue.limitAmountValue || ''),
          value: numeric(formRawValue.value || ''),
          campaignInit: formRawValue.campaignInit ? new Date(formRawValue.campaignInit) : '',
          campaignEnd: formRawValue.campaignEnd ? new Date(formRawValue.campaignEnd) : '',
        } as CreateVoucher;

        this.service
          .update(id, payload)
          .pipe(takeUntil(this.destroyRef$))
          .subscribe({
            next: () => {
              this.toast.success('Rascunho salvo com sucesso!');
              this.router.navigate(['/cupons']);
            },
            error: (err) => {
              toastFailure();
            },
          });
        break;
      }
    }
  }

  onClickPublish() {
    const { id } = this.route.snapshot.params;

    if (!id) return this.toast.failure('Falha ao buscar informações do Cupom');
    if (this.isPublished.value) return this.toast.success('Cupom já publicado');

    this.service
      .publish(id)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: () => {
          this.toast.success('Cupom publicado com sucesso!');
          this.router.navigate(['/cupons']);
        },
        error: (err) => {
          this.toast.failure('Ocorreu um erro ao publicar o cupom');
        },
      });
  }
}
