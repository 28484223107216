import { BehaviorSubject, Subject } from 'rxjs';
import { Component } from '@angular/core';
import { Pagination, PaginationInitialState, BreadcrumbItem } from '@peca/design-system';
import { ProductSearchQuery } from '../../models/product-search-query.model';
import { ProductSearchState } from '../../models/product-search-state.model';
import { SearchMode } from '../../models/search-mode.model';

@Component({
  selector: 'peca-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  standalone: false,
})
export class ProductListComponent {
  lastQuery: ProductSearchQuery;
  destroy$: Subject<void>;
  query$: Subject<ProductSearchQuery>;
  mode: SearchMode;
  loading$: BehaviorSubject<boolean>;
  pagination$: BehaviorSubject<Pagination>;
  breadcrumbItems: BreadcrumbItem[] = [{ label: 'Pesquisa de Produtos', path: '/produtos' }];

  constructor() {
    this.lastQuery = { ...PaginationInitialState };
    this.destroy$ = new Subject();
    this.query$ = new Subject<ProductSearchQuery>();
    this.mode = 'STOREFRONT';
    this.loading$ = new BehaviorSubject<boolean>(false);
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
  }

  handleSearchEvent(_query: ProductSearchQuery) {
    const query = { ...this.lastQuery, ..._query, page: 0 };
    this.lastQuery = query;
    this.query$.next(query);
  }

  handleSearchChange(state: ProductSearchState<unknown>) {
    this.loading$.next(!!state.loading);

    if (!state.data) return;

    this.pagination$.next({
      page: state.data.currentPage + 1,
      total: state.data.totalPages,
      size: state.data.pageSize,
      items: state.data.totalItems,
    });
  }

  handlePaginateEvent(pagination: Pagination) {
    const { size, page } = pagination;
    const query = { ...this.lastQuery, page: page - 1, size };
    this.pagination$.next({ ...pagination, disabled: true });
    this.query$.next(query);
  }

  handleSearchModeAlternate(mode: SearchMode) {
    this.mode = mode;
    this.pagination$.next(PaginationInitialState);
  }
}
