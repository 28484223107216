import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';

type PDSChipDirectiveModifier = 'outlined';

type PDSChipDirectiveType = 'default' | 'secondary' | 'terciary' | '';

@Directive({
  selector: 'a[pdsChipLink]',
  standalone: false,
})
export class PDSChipLinkDirective implements OnInit {
  @Input()
  modifiers?: PDSChipDirectiveModifier;

  @Input()
  pdsChipLink: PDSChipDirectiveType = '';

  elementRef = inject(ElementRef);

  renderer = inject(Renderer2);

  get hostElement() {
    return this.elementRef.nativeElement;
  }

  ngOnInit() {
    const icon = this.renderer.createElement('span');

    this.renderer.addClass(this.hostElement, 'pds-chip-link');
    this.renderer.addClass(this.hostElement, `pds-chip-link-${this.pdsChipLink || 'default'}`);
    this.modifiers?.split(' ').forEach((modifier) => this.renderer.addClass(this.hostElement, `--is-${modifier}`));
    this.renderer.addClass(icon, 'icon');
    this.renderer.appendChild(icon, this.renderer.createText('open_in_new'));
    this.renderer.appendChild(this.hostElement, icon);
  }
}
