<pds-module-header title="Transportadoras" ctaPath="/transportadoras" ctaLabel="Voltar" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <peca-carrier-form [form]="form" />

  <hr />

  <pds-form-actions>
    <button pdsButton [disabled]="form.instance.invalid" (click)="handleSubmitEvent()">salvar</button>
  </pds-form-actions>
</pds-module-header>
