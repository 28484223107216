<pds-modal title="Adicionar Vendedores" #modal [width]="960" (dismiss)="close()">
  <pds-form>
    <pds-form-row grid="1-1-1" [formGroup]="form">
      <pds-form-field label="Vendedor">
        <pds-autocomplete [source]="sellers$" (autocomplete)="onSellerAutocomplete($event)" placeholder="Busque por vendedores" formControlName="seller" />
      </pds-form-field>
    </pds-form-row>

    <pds-form-section title="Filiais" *ngIf="branches$ | async; let branches">
      @if (branches.loading) {
      <strong>carregando...</strong>
      } @else if (branches.failure) {
      <strong>Falha ao carregar filiais.</strong>
      } @else if (branches.branches) { @for (branch of branches.branches; track $index) {
      <pds-checkbox [label]="branch.cnpj + ' - ' + branch.externalReference" [(ngModel)]="branch.selected" (ngModelChange)="onSelectBranch()" />
      } } @else {
      <strong>Selecione um vendedor para listar suas filiais</strong>
      }
    </pds-form-section>

    <pds-form-actions>
      <button pdsButton title="Salvar" (click)="onClickSubmit()">
        <span>Salvar</span>
      </button>
    </pds-form-actions>
  </pds-form>
</pds-modal>
