import { Component, inject, OnInit } from '@angular/core';
import { Pagination, PaginationInitialState } from '@peca/design-system';
import { BuyerForm } from '../../forms/buyer.form';
import { FormControl, FormGroup } from '@angular/forms';
import { Bill } from '../../models/bill.model';
import { BehaviorSubject } from 'rxjs';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { BuyerBillQuery } from '../../models/buyer-bills-query.model';
import { BillService } from '../../services/bill.service';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { ActivatedRoute } from '@angular/router';
import { numeric, PersistQueryService } from '@peca/commons';
import { environment } from 'apps/backoffice/src/environments/environment';

@Component({
  selector: 'peca-buyer-bill-list',
  templateUrl: './buyer-bill-list.component.html',
  styleUrls: ['./buyer-bill-list.component.scss'],
  standalone: false,
})
export class BuyerBillListComponent implements OnInit {
  form: BuyerForm;
  billService: BillService;
  bills$: BehaviorSubject<AsyncState<Bill[]>>;
  pagination$: BehaviorSubject<Pagination>;
  lastQuery: BuyerBillQuery;
  route: ActivatedRoute;
  persistQueryService: PersistQueryService;
  acessBillBaseUrl?: string;
  searchForm: FormGroup = new FormGroup({
    code: new FormControl(''),
    date: new FormControl({ startDate: '', endDate: '' }),
    amount: new FormControl(''),
  });
  private readonly searchFormInitialValue = {
    code: '',
    date: {
      startDate: '',
      endDate: '',
    },
    amount: '',
  };

  constructor() {
    this.persistQueryService = inject(PersistQueryService);
    this.billService = inject(BillService);
    this.route = inject(ActivatedRoute);
    this.form = new BuyerForm();
    this.bills$ = new BehaviorSubject<AsyncState<Bill[]>>({ loading: true });
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
    this.acessBillBaseUrl = environment.storefront.url;
    this.lastQuery = {};
  }

  ngOnInit() {
    this.searchForm.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { code, startDate, endDate, amount, sort } = JSON.parse(atob(params['q']));
      this.searchForm.patchValue({
        code: code || '',
        date: { startDate: startDate?.split('T')[0] || '', endDate: endDate?.split('T')[0] || '' },
        amount: amount || '',
        sort: sort || 'endDate,desc',
      });
    });

    const persitedQuery = this.persistQueryService.read<BuyerBillQuery>('peca-buyer-bill-list');
    const query = persitedQuery || this.query;
    this.fechBills(query);
  }

  fechBills(query: BuyerBillQuery) {
    this.lastQuery = query;
    this.persistQueryService.rewriteQueryString('peca-buyer-bill-list', query);
    this.bills$.next({ loading: true });

    this.billService.fetchBills(this.buyerId, query).subscribe({
      next: this.onFetchBillsSuccess.bind(this),
      error: this.onFetchBillsFailure.bind(this),
    });
  }

  onRetry() {
    this.fechBills(this.query);
  }

  paginate(page: Pageable<Bill>) {
    const pagination = {
      page: page.currentPage,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    };
    this.pagination$.next({ ...pagination, page: pagination.page + 1 });
    this.persistQueryService.rewriteQueryString('peca-buyer-bill-list', { ...this.lastQuery, ...pagination });
  }

  onFetchBillsSuccess(response: Pageable<Bill>) {
    const { items } = response;
    this.paginate(response);
    this.bills$.next({ data: items });
  }

  onFetchBillsFailure(e: unknown) {
    this.bills$.next({ failure: true });
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fechBills(query);
  }

  get query(): BuyerBillQuery {
    const fielterQuery = this.filter;

    const query = {
      sort: 'endDate,desc',
      ...fielterQuery,
      ...this.pagination$.value,
    };
    return query;
  }

  get filter() {
    const code = (this.searchForm.get('code') as FormControl).value;
    const startDate = (this.searchForm.get('date') as FormControl).value?.startDate || '';
    const endDate = (this.searchForm.get('date') as FormControl).value?.endDate || '';
    const amount = numeric((this.searchForm.get('amount') as FormControl).value);

    const filter = {
      code,
      startDate: !startDate ? '' : startDate + 'T00:00:00',
      endDate: !endDate ? '' : endDate + 'T23:59:00',
      amount: !amount ? '' : String(amount),
    };

    return filter;
  }

  get buyerId() {
    return this.route.snapshot.params['id'] as string;
  }

  onClickSearch() {
    if (this.searchForm.invalid) return;
    const query = { ...this.query, page: 0 };
    this.fechBills(query);
  }

  clearFilters() {
    this.searchForm.reset(this.searchFormInitialValue);
    const query = { ...this.query, page: 0 };
    this.fechBills(query);
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.searchForm.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.searchForm.markAsPristine();
    return this.searchForm.markAsDirty();
  }
}
