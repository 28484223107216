import { Component, Input } from '@angular/core';

import { ProductDetails } from '../../../models/product-details.model';

@Component({
  selector: 'peca-product-details-spec[product]',
  templateUrl: './product-details-spec.component.html',
  styleUrls: ['./product-details-spec.component.scss'],
  standalone: false,
})
export class ProductDetailsSpecComponent {
  @Input()
  product!: ProductDetails;

  get applications() {
    return this.product.applications.map((application) => {
      const vehicles = application.vehicles.sort((a, b) => a.name.localeCompare(b.name));

      return { ...application, vehicles };
    });
  }
}
