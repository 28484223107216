import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { DesignSystemModule, ModalComponent } from '@peca/design-system';

@Component({
  selector: 'peca-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [CommonModule, DesignSystemModule],
})
export class FileUploadComponent {
  @Output()
  upload: EventEmitter<File>;

  @ViewChild('modal')
  modal!: ModalComponent;

  file: File | null;
  previewImage: string | ArrayBuffer | null;

  constructor() {
    this.upload = new EventEmitter<File>();
    this.file = null;
    this.previewImage = null;
  }

  get fileName() {
    return this.file?.name || null;
  }

  get fileSize() {
    if (!this.file) {
      return null;
    }

    const fileSize = this.file.size.toString();

    if (fileSize.length < 7) {
      return `${Math.round(+fileSize / 1024).toFixed(2)}kb`;
    }

    return `${(Math.round(+fileSize / 1024) / 1000).toFixed(2)}MB`;
  }

  open() {
    this.modal.open();
  }

  close() {
    this.clear();
    this.modal.close();
  }

  clear() {
    this.file = null;
    this.previewImage = null;
  }

  onClickRemove() {
    this.file = null;
    this.previewImage = null;
  }

  onEventFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.handleFile(input.files[0]);
    }
  }

  onEventDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer?.files.length) {
      this.handleFile(event.dataTransfer.files[0]);
    }
  }

  onEventDragOver(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onClickAttach() {
    if (!this.file) return;
    this.upload.next(this.file);
    this.clear();
    this.modal.close();
  }

  onEventDismis() {
    this.clear();
  }

  private handleFile(file: File): void {
    this.file = file;

    if (file.type.startsWith('image/')) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewImage = e.target?.result || null;
      };

      reader.readAsDataURL(file);
    } else {
      this.previewImage = null;
    }
  }
}
