import { Pipe, PipeTransform } from '@angular/core';
import { isNull } from '@peca/commons';

@Pipe({
  name: 'orderDiscount',
  standalone: false,
})
export class OrderDiscountPipe implements PipeTransform {
  transform(value: number | null | undefined) {
    if (isNull(value)) return value;

    return Math.abs(value!) * -1;
  }
}
