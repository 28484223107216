import { Component } from '@angular/core';
import { BreadcrumbItem } from '@peca/design-system';

@Component({
  templateUrl: './buyer-container-form.component.html',
  styleUrls: ['./buyer-container-form.component.scss'],
  standalone: false,
})
export class BuyerContainerFormComponent {
  breadcrumb: BreadcrumbItem[];

  constructor() {
    this.breadcrumb = [{ label: 'Compradores', path: '/compradores' }, { label: 'Pessoa Jurídica', path: '/compradores/pessoa-juridica' }, { label: 'Novo' }];
  }
}
