import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';

import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import PermissionModuleQuery from '../models/module/module-query.model';
import { PermissionModule, PermissionModuleDetail } from '../models/module/module.model';

@Injectable({ providedIn: 'root' })
export class PermissionModuleService extends BaseHttpClient {
  private resource: string;

  constructor() {
    super();
    this.resource = `${environment.gateway.url}/backoffice/v2/platforms`;
  }

  fetchModules(query: PermissionModuleQuery, platformId: string) {
    const params = this.prepareQuery(query);
    return this.http.get<PermissionModule[]>(`${this.resource}/${platformId}/modules`, { params });
  }

  findById(platformId: string, moduleId: string): Observable<PermissionModuleDetail> {
    return this.http.get<PermissionModuleDetail>(`${this.resource}/${platformId}/modules/${moduleId}`);
  }

  delete(platformId: string, moduleId: string) {
    return this.http.delete(`${this.resource}/${platformId}/modules/${moduleId}`);
  }

  save(platformId: string, module: PermissionModule) {
    const payload = {
      name: module.name,
      description: module.description,
    };

    if (module?.id) {
      return this.http.put(`${this.resource}/${platformId}/modules/${module.id}`, payload);
    } else {
      return this.http.post(`${this.resource}/${platformId}/modules`, payload);
    }
  }
}
