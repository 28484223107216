import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsService, PersistQueryService, ToastService } from '@peca/commons';
import { Pagination, PaginationInitialState } from '@peca/design-system';
import { FeatureService } from '../../../services/feature.service';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import FeatureQuery from '../../../models/feature/feature-query.model';
import { Feature } from '../../../models/feature/feature.model';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

@Component({
  selector: 'peca-feature-list',
  templateUrl: './features-list.component.html',
  styleUrls: ['./features-list.component.scss'],
  standalone: false,
})
export class FeatureListComponent implements OnInit {
  private persistQueryService: PersistQueryService;
  private route: ActivatedRoute;
  private router: Router;
  private service: FeatureService;
  private lastQuery: FeatureQuery;
  private readonly searchFormInitialValue;
  private confirmable: ConfirmableActionService;
  private toast: ToastService;
  private analytics: AnalyticsService;

  form: FormGroup;
  features$: BehaviorSubject<AsyncState<Feature[]>>;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.persistQueryService = inject(PersistQueryService);
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.service = inject(FeatureService);
    this.confirmable = inject(ConfirmableActionService);
    this.toast = inject(ToastService);
    this.lastQuery = { sort: 'audit.createdAt,asc', ...PaginationInitialState };
    this.searchFormInitialValue = {
      search: '',
    };

    this.form = new FormGroup({
      search: new FormControl(''),
    });
    this.features$ = new BehaviorSubject<AsyncState<Feature[]>>({ loading: true });
  }

  get platformId() {
    const { platformId } = this.route.snapshot.params;
    return platformId as string;
  }

  get moduleId() {
    const { moduleId } = this.route.snapshot.params;
    return moduleId as string;
  }

  ngOnInit() {
    this.analytics.view('funcionalidades_listagem');
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.route.queryParams.subscribe((params) => this.updateFormWithQuery(params));

    const persistedQuery = this.persistQueryService.read<FeatureQuery>('peca-feature-list');
    const query = persistedQuery || this.lastQuery;
    this.fetchFeatures(query, this.platformId, this.moduleId);
  }

  updateFormWithQuery(params: Params) {
    if (!params['q']) return;
    const { search } = JSON.parse(atob(params['q']));
    this.form.patchValue({
      search: search || '',
    });
  }

  fetchFeatures(query: FeatureQuery, platformId: string, moduleId: string) {
    this.lastQuery = query;
    this.features$.next({ loading: true });
    this.service.fetchFeatures(query, platformId, moduleId).subscribe({
      next: (features) => {
        this.analytics.callback('funcionalidades_busca', { query, success: true });
        this.features$.next({ data: features });
      },
      error: () => {
        this.analytics.callback('funcionalidades_busca', { query, success: false });
        this.features$.next({ failure: true });
      },
    });
  }

  onClickDetails(id: string) {
    this.analytics.click('funcionalidades_click_detalhes');
    this.router.navigate(['/plataformas', this.platformId, this.moduleId, id]);
  }

  onClickRemove(feature: Feature) {
    const message = `Você está deletando uma funcionalidade.`;

    this.confirmable.confirm(message, feature, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        return;
      }

      this.service.delete(this.platformId, this.moduleId, feature.id).subscribe({
        next: () => {
          this.analytics.callback('funcionalidades_delete', { feature, success: true });

          this.toast.success(`Funcionalidade deletada com sucesso.`);
          this.fetchFeatures(this.lastQuery, this.platformId, this.moduleId);
        },
        error: () => {
          this.analytics.callback('funcionalidades_delete', { feature, success: false });
          this.toast.failure(`Falha ao deleta a funcionalidade.`);
        },
      });
    });
  }

  onClickSearch() {
    const query = { ...this.lastQuery, ...this.form.value };
    this.fetchFeatures(query, this.platformId, this.moduleId);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.onClickSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.form.markAsPristine();
    return this.form.markAsDirty();
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchFeatures(query, this.platformId, this.moduleId);
  }
}
