import { Component, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '@peca/design-system';
import { Buyer } from '../../models/buyer.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService, Validators } from '@peca/commons';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { BuyerLegalPersonService } from '../../services/buyer-legal-person.service';

@Component({
  selector: 'peca-buyer-reject-form',
  templateUrl: './buyer-reject-form.component.html',
  styleUrls: ['./buyer-reject-form.component.scss'],
  standalone: false,
})
export class BuyerRejectFormComponent {
  @Output()
  success: EventEmitter<void>;

  @ViewChild('modal')
  modal!: ModalComponent;
  buyerService: BuyerLegalPersonService;
  buyer: Buyer | null;
  form: FormGroup;
  confirmable: ConfirmableActionService;
  toast: ToastService;

  constructor() {
    this.confirmable = inject(ConfirmableActionService);
    this.buyerService = inject(BuyerLegalPersonService);
    this.toast = inject(ToastService);
    this.buyer = null;
    this.form = new FormGroup({ reason: new FormControl('', [Validators.required]) });
    this.success = new EventEmitter<void>();
  }

  open(buyer: Buyer) {
    this.buyer = buyer;
    this.modal.open();
  }

  close() {
    this.buyer = null;
    this.modal.close();
  }

  onClickSubmit() {
    if (!this.buyer || this.form.invalid) return;
    const { companyName, id } = this.buyer;
    const { reason } = this.form.getRawValue();

    this.confirmable.confirm(`Você está recusando o cadastro do comprador ${companyName}`, null, true).subscribe(({ confirmed }) => {
      if (!confirmed) return;

      this.buyerService.reject(id, reason).subscribe({
        next: () => {
          this.toast.success('Comprador recusado com sucesso.');
          this.close();
          this.success.next();
        },
        error: () => this.toast.failure('Falha ao recusar comprador'),
      });
    });
  }
}
