import { Component, EventEmitter, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { KeyTab, Tab, BreadcrumbItem, ModalComponent } from '@peca/design-system';
import { BehaviorSubject, Subject, map, takeUntil, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@peca/commons';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { AnalyticsService } from '@peca/commons';

import { Order } from '../../models/order.model';
import { OrderService } from '../../services/order.service';
import { AsyncState } from '../../../../core/models/async-state.model';
import { OrderReturnComponent } from '../order-return/order-return.component';
import { OrderObservationComponent } from '../order-observation/order-observation.component';
import { OrderChangeStatusComponent } from '../order-change-status/order-change-status.component';
import { OrderOperationsValidadorService } from '../../services/order-operations-validador.service';
import { OrderChangePricesComponent } from '../order-change-prices/order-change-prices.component';

@Component({
  selector: 'peca-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  standalone: false,
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('orderObservation')
  orderObservation!: OrderObservationComponent;

  @ViewChild('changeStatusComponent')
  changeStatusComponent!: OrderChangeStatusComponent;

  @ViewChild('orderReturnComponent')
  orderReturnComponent!: OrderReturnComponent;

  @ViewChild('invoiceObservations')
  invoiceObservations!: ModalComponent;

  @ViewChild('changePricesModal')
  changePricesModal!: OrderChangePricesComponent;

  orderOperationsValidador: OrderOperationsValidadorService;
  orderService: OrderService;
  destroyRef$: Subject<void>;
  order$: BehaviorSubject<AsyncState<Order>>;
  route: ActivatedRoute;
  toast: ToastService;
  childReloaded: Subject<void>;
  confirmableAction: ConfirmableActionService;
  analytics: AnalyticsService;
  orderId: string;
  breadcrumbItems: BreadcrumbItem[] = [{ label: 'Vendas', path: '/vendas' }];
  tabs: KeyTab = {
    products: {
      label: 'Produtos',
      path: 'produtos',
      roles: [],
    },
    orders: {
      label: 'Pedidos de compra',
      path: 'compras',
      roles: [],
    },
    shippings: {
      label: 'Entregas',
      path: 'entregas',
      roles: [],
    },
    payments: {
      label: 'Pagamentos',
      path: 'pagamentos',
      roles: [],
    },
    logs: {
      label: 'Logs',
      path: 'logs',
      roles: [],
    },
  };

  constructor() {
    this.orderOperationsValidador = inject(OrderOperationsValidadorService);
    this.orderService = inject(OrderService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.confirmableAction = inject(ConfirmableActionService);
    this.analytics = inject(AnalyticsService);
    this.destroyRef$ = new Subject<void>();
    this.order$ = new BehaviorSubject<AsyncState<Order>>({ loading: true });
    this.childReloaded = new Subject<void>();
    this.orderId = '';
  }

  get selectedTab() {
    const tab = Object.values<Tab>(this.tabs).find(({ active }) => active);

    if (!tab) return this.tabs[0];

    return tab;
  }

  get order() {
    return this.order$.value.data as Order;
  }

  get buyerEmail() {
    const [email] = this.order?.buyer?.contact.split(',') || '';

    if (!email) return '';

    const [, value] = email.split(':').map((v) => v.trim());

    return value;
  }

  ngOnInit() {
    this.route.params
      .pipe(
        map(({ orderId }) => orderId),
        tap((orderId) => (this.orderId = orderId)),
        takeUntil(this.destroyRef$)
      )
      .subscribe(this.fetchDetails.bind(this));
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  fetchDetails(orderId: string) {
    this.breadcrumbItems = [{ label: 'Vendas', path: '/vendas' }];
    this.order$.next({ loading: true });
    this.orderService.fetchDetails(orderId).subscribe({
      next: this.onFetchDetailsSuccess.bind(this),
      error: this.onFetchDetailsFailure.bind(this),
    });
  }

  onFetchDetailsSuccess(order: Order) {
    this.breadcrumbItems.push({ label: order.commerceReference });
    this.order$.next({ data: order });
  }

  onFetchDetailsFailure() {
    this.order$.next({ failure: true });
  }

  onTabSelected(tab: Tab) {
    this.selectedTab.active = false;
    tab.active = true;
  }

  onAdjust() {
    this.fetchDetails(this.orderId);
  }

  onChildActivate(event: Component & { reload: EventEmitter<void> }) {
    if (!event.reload) return;
    event.reload.pipe(takeUntil(this.destroyRef$)).subscribe(() => {
      this.fetchDetails(this.orderId);
    });
  }

  onClickCancelOrder() {
    const validation = this.orderOperationsValidador.validateOrderCancelation(this.order);

    if (validation) {
      this.toast.warning(validation.message);
      return;
    }

    const message = `Você está cancelando o pedido ${this.order$.value.data?.commerceReference}.`;

    this.confirmableAction.confirm(message, null, true).subscribe((confimation) => {
      if (!confimation.confirmed) return;

      this.orderService.cancelOrder(this.orderId).subscribe({
        next: () => {
          this.toast.success('Pedido canceledo com sucesso.');
          this.fetchDetails(this.orderId);
        },
        error: () => {
          this.toast.failure('Falha ao cancelar o pedido.');
        },
      });
    });
  }

  onClickChangeStatus() {
    if (!this.order$.value.data) return;
    this.changeStatusComponent.open(this.orderId, this.order$.value.data.internalStatus);
  }

  onClickAddObservation() {
    if (!this.order$.value.data) return;
    this.orderObservation.open(this.order$.value.data);
  }

  onClickEmitInvoice() {
    const validation = this.orderOperationsValidador.validateInvoiceIssuance(this.order);

    if (validation) {
      this.toast.warning(validation.message);
      return;
    }

    const message = `Você está solicitando emissão de nota fiscal para o pedido ${this.order$.value.data?.commerceReference}.`;
    this.confirmableAction.confirm(message, null, true).subscribe((confimation) => {
      if (!confimation.confirmed) return;
      this.orderService.issueInvoice(this.orderId).subscribe({
        next: () => {
          this.toast.success('Emissão de nota fiscal solicitada com sucesso.');
          this.fetchDetails(this.orderId);
        },
        error: () => {
          this.toast.failure('Não foi possível solicitar emissão de nota fiscal.');
        },
      });
    });
  }

  onClickReturnOrder() {
    const validation = this.orderOperationsValidador.validateOrderReturn(this.order);

    if (validation) {
      this.toast.warning(validation.message);
      return;
    }

    this.orderReturnComponent.open(this.order);
  }

  onClickChangePrices() {
    if (!this.order) return;

    const validation = this.orderOperationsValidador.validateChangePrices(this.order);

    if (validation) {
      this.toast.warning(validation.message);
      return;
    }

    this.changePricesModal.open(this.order);
  }
}
