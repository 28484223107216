import { Router } from '@angular/router';
import { ToastService } from '@peca/commons';
import { Component, inject } from '@angular/core';

import { CarrierForm } from '../carrier-form/carrier-form';
import { CarrierService } from '../../services/carrier.service';
import { BreadcrumbItem } from '@peca/design-system';

@Component({
  selector: 'peca-carrier-create',
  templateUrl: './carrier-create.component.html',
  styleUrls: ['./carrier-create.component.scss'],
  standalone: false,
})
export class CarrierCreateComponent {
  readonly service: CarrierService;
  readonly router: Router;
  readonly toast: ToastService;
  readonly form: CarrierForm;
  breadcrumbItems: BreadcrumbItem[] = [
    {
      label: 'Listagem de Tranportadoras',
      path: '/transportadoras',
    },
    {
      label: 'Nova transportadora',
    },
  ];

  constructor() {
    this.router = inject(Router);
    this.service = inject(CarrierService);
    this.toast = inject(ToastService);
    this.form = new CarrierForm();
  }

  handleSubmitEvent() {
    if (this.form.instance.invalid) return;

    this.form.instance.disable();
    this.service.createCarrier(this.form.instance.getRawValue()).subscribe({
      next: this.onSubmitSuccess.bind(this),
      error: this.onSubmitFailure.bind(this),
    });
  }

  onSubmitSuccess() {
    this.router.navigate(['/transportadoras']);
    this.toast.success('Transportadora cadastrada com sucesso.');
  }

  onSubmitFailure(e: unknown) {
    this.toast.failure('Ocorreu um erro ao cadastrar a transportadora.');
    this.form.instance.enable();
  }
}
