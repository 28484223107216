import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'freightType',
  standalone: false,
})
export class FreightTypePipe implements PipeTransform {
  transform(value: string) {
    if (!value) return '-';

    switch (value) {
      case 'DROP_SHIPPING':
        return 'Drop Shipping';
      case 'CROSS_DOCKING':
        return 'Cross Docking';
      default:
        return value;
    }
  }
}
