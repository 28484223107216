import { Directive, ElementRef, forwardRef, HostListener, inject, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[pdsNumberMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberMaskDirective),
      multi: true,
    },
  ],
  standalone: false,
})
export class NumberMaskDirective implements OnInit {
  hostElementRef: ElementRef;
  specialKeys: Array<string>;
  onChange!: (value: string) => void;
  onTouched!: () => void;

  constructor() {
    this.hostElementRef = inject(ElementRef);
    this.specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];
  }

  get value() {
    return this.hostElementRef.nativeElement.value;
  }

  set value(value: string) {
    this.hostElementRef.nativeElement.value = value;
  }

  ngOnInit() {
    this.value = this.sanitize(this.value);
  }

  @HostListener('input', ['$event'])
  onKeydown() {
    const sanitized = this.sanitize(this.value);
    const [units, decimals] = sanitized.split(',');

    if (decimals) {
      this.value = `${units},${decimals.substring(0, 2)}`;
    } else if (sanitized.length && !units) {
      this.value = `0${sanitized}`;
    } else {
      this.value = sanitized;
    }

    this.onChange(this.value);
  }

  writeValue(value: string): void {
    if (value !== undefined && value !== null) {
      this.value = this.sanitize(value);
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.hostElementRef.nativeElement.disabled = isDisabled;
  }

  private sanitize(value: string) {
    const sanitizedValue = value
      .toString()
      .replace('.', ',')
      .replace(/[^\d,]/g, '');

    const parts = sanitizedValue.split(',');

    if (parts.length > 2) {
      return parts[0] + ',' + parts.slice(1).join('');
    }

    return sanitizedValue;
  }
}
