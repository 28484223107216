<pds-modal #modal title="Substituir Vendedor" [width]="1024" (dismiss)="close()">
  <pds-form>
    <pds-form-section *ngIf="currentProduct" title="Produto Atual">
      <pds-table>
        <table>
          <thead>
            <tr>
              <th rowspan="2" style="width: 24%">Partnumber</th>
              <th rowspan="2">Marca</th>
              <th colspan="3">Vendedor</th>
              <th rowspan="2">QMV</th>
              <th rowspan="2">Qtd.</th>
              <th rowspan="2">Preço Unt.</th>
              <th rowspan="2">Preço Total</th>
            </tr>
            <tr>
              <th>Nome</th>
              <th>Filial</th>
              <th>Und. de negócio</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="product-substitute-description">
                  <strong>{{ currentProduct.partNumber }}</strong>
                  <small>{{ currentProduct.description }}</small>
                </div>
              </td>
              <td>{{ currentProduct.brand }}</td>
              <td>{{ currentProduct.branch.commercialName }}</td>
              <td>{{ currentProduct.branch.externalReference }}</td>
              <td>{{ currentProduct.branch.businessUnit }}</td>
              <td>{{ currentProduct.qmv }}</td>
              <td>{{ currentProduct.quantity }}</td>
              <td>{{ currentProduct.unitPrice | currency : 'BRL' }}</td>
              <td>{{ currentProduct.totalPrice | currency : 'BRL' }}</td>
            </tr>
          </tbody>
        </table>
      </pds-table>
    </pds-form-section>

    <pds-form-section title="Ajustar por">
      <pds-tabs [tabs]="tabs" (selected)="handleTabSelectEvent($event)">
        <ng-container *ngFor="let tab of tabs">
          <section [hidden]="!tab.active">
            <pds-form-section *ngIf="products$ | async; let products" [hidden]="substituteProduct">
              @if (tab.target === 'vendedor' || tab.target === 'similar' && tab.active) {
              <peca-order-table-replace-seller [products]="products$ | async" [onlySameSeller]="onlySameSeller" (clickSelectProduct)="onClickSelectProduct($event)" [currentProduct]="currentProduct" />
              }@else if (tab.target === 'item' && tab.active) {
              <ng-container *ngIf="products$ | async; let products">
                <pds-form [formGroup]="formCatalogIdSearch">
                  <pds-form-row>
                    <pds-form-field label="Insira o catálogo ID">
                      <input formControlName="catalogId" type="text" />
                    </pds-form-field>
                  </pds-form-row>
                  <pds-form-actions>
                    <button [disabled]="formCatalogIdControls.catalogId?.invalid" (click)="searchItem()" pdsButton>Buscar</button>
                  </pds-form-actions>
                </pds-form>
                <peca-order-table-replace-seller *ngIf="products.data" [products]="products$ | async" [onlySameSeller]="onlySameSeller" (clickSelectProduct)="onClickSelectProduct($event)" [currentProduct]="currentProduct" />
              </ng-container>
              }
            </pds-form-section>

            <pds-form-section *ngIf="substituteProduct" title="Produto Selecionado" [formGroup]="form.group">
              <pds-table>
                <table>
                  <thead>
                    <tr>
                      <th rowspan="2" style="width: 24%">Partnumber</th>
                      <th rowspan="2">Marca</th>
                      <th colspan="3">Vendedor</th>
                      <th rowspan="2">QMV</th>
                      <th rowspan="2">Estoque</th>
                      <th rowspan="2">Preço Unt.</th>
                      <th rowspan="2"></th>
                    </tr>
                    <tr>
                      <th>Nome</th>
                      <th>Filial</th>
                      <th>Und. de negócio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="product-substitute-description">
                          <strong>{{ substituteProduct.partNumber }}</strong>
                          <small>{{ substituteProduct.description }}</small>
                        </div>
                      </td>
                      <td>{{ substituteProduct.brand.name }}</td>
                      <td>{{ substituteProduct.branch.commercialName }}</td>
                      <td>{{ substituteProduct.branch.externalReference }}</td>
                      <td>{{ substituteProduct.branch.businessUnit }}</td>
                      <td>{{ substituteProduct.qmv }}</td>
                      <td>{{ substituteProduct.stock }}</td>
                      <td>{{ substituteProduct.salePrice | currency : 'BRL' }}</td>
                      <td pdsColActions>
                        <button pdsButton="icon" title="Selecionar substituto" (click)="onClickRemoveSubstituteProduct()">
                          <span class="icon">remove</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </pds-table>

              <pds-form-row grid="1-1-1-1">
                <pds-form-field label="Quantidade">
                  <input type="number" formControlName="quantity" />
                </pds-form-field>
                <pds-form-field label="Preço unitário">
                  <input type="text" [value]="form.value.unitPrice.formmated" disabled />
                </pds-form-field>
                <pds-form-field label="Preço total">
                  <input type="text" [value]="form.value.totalPrice.formmated" disabled />
                </pds-form-field>
                <pds-form-field label="Diferença de preço">
                  <input type="text" [value]="form.value.diffPrice.formmated" disabled />
                </pds-form-field>
              </pds-form-row>

              <pds-form-row>
                <pds-form-field label="Justificativa">
                  <input type="text" formControlName="reason" />
                </pds-form-field>
              </pds-form-row>
            </pds-form-section>

            <ng-container *ngIf="substituteProduct">
              <hr />

              <pds-form-actions>
                <button pdsButton data-id="btnReplaceProductSubmit" [disabled]="form.group.invalid" (click)="onClickSubmit()">Salvar</button>
              </pds-form-actions>
            </ng-container>
          </section>
        </ng-container>
      </pds-tabs>
    </pds-form-section>
  </pds-form>
</pds-modal>
