import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderStatus } from '../../models/order-status.model';
import { Order } from '../../models/order.model';

@Component({
  selector: 'peca-order-steps[order]',
  templateUrl: './order-steps.component.html',
  styleUrls: ['./order-steps.component.scss'],
  standalone: false,
})
export class OrderStepsComponent implements OnInit {
  @Input()
  order!: Order;

  steps$: BehaviorSubject<OrderStatus[]>;

  constructor() {
    const steps = this.getInitialStepsValue();
    this.steps$ = new BehaviorSubject<OrderStatus[]>(steps);
  }

  ngOnInit() {
    const order = this.order;
    this.updateStepsValue(order);
  }

  updateStepsValue(order: Order) {
    const [first, ...steps] = this.getInitialStepsValue();
    const nextSteps: OrderStatus[] = [];

    // PROCESSING_PAYMENT
    first.changedAt = order.audit?.createdAt;
    first.completed = true;

    for (const step of steps) {
      const orderStep = order.publicStatusChangeLogs.find(({ newStatus }) => newStatus === step.newStatus);

      if (orderStep) {
        nextSteps.push({ ...orderStep, completed: true });
      } else if (this.isInExceptionStatus(order)) {
        nextSteps.push(this.getStepByCurrentStatus(order));
        break;
      } else {
        nextSteps.push(step);
      }
    }

    this.steps$.next([first, ...nextSteps]);
  }

  private getStepByCurrentStatus(order: Order) {
    const step = order.publicStatusChangeLogs.find(({ newStatus }) => newStatus === order.publicStatus);

    if (!step) throw 'unexpected order status';

    return { ...step, exception: true, completed: true };
  }

  private isInExceptionStatus(order: Order) {
    const exceptions = ['PAYMENT_REFUSED', 'CANCELED'];
    return exceptions.includes(order.publicStatus);
  }

  private getInitialStepsValue() {
    return [
      { newStatus: 'PROCESSING_PAYMENT', completed: false },
      { newStatus: 'PAYMENT_ACCEPT', completed: false },
      { newStatus: 'IN_SEPARATION', completed: false },
      { newStatus: 'ON_ROUTE', completed: false },
      { newStatus: 'DELIVERED', completed: false },
    ] as OrderStatus[];
  }
}
