import { Subject, filter } from 'rxjs';
import { CommonModule } from '@angular/common';
import { LoadingOverlayService } from '@peca/commons';
import { DesignSystemModule } from '@peca/design-system';
import { KeycloakCredentialsService } from '@peca/keycloak';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';

import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { MainNavigationMenuComponent } from './main-navigation-menu/main-navigation-menu.component';
import { MainNavigationInsightsComponent } from './main-navigation-insights/main-navigation-insights.component';
import { MAIN_NAVIGATION_CONFIG } from './main-navigation.config';

@Component({
  imports: [MainNavigationMenuComponent, MainNavigationInsightsComponent, CommonModule, RouterOutlet, RouterLink, DesignSystemModule],
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class PDSMainNavigationComponent implements OnInit, OnDestroy {
  router = inject(Router);
  keycloakCredentialsService = inject(KeycloakCredentialsService);
  loadingOverlayService = inject(LoadingOverlayService);
  config = inject(MAIN_NAVIGATION_CONFIG);
  opened = false;
  destroyRef$ = new Subject<void>();

  get username() {
    return this.keycloakCredentialsService.credentials?.username;
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  ngOnInit() {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => (this.opened = false));
  }

  onClickToogleMenu() {
    this.opened = !this.opened;
  }

  async onClickLogout() {
    this.loadingOverlayService.set(true);
    await this.keycloakCredentialsService.logout();
  }
}
