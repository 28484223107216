import { Component } from '@angular/core';

@Component({
  selector: 'peca-root',
  template: `
    <router-outlet />
    <peca-toast />
    <peca-loading-overlay />
    <peca-confirmable-action-2 />
  `,
  standalone: false,
})
export class AppComponent {}
