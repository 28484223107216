import { FormControl, FormGroup } from '@angular/forms';

export class SearchForm {
  group: FormGroup;

  constructor() {
    this.group = new FormGroup({
      orderId: new FormControl(''),
      buyer: new FormControl(null),
      date: new FormControl({ startDate: '', endDate: '' }),
      status: new FormControl([]),
      trackingCode: new FormControl(''),
      salesChannelIds: new FormControl([]),
      carrierIds: new FormControl([]),
      deliveryTime: new FormControl([]),
    });
  }

  get controls() {
    return {
      orderId: this.group.get('orderId'),
      buyer: this.group.get('buyer'),
      date: this.group.get('date'),
      status: this.group.get('status'),
      trackingCode: this.group.get('trackingCode'),
      salesChannelIds: this.group.get('salesChannelIds'),
      carrierIds: this.group.get('carrierIds'),
      deliveryTime: this.group.get('deliveryTime'),
    };
  }

  get values() {
    return {
      orderId: this.group.get('orderId')?.value,
      buyer: this.group.get('buyer')?.value,
      date: this.group.get('date')?.value,
      status: this.group.get('status')?.value,
      trackingCode: this.group.get('trackingCode')?.value,
      salesChannelIds: this.group.get('salesChannelIds')?.value,
      carrierIds: this.group.get('carrierIds')?.value,
      deliveryTime: this.group.get('deliveryTime')?.value,
    };
  }
}
