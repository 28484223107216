<pds-panel *ngIf="module$ | async; let module">
  <ng-container *ngIf="module.loading"><strong>carregando...</strong></ng-container>
  <ng-container *ngIf="module.failure"><strong>Não foi possível carregar os dados do módulo.</strong></ng-container>

  <ng-container *ngIf="!module.loading && !module.failure">
    <pds-form [formGroup]="form.group">
      <pds-form-section title="Informações do módulo">
        <pds-form-row grid="1-1">
          <pds-form-field label="Nome">
            <input type="text" formControlName="name" />
          </pds-form-field>
          <pds-form-field label="Descrição">
            <input type="text" formControlName="description" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-actions>
        <ng-container *ngIf="isUpdateOperation && !form.group.enabled">
          <button pdsButton (click)="onClickEnable()">Editar</button>
        </ng-container>

        <ng-container *ngIf="form.group.enabled">
          <ng-container *ngIf="form.getValue().id && form.group.enabled">
            <button (click)="onClickCancel()" pdsButton="primary">Cancelar</button>
          </ng-container>
          <button pdsButton (click)="onClickSubmit()" [disabled]="form.group.invalid">salvar</button>
        </ng-container>
      </pds-form-actions>

      @if (isUpdateOperation) {
      <hr />
      <peca-feature-list></peca-feature-list>
      }
    </pds-form>
  </ng-container>
</pds-panel>
