import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';

import { environment } from '../../../../environments/environment';
import { SettingsSeller } from '../models/seller-settings.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SellerSettingService extends BaseHttpClient {
  private readonly api;

  constructor() {
    super();
    this.api = `${environment.gateway.url}/backoffice/v1/sellers`;
  }

  fetchSellersSettings(sellerId: string): Observable<SettingsSeller> {
    return this.http.get<SettingsSeller>(`${this.api}/${sellerId}/settings`);
  }

  updateSettingsSellers(sellerId: string, settings: SettingsSeller): Observable<SettingsSeller> {
    return this.http.put<SettingsSeller>(`${this.api}/${sellerId}/settings`, {
      clientId: settings.apiCredential.clientId,
      ftpCredential: settings.ftpCredential,
      webhooks: settings.webhooks,
    });
  }

  refreshClientSecret(sellerId: string) {
    const resource = `${this.api}/${sellerId}/settings/client-secret`;
    return this.http.patch<{ newClientSecret: string }>(resource, {});
  }

  initSettings(sellerId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/sellers/${sellerId}/settings`;
    return this.http.post<SettingsSeller>(resource, {});
  }
}
