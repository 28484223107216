import { Component, inject, OnInit } from '@angular/core';
import { BreadcrumbItem } from '@peca/design-system';
import { ChangeDetectorRef } from '@angular/core';

interface ActionButton {
  style: 'default' | 'primary' | 'secondary' | 'terciary';
  path: string;
  text: string;
}
@Component({
  selector: 'peca-permission-container',
  templateUrl: './permission-container.component.html',
  styleUrls: ['./permission-container.component.scss'],
  standalone: false,
})
export class PermissionContainerComponent {
  cdr: ChangeDetectorRef;

  breadcrumb: BreadcrumbItem[];
  title_: string;
  actionButtons: ActionButton[];

  constructor() {
    this.cdr = inject(ChangeDetectorRef);

    this.title_ = 'Plataformas';
    this.actionButtons = [];

    this.breadcrumb = [{ label: 'Listagem de Plataformas', path: '/plataformas' }];
  }

  set title(title: string) {
    this.title_ = title;
  }

  get title() {
    return this.title_;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
