import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService, Validators, currency, numeric } from '@peca/commons';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { DeliveryType } from '../../models/delivery-type.model';
import { DeliveryTypeService } from '../../services/delivery-type.service';
import { BreadcrumbItem } from '@peca/design-system';
import { CarrierService } from '../../services/carrier.service';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

@Component({
  selector: 'peca-carrier-delivery-type-form',
  templateUrl: './carrier-delivery-type-form.component.html',
  styleUrls: ['./carrier-delivery-type-form.component.scss'],
  standalone: false,
})
export class CarrierDeliveryTypeFormComponent implements OnInit {
  toast: ToastService;
  deliveryTypeService: DeliveryTypeService;
  carrierService: CarrierService;
  route: ActivatedRoute;
  valueHolder: DeliveryType | null;
  form: FormGroup;
  confirmable = inject(ConfirmableActionService);
  breadcrumbItems: BreadcrumbItem[];

  @Input('deliveryType')
  deliveryType$: Observable<DeliveryType>;

  constructor() {
    this.toast = inject(ToastService);
    this.route = inject(ActivatedRoute);
    this.deliveryTypeService = inject(DeliveryTypeService);
    this.carrierService = inject(CarrierService);
    this.deliveryType$ = new Subject<DeliveryType>().asObservable();
    this.breadcrumbItems = [
      {
        label: 'Listagem de Tranportadoras',
        path: '/transportadoras',
      },
      {
        label: 'Transportadora',
        path: `/transportadoras/${this.carrierId}`,
      },
      {
        label: 'Novo Tipo de Entrega',
      },
    ];
    this.valueHolder = null;
    this.form = new FormGroup({
      id: new FormControl(''),
      externalReference: new FormControl('', Validators.required),
      name: new FormControl('', [Validators.required]),
      publicName: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      observation: new FormControl(''),
      fixedPrice: new FormControl('', [Validators.required, Validators.number.min(0, true)]),
      callPrice: new FormControl('', [Validators.required, Validators.number.min(0, true)]),
      kmPrice: new FormControl('', [Validators.required, Validators.number.min(0, true)]),
      stopPrice: new FormControl('', [Validators.required, Validators.number.min(0, true)]),
      fixedTime: new FormControl('', [Validators.required, Validators.number.min(0)]),
      kmTime: new FormControl('', [Validators.required, Validators.number.min(0)]),
      minWeightKg: new FormControl('', [Validators.required, Validators.number.min(0)]),
      maxWeightKg: new FormControl('', [Validators.required, Validators.number.min(0)]),
      maxHeightCm: new FormControl('', [Validators.required, Validators.number.min(0)]),
      maxWidthCm: new FormControl('', [Validators.required, Validators.number.min(0)]),
      maxLengthCm: new FormControl('', [Validators.required, Validators.number.min(0)]),
      minDistanceKm: new FormControl('', [Validators.required, Validators.number.min(0)]),
      maxDistanceKm: new FormControl('', [Validators.required, Validators.number.min(0)]),
      maxDimensionsSumCm: new FormControl(''),
      active: new FormControl(true),
    });
  }

  //#region getters
  get carrierId() {
    return this.route.snapshot.params['carrierId'] as string;
  }

  get disabled() {
    return this.form.pristine || this.form.invalid;
  }

  get value() {
    return {
      id: this.form.value.id,
      name: this.form.value.name,
      externalReference: this.form.value.externalReference,
      publicName: this.form.value.publicName,
      description: this.form.value.description,
      observation: this.form.value.observation,
      fixedPrice: numeric(this.form.value.fixedPrice),
      callPrice: numeric(this.form.value.callPrice),
      kmPrice: numeric(this.form.value.kmPrice),
      stopPrice: numeric(this.form.value.stopPrice),
      fixedTime: numeric(this.form.value.fixedTime),
      kmTime: numeric(this.form.value.kmTime),
      minWeightKg: numeric(this.form.value.minWeightKg),
      maxWeightKg: numeric(this.form.value.maxWeightKg),
      maxHeightCm: numeric(this.form.value.maxHeightCm),
      maxWidthCm: numeric(this.form.value.maxWidthCm),
      maxLengthCm: numeric(this.form.value.maxLengthCm),
      minDistanceKm: numeric(this.form.value.minDistanceKm),
      maxDistanceKm: numeric(this.form.value.maxDistanceKm),
      active: this.form.value.active,
    } as DeliveryType;
  }
  //#endregion

  //#region setters
  set value(value: DeliveryType) {
    this.form.get('id')?.setValue(value.id);
    this.form.get('externalReference')?.setValue(value.externalReference);
    this.form.get('name')?.setValue(value.name);
    this.form.get('publicName')?.setValue(value.publicName);
    this.form.get('description')?.setValue(value.description);
    this.form.get('observation')?.setValue(value.observation);
    this.form.get('fixedPrice')?.setValue(currency(value.fixedPrice));
    this.form.get('callPrice')?.setValue(currency(value.callPrice));
    this.form.get('kmPrice')?.setValue(currency(value.kmPrice));
    this.form.get('stopPrice')?.setValue(currency(value.stopPrice));
    this.form.get('fixedTime')?.setValue(value.fixedTime);
    this.form.get('kmTime')?.setValue(value.kmTime);
    this.form.get('minWeightKg')?.setValue(value.minWeightKg);
    this.form.get('maxWeightKg')?.setValue(value.maxWeightKg);
    this.form.get('maxHeightCm')?.setValue(value.maxHeightCm);
    this.form.get('maxWidthCm')?.setValue(value.maxWidthCm);
    this.form.get('maxLengthCm')?.setValue(value.maxLengthCm);
    this.form.get('minDistanceKm')?.setValue(value.minDistanceKm);
    this.form.get('maxDistanceKm')?.setValue(value.maxDistanceKm);
    this.form.get('maxDimensionsSumCm')?.setValue(value.maxDimensionsSumCm);
    this.form.get('active')?.setValue(value.active);
  }
  //#endregion

  ngOnInit() {
    this.deliveryType$.subscribe((value) => {
      this.value = value;
      this.form.disable();
      this.breadcrumbItems[2].label = value.publicName;
    });

    this.carrierService.fetchCarrierDetails(this.carrierId).subscribe({
      next: (carrierDetails) => (this.breadcrumbItems[1].label = carrierDetails.commercialName),
    });
  }

  //#region save
  save(deliveryType: DeliveryType) {
    this.form.enable();
    this.deliveryTypeService.save(this.carrierId, deliveryType).subscribe({
      next: this.onSaveSuccess.bind(this),
      error: this.onSaveFailure.bind(this),
    });
  }
  onSaveSuccess() {
    this.toast.success('Tipo de entrega salva com sucesso.');
  }

  onSaveFailure(e: unknown) {
    this.form.enable();
    this.toast.failure('Falha ao salvar o tipo de entrega.');
  }
  //#endregion

  //#region updateStatus
  updateStatus() {
    const { id, active } = this.value;
    this.valueHolder = this.form.value;
    this.form.get('active')?.setValue(!active);
    this.deliveryTypeService.updateStatus(this.carrierId, id, !active).subscribe({
      next: this.onUpdateStatusSuccess.bind(this),
      error: this.onUpdateStatusFailure.bind(this),
    });
  }

  onUpdateStatusSuccess() {
    this.toast.success('Tipo de entrega alterado com sucesso.');
    this.form.updateValueAndValidity();
    window.location.reload();
  }

  onUpdateStatusFailure(e: unknown) {
    this.toast.failure('Falha ao alterar status do tipo de entrega.');
    if (this.valueHolder) this.form.setValue(this.valueHolder);
  }
  //#endregion

  //#region handlers
  handleCancelEvent() {
    if (this.valueHolder) this.form.setValue(this.valueHolder);
    this.form.disable();
  }

  handleChangeStatusEvent() {
    this.confirmable.confirm('Você está alterando o status de um tipo de entrega.').subscribe(({ confirmed }) => {
      if (!confirmed) {
        return;
      }

      this.updateStatus();
    });
  }

  handleEnableEvent() {
    this.valueHolder = this.form.value;
    this.form.enable();
  }

  handleSubmitEvent() {
    const error = 'Formulário inválido. Verifique os campos e tente novamente.';

    if (this.form.invalid) {
      this.toast.failure(error);
      return;
    }

    this.save(this.value);
  }

  //#endregion
}
