import { ToastService, Validators } from '@peca/commons';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { BreadcrumbItem, Tab } from '@peca/design-system';

import { SellerService } from '../../services/seller.service';
import { AsyncState } from '../../../../core/models/async-state.model';
import { Seller } from '../../models/seller.model';

@Component({
  selector: 'peca-seller-details',
  templateUrl: './seller-details.component.html',
  styleUrls: ['./seller-details.component.scss'],
  standalone: false,
})
export class SellerDetailsComponent implements OnInit, OnDestroy {
  seller$: BehaviorSubject<AsyncState<Seller>>;
  service: SellerService;
  route: ActivatedRoute;
  destroyRef$: Subject<void>;
  form: FormGroup;
  router: Router;
  toast: ToastService;
  tabs: Tab[];
  breadcrumbItems: BreadcrumbItem[];

  constructor() {
    this.seller$ = new BehaviorSubject<AsyncState<Seller>>({ loading: true });
    this.service = inject(SellerService);
    this.route = inject(ActivatedRoute);
    this.destroyRef$ = new Subject<void>();
    this.form = new FormGroup({
      fragaReference: new FormControl('', [Validators.required, Validators.length.exactly(36)]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.router = inject(Router);
    this.toast = inject(ToastService);
    this.breadcrumbItems = [{ label: 'Vendedores', path: '/vendedores' }, { label: 'Ver Vendedor' }];
    this.tabs = [
      {
        label: 'Filiais',
        path: './filiais',
        active: true,
        roles: [],
      },
      {
        label: 'Configurações',
        path: './configurações',
        active: false,
        roles: [['vendedores-cadastrar-configuracao']],
      },
    ];
  }

  ngOnInit() {
    const { sellerId } = this.route.snapshot.params;
    this.fetchSellerDetails(sellerId);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  // fetch seller details lifecycle
  fetchSellerDetails(id: string) {
    this.seller$.next({ loading: true });
    this.service.fetchSellerDetails(id).subscribe({
      next: this.onFetchSellerDetailsSuccess.bind(this),
      error: this.onFetchSellerDetailsFailure.bind(this),
    });
  }

  onFetchSellerDetailsSuccess(seller: Seller) {
    const { name, fragaReference, email } = seller;
    this.form.setValue({ name, fragaReference, email });
    this.form.disable();
    this.form.updateValueAndValidity();
    this.seller$.next({ data: seller });
    this.breadcrumbItems[1] = {
      label: seller.name,
    };
  }

  onFetchSellerDetailsFailure(e: unknown) {
    this.seller$.next({ failure: true });
  }
  // end fetch seller details lifecycle

  // handlers
  handleCancelClickEvent() {
    const { name, fragaReference, email } = this.seller$.value.data || {};
    this.form.setValue({ name, fragaReference, email });
    this.form.disable();
  }

  handleSaveClickEvent() {
    const { sellerId } = this.route.snapshot.params;

    if (this.form.invalid) return;

    this.service.updateSeller(this.form.getRawValue(), sellerId).subscribe({
      next: () => {
        this.toast.success('Vendedor atualizado com sucesso.');
        this.form.disable();
      },
      error: () => this.toast.success('Falha ao atualizar vendedor.'),
    });
  }

  handleEditClickEvent() {
    this.form.enable();
  }
}
