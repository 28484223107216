import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logType',
  standalone: false,
})
export class OrderReturnLogTypePipe implements PipeTransform {
  transform(value: string) {
    switch (value) {
      case 'UPLOAD_FILES':
        return 'Upload de imagem';
      case 'DELETE_FILE':
        return 'Remoção de imagem';
      case 'UPDATE_OBSERVATION':
        return 'Observação';
      case 'UPDATE_INVOICE':
        return 'NFe de devolução';
      case 'UPDATE_PAYMENT_STATUS':
        return 'Status do pagamento';
      case 'UPDATE_STATUS':
        return 'Status';
      default:
        return value;
    }
  }
}
