import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusType } from '../models/order-status';

@Pipe({
  name: 'orderStatusColor',
  standalone: false,
})
export class OrderStatusColorPipe implements PipeTransform {
  transform(status: OrderStatusType) {
    switch (status) {
      case 'PROCESSING_PAYMENT':
      case 'WAITING_FOR_SELLER':
      case 'IN_SEPARATION':
      case 'AWAITING_COLLECTION':
      case 'ON_ROUTE':
      case 'PROCESSING_RETURN':
        return 'default';
      case 'PAYMENT_ACCEPT':
      case 'DELIVERED':
      case 'PARTIAL_RETURN':
      case 'TOTAL_RETURN':
        return 'secondary';
      case 'PAYMENT_REFUSED':
      case 'CANCELED':
      case 'MISPLACED':
        return 'terciary';
      case 'REQUIRES_MANUAL_ADJUSTMENT':
        return 'primary';
      default:
        return 'default';
    }
  }
}
