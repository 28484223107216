import { CommonModule } from '@angular/common';
import { Component, Input, signal, WritableSignal, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

type Status = 'completed' | 'pending' | 'exception';

interface Step {
  label: string;
  date?: string;
  status?: Status;
  externalStatus: string;
  icon: string;
}
@Component({
  selector: 'pds-process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss'],
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessStatusComponent {
  _steps: WritableSignal<Step[]> = signal<Step[]>([]);

  constructor(private cdr: ChangeDetectorRef) {}

  @Input()
  set steps(value: { currentStep: string; currentStepStatus: Status; steps: Step[] }) {
    this.updateStepStatuses(value.steps, value.currentStep, value.currentStepStatus);
  }

  private updateStepStatuses(steps: Step[], externalStatus: string, currentStepStatus: Status): void {
    let isBefore = true;

    const stepsFormatted = steps.map((step) => {
      if (isBefore) {
        step.status = 'completed';

        if (step.externalStatus === externalStatus) {
          isBefore = false;
          step.status = currentStepStatus;
        }
      } else {
        step.status = 'pending';
      }

      return step;
    });

    this._steps.set(stepsFormatted);
    this.cdr.markForCheck();
  }
}
