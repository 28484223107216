import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AutocompleteItemSource, ModalComponent } from '@peca/design-system';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, pipe, Subject, takeUntil, tap } from 'rxjs';
import { UserService } from '../../services/user.service';
import { SellerService } from '../../../sellers/services/seller.service';

@Component({
  selector: 'peca-users-branches-modal',
  templateUrl: './users-branches.component.html',
  styleUrls: ['./users-branches.component.scss'],
  standalone: false,
})
export class UsersBranchesComponent implements OnInit, OnDestroy {
  @Input()
  selecteds: any[] = [];

  @ViewChild('modal')
  modal!: ModalComponent;

  @Output()
  add = new EventEmitter<{ id: string; name: string }[]>();

  userService = inject(UserService);
  sellerService = inject(SellerService);
  form = new FormGroup({ seller: new FormControl<{ id: string } | null>(null) });
  branches$ = new BehaviorSubject<{ loading?: boolean; failure?: boolean; branches?: any[] }>({});
  sellers$ = new BehaviorSubject<AutocompleteItemSource>({});
  destroyRef$ = new Subject<void>();
  selectedBranches: { id: string; name: string }[] = [];

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        tap(() => this.branches$.next({ loading: true })),
        takeUntil(this.destroyRef$)
      )
      .subscribe((value) => {
        if (!value || !value.seller) {
          this.branches$.next({});
          return;
        }

        this.sellerService
          .fetchSellerBranches(value.seller.id)
          .pipe(takeUntil(this.destroyRef$))
          .subscribe({
            next: (branches) => this.branches$.next({ branches: branches.map((b) => ({ ...b, selected: this.selecteds.some(({ id }) => id === b.id) })) }),
            error: () => this.branches$.next({ failure: true }),
          });
      });
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  open() {
    this.modal.open();
  }

  close() {
    this.selectedBranches = [];
    this.form.patchValue({ seller: null });
    this.branches$.next({});
    this.sellers$.next({});
    this.modal.close();
  }

  onSellerAutocomplete(search: string) {
    if (search.length <= 1) return;

    this.sellers$.next({ loading: true });
    this.userService
      .suggestSellers(search)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: (data) => this.sellers$.next({ data }),
        error: () => this.sellers$.next({ failure: true }),
      });
  }

  onClickSubmit() {
    this.add.next(this.selectedBranches);
  }

  onSelectBranch() {
    if (!this.branches$.value.branches) return;

    const stream = this.branches$.value.branches;
    const unselecteds = stream.filter(({ selected }) => !selected);
    const previousSelectionFiltered = this.selecteds.filter((item) => !unselecteds.some((unselected) => unselected.id === item.id));
    const currentSelection = stream.filter((item) => item.selected && !previousSelectionFiltered.includes(item));

    this.selectedBranches = Array.from(new Map([...previousSelectionFiltered, ...currentSelection].map((obj) => [obj.id, obj])).values());
  }
}
