import { Pipe, PipeTransform } from '@angular/core';
import { Delivery } from '../models/delivery.model';

@Pipe({
  name: 'activationTime',
  standalone: false,
})
export class DeliveryActivationTimePipe implements PipeTransform {
  transform(delivery: Delivery, format: 'HH:mm:ss' | 'modifier' = 'HH:mm:ss') {
    const createdAt = new Date(delivery.createdAt);
    const today = new Date();
    const diff = (today.getTime() - createdAt.getTime()) / 60000;
    const activationTime = delivery.activationTime - diff;

    switch (format) {
      case 'HH:mm:ss':
        return this.format(activationTime);
      case 'modifier':
        if (activationTime > 5) return 'success';
        if (activationTime > 0) return 'warning';
        return 'error';
    }
  }

  private format(timeInMinutes: number) {
    const late = timeInMinutes < 0 ? '-' : '';
    const abs = Math.abs(timeInMinutes);
    const h = Math.floor(abs / 60);
    const m = Math.floor(abs % 60);
    const s = Math.floor((abs % 1) * 60);
    const fix = (slice: number) => ('0' + slice).slice(-2);

    return `${late}${fix(h)}:${fix(m)}:${fix(s)}`;
  }
}
