import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'pds-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: false,
})
export class ModalComponent {
  @Output()
  readonly dismiss: EventEmitter<void>;

  @Input()
  width: number;

  @Input()
  title: string;

  active: boolean;

  constructor() {
    this.width = 360;
    this.title = '';
    this.active = false;
    this.dismiss = new EventEmitter();
  }

  /**
   * @deprecated use open and close instead
   */
  toggle() {
    this.active = !this.active;
  }

  open() {
    this.active = true;
  }

  close() {
    this.active = false;
  }

  dismissAll() {
    this.active = false;
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleCloseClickEvent() {
    this.active = false;
    this.dismiss.next();
  }
}
