import { Routes } from '@angular/router';
import { TrackingListComponent } from './components/shipping-list/tracking-list.component';

export const routes: Routes = [
  {
    path: '',
    component: TrackingListComponent,
  },
];

export const TrackingtModuleLoad = () => import('./tracking.module').then(({ TrackingModule }) => TrackingModule);
