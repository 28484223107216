<pds-module-header title="Vendedores" ctaLabel="Novo vendedor" ctaPath="/vendedores/novo">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <div class="seller-list__content">
    <pds-panel>
      <peca-seller-list-search (filter)="handleFilterEvent($event)"></peca-seller-list-search>

      <hr />
      <ng-container *ngIf="sellers$ | async; let sellers">
        <ng-container *ngIf="sellers.data?.length">
          <pds-table>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Referencia Fraga</th>
                  <th>Cadastro</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let seller of sellers.data">
                  <tr>
                    <td>{{ seller.name }}</td>
                    <td>{{ seller.email }}</td>
                    <td>{{ seller.fragaReference }}</td>
                    <td>{{ seller.audit.createdAt | date : 'dd/MM/yyyy' }}</td>
                    <td pdsColActions>
                      <pds-switch [(ngModel)]="seller.active" (ngModelChange)="handleSellerStatusChange(seller)"></pds-switch>
                    </td>
                    <td pdsColActions>
                      <button pdsButton="icon" (click)="handleDetailsClickEvent(seller)">
                        <span class="icon">visibility</span>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </pds-table>
        </ng-container>

        <ng-container *ngIf="sellers.loading">
          <span>carregando...</span>
        </ng-container>

        <ng-container *ngIf="sellers.failure">
          <strong>Não foi possível listar os vendedores. Por favor, tente novamente mais tarde.</strong>
        </ng-container>

        <ng-container *ngIf="sellers.data && !sellers.data?.length">
          <span>Nenhum vendedor encontrado.</span>
        </ng-container>

        <pds-pagination [pagination]="pagination$" (paginate)="handlePaginateEvent($event)"></pds-pagination>
      </ng-container>
    </pds-panel>
  </div>
</pds-module-header>
