import { Component, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService, ToastService } from '@peca/commons';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { PermissionModuleForm } from './module-form';
import { PermissionContainerComponent } from '../../permission-container/permission-container.component';
import { PermissionModuleDetail } from '../../../models/module/module.model';
import { PlatformService } from '../../../services/platform.service';
import { PermissionModuleService } from '../../../services/module.service';

@Component({
  selector: 'peca-module-form',
  templateUrl: './module-form.component.html',
  styleUrls: ['./module-form.component.scss'],
  standalone: false,
})
export class ModuleFormComponent {
  form: PermissionModuleForm;
  moduleService: PermissionModuleService;
  platformService: PlatformService;
  route: ActivatedRoute;
  toast: ToastService;
  router: Router;
  module$: BehaviorSubject<AsyncState<PermissionModuleDetail>>;
  permissionContainer: PermissionContainerComponent;
  analytics: AnalyticsService;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.platformService = inject(PlatformService);
    this.moduleService = inject(PermissionModuleService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.permissionContainer = inject(PermissionContainerComponent);
    this.form = new PermissionModuleForm();
    this.module$ = new BehaviorSubject<AsyncState<PermissionModuleDetail>>({ loading: true });
  }

  get platformId() {
    const { platformId } = this.route.snapshot.params;
    return platformId as string;
  }

  get moduleId() {
    const { moduleId } = this.route.snapshot.params;
    return moduleId as string;
  }

  get isUpdateOperation() {
    return !!this.moduleId;
  }

  ngOnInit() {
    this.permissionContainer.breadcrumb = [{ label: 'Listagem de Plataformas', path: '/plataformas' }];
    this.permissionContainer.title = 'Módulos';

    this.permissionContainer.actionButtons = [
      {
        style: 'primary',
        path: `/plataformas/${this.platformId}`,
        text: 'Voltar',
      },
    ];

    if (this.isUpdateOperation) {
      (this.permissionContainer.actionButtons[1] = {
        style: 'default',
        path: `/plataformas/${this.platformId}/${this.moduleId}/nova`,
        text: 'Nova funcionalidade',
      }),
        this.analytics.view('module_form_update');
      this.fetchModule(this.platformId, this.moduleId);
      return;
    }

    this.analytics.view('module_form_create');
    this.fetchPlatform(this.platformId);
  }

  fetchPlatform(platformId: string) {
    this.platformService.findById(platformId).subscribe({
      next: (platform) => {
        this.permissionContainer.breadcrumb.push({ label: platform.name, path: `/plataformas/${platform.id}` }, { label: 'Novo Módulo' });
        this.form.group.enable();
        this.module$.next({ loading: false });
      },
      error: (e: unknown) => {
        this.module$.next({ failure: true });
      },
    });
  }

  fetchModule(platformId: string, moduleId: string) {
    this.moduleService.findById(platformId, moduleId).subscribe({
      next: (module: PermissionModuleDetail) => {
        this.analytics.callback('module_busca', { platformId, moduleId, success: true });
        this.form.group.disable();

        this.module$.next({ loading: false });
        this.permissionContainer.breadcrumb.push({ label: module.platform.name, path: `/plataformas/${module.platform.id}` }, { label: module.name });
        this.form.setValue(module);
      },
      error: (e: unknown) => {
        this.analytics.callback('module_busca', { platformId, moduleId, success: false });
        this.module$.next({ failure: true });
      },
    });
  }

  onClickEnable() {
    this.form.group.enable();
  }

  onClickCancel() {
    this.form.group.disable();
    this.router.navigate(['plataformas', this.platformId, this.moduleId]);
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;

    const payload = this.form.getValue();

    this.moduleService.save(this.platformId, payload).subscribe({
      next: () => {
        this.analytics.callback('module_salva', { payload, success: true });
        this.toast.success('Módulo salvo com sucesso.');
        this.router.navigate(['plataformas', this.platformId]);
      },
      error: (e) => {
        this.analytics.callback('module_salva', { payload, success: false });
        this.toast.failure('Falha ao salvar módulo.');
      },
    });
  }
}
