import { Pipe, PipeTransform } from '@angular/core';
import { ProductDistributor } from '../models/product-search-state.model';

@Pipe({
  name: 'sellers',
  standalone: false,
})
export class ProductSellersPipe implements PipeTransform {
  transform(distributors: ProductDistributor[]) {
    if (!distributors) return '-';

    return distributors
      .filter((distributor) => !!distributor && !!distributor.seller)
      .map(({ seller }) => seller)
      .join(', ');
  }
}
