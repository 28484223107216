import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject, distinctUntilChanged, takeUntil } from 'rxjs';

import { ProductService } from '../../services/product.service';
import { ProductSearchQuery } from '../../models/product-search-query.model';
import { ProductSearchState } from '../../models/product-search-state.model';
import { ProductStorefront } from '../../models/storefront/product-storefront.model';
import { Router } from '@angular/router';

@Component({
  selector: 'peca-product-card[query]',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  standalone: false,
})
export class ProductCardComponent implements OnInit, OnDestroy {
  @Input('query')
  query$!: Subject<ProductSearchQuery>;

  @Output('changed')
  readonly products$: BehaviorSubject<ProductSearchState<ProductStorefront>>;

  readonly destroy$: Subject<void>;

  constructor(private productService: ProductService, private router: Router) {
    this.destroy$ = new Subject();
    this.products$ = new BehaviorSubject({});
  }

  get loading() {
    return !!this.products$.value.loading;
  }

  get failure() {
    return !!this.products$.value.failure;
  }

  get products() {
    return this.products$.value.data;
  }

  ngOnInit() {
    this.query$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(this.onQueryChanges.bind(this));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onQueryChanges(query: ProductSearchQuery) {
    this.products$.next({ loading: true });
    this.productService
      .storefrontSearch(query)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((data) => this.products$.next({ data }));
  }

  handleItemClick(id: string) {
    this.router.navigate([`/produtos/${id}`]);
  }
}
