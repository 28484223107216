import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AnalyticsService } from '@peca/commons';
import { Async } from '@peca/backoffice/utils/async.util';
import { OrdersReturnService } from '../../../services/orders-return.service';
import { OrderReturnProduct } from '../../../models/orders-return-product.model';

@Component({
  selector: 'peca-orders-return-details-products',
  templateUrl: './orders-return-details-products.component.html',
  styleUrls: ['./orders-return-details-products.component.scss'],
  standalone: false,
})
export class OrderReturnDetailsProductsComponent implements OnInit, OnDestroy {
  destroyRef$: Subject<void>;
  route: ActivatedRoute;
  orderReturnService: OrdersReturnService;
  analytics: AnalyticsService;
  products: Async<OrderReturnProduct[]>;

  constructor() {
    this.destroyRef$ = new Subject<void>();
    this.route = inject(ActivatedRoute);
    this.orderReturnService = inject(OrdersReturnService);
    this.analytics = inject(AnalyticsService);
    this.products = new Async<OrderReturnProduct[]>();
  }

  get id() {
    return this.route.snapshot.parent?.params['id'] as string;
  }

  ngOnInit() {
    const id = this.id;
    this.fetchOrderReturnProducts(id);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  fetchOrderReturnProducts(id: string) {
    this.products.loading();
    this.orderReturnService.fetchOrderReturnProducts(id).subscribe({
      next: (response) => {
        this.products.loaded(response);
      },
      error: () => {
        this.products.failed();
      },
    });
  }

  onRetryEvent() {
    this.fetchOrderReturnProducts(this.id);
  }
}
