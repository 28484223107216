import { required } from './required.validator';
import { length } from './length.validator';
import { email } from './email.validator';
import { cnpj } from './cnpj.validator';
import { cpf } from './cpf.validator';
import { number } from './number.validator';
import { cep } from './cep.validator';
import { contact } from './contact.validator';
import { oneOf } from './one-of.validator';
import { date } from './date.validator';
import { contactType } from './contact-type.validator';
import { url } from './url.validator';

export const Validators = {
  required,
  length,
  email,
  cnpj,
  number,
  cep,
  contact,
  oneOf,
  cpf,
  date,
  contactType,
  url,
};
