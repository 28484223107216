import { BaseHttpClient } from '@peca/commons';

import { environment } from 'apps/backoffice/src/environments/environment';
import { map, Observable } from 'rxjs';
import { TrackingQuery } from '../models/tracking-query.model';
import { TrackingDetails } from '../models/tracking-details.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { Tracking } from '../models/tracking.model';

export class TrackingService extends BaseHttpClient {
  private readonly api;

  constructor() {
    super();
    this.api = `${environment.gateway.url}/backoffice/v1/trackings`;
  }

  fetchTracking(query: TrackingQuery) {
    const params = this.prepareQuery(query);
    const resource = `${this.api}`;
    return this.http.get<Pageable<Tracking>>(resource, { params });
  }

  fetchTrackingDetails(shippingId: string, orderId: string): Observable<TrackingDetails> {
    const resource = `${this.api}/${shippingId}/orders/${orderId}`;
    return this.http.get<TrackingDetails>(resource);
  }

  suggestBuyers(search: string) {
    const params = { search };
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/suggestions`;
    return this.http.get<any[]>(resource, { params }).pipe(map((items) => items.map(({ id, name }) => ({ id, label: name }))));
  }

  suggestCarriers(search: string) {
    return this.http
      .get<any[]>(`${environment.gateway.url}/backoffice/v1/carriers/suggestions`, { params: { search } })
      .pipe(map((items) => items.map(({ id, name }) => ({ id, label: name })).sort((x, y) => (x.label > y.label ? 1 : -1))));
  }

  suggestSalesChannels(search: string) {
    const params = { search };
    const resource = `${environment.gateway.url}/backoffice/v1/sales-channel/suggestions`;
    return this.http
      .get<any[]>(resource, { params })
      .pipe(map((items) => items.map(({ id, name }) => ({ id, label: name })).sort((x, y) => (x.label > y.label ? 1 : -1))));
  }
}
