<pds-modal #modal title="Alterar pedido de devolução" [width]="720" (dismiss)="close()">
  <pds-form *ngIf="order" [formGroup]="form">
    <pds-form-row grid="1-2">
      <pds-form-field label="Status do pedido de devolução">
        <select formControlName="status">
          @for (option of order.status | allowedStatus; track $index) {
          <option [value]="option">{{ option | status }}</option>
          }
        </select>
      </pds-form-field>
      <pds-form-field *ngIf="value.status === 'REJECTED'" label="Justificativa da recusa">
        <input type="text" formControlName="rejectionJustification" />
      </pds-form-field>
    </pds-form-row>
    <pds-form-row>
      <pds-form-field label="Número da nota fiscal de devolução (PA-101010)">
        <input type="text" formControlName="invoice" pattern="[0-9]+" />
      </pds-form-field>

      <pds-form-field label="Observações">
        <input type="text" formControlName="observation" />
      </pds-form-field>
    </pds-form-row>

    <pds-form *ngIf="value.status === 'APPROVED'">
      <pds-checkbox formControlName="paid" label="Confirmo que o estorno dos valores da devolução foi realizado ao comprador de acordo com sua preferência." />
    </pds-form>

    <pds-form-actions>
      <button type="button" pdsButton [disabled]="form.pristine || form.invalid" (click)="onClickSubmit()">Salvar</button>
    </pds-form-actions>
  </pds-form>
</pds-modal>
