import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalComponent } from '@peca/design-system';
import { OrderReturnRequest } from '../../models/orders-return-request.model';
import { OrderReturnDetails } from '../../models/orders-return-details.model';

@Component({
  selector: 'peca-orders-return-form-modal',
  templateUrl: './orders-return-form-modal.component.html',
  styleUrls: ['./orders-return-form-modal.component.scss'],
  standalone: false,
})
export class OrderReturnFormModalComponent {
  @Output()
  submit: EventEmitter<OrderReturnRequest>;
  @ViewChild('modal')
  modal!: ModalComponent;
  form: FormGroup;
  order: OrderReturnDetails | null;

  constructor() {
    this.submit = new EventEmitter<OrderReturnRequest>();
    this.form = new FormGroup({
      status: new FormControl(''),
      invoice: new FormControl(''),
      observation: new FormControl(''),
      paid: new FormControl(false),
      rejectionJustification: new FormControl(''),
    });
    this.order = null;
  }

  get controls() {
    return {
      paid: this.form.get('paid') as FormControl,
      status: this.form.get('status') as FormControl,
      invoice: this.form.get('invoice') as FormControl,
      observation: this.form.get('observation') as FormControl,
      rejectionJustification: this.form.get('rejectionJustification') as FormControl,
    };
  }

  get value() {
    return {
      paid: this.controls.paid.getRawValue(),
      status: this.controls.status.getRawValue(),
      invoice: this.controls.invoice.getRawValue(),
      observation: this.controls.observation.getRawValue(),
      rejectionJustification: this.controls.rejectionJustification.getRawValue(),
    } as OrderReturnRequest;
  }

  open(order: OrderReturnDetails) {
    this.order = order;
    this.controls.status.setValue(order.status);
    this.controls.paid.setValue(order.payment === 'PAID');
    this.controls.observation.setValue(order.observation);
    this.controls.invoice.setValue(order.invoice);

    if (!this.ableToChangePaymentStatus(order)) {
      this.controls.paid.disable();
      this.form.updateValueAndValidity();
    }

    this.modal.open();
  }

  close() {
    this.order = null;
    this.form.reset();
    this.modal.close();
  }

  onClickSubmit() {
    if (this.form.invalid) return;
    this.submit.next(this.value);
  }

  ableToChangePaymentStatus(order: OrderReturnDetails) {
    if (
      order.payment === 'PAID' ||
      order.paymentMethod.referenceName === 'third-party-transfer' ||
      (order.paymentMethod.referenceName.startsWith('bank-slip') && !order.manualRefundAmount)
    ) {
      return false;
    }

    return true;
  }
}
