import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusColor',
  standalone: false,
})
export class OrdersReturnStatusColorPipe implements PipeTransform {
  transform(value: string) {
    switch (value) {
      case 'PROCESSING':
      case 'PENDING_COLLECTION':
      case 'IN_TRANSIT':
      case 'UNDER_ANALYSIS':
      case 'PENDING':
        return 'default';
      case 'APPROVED':
      case 'PAID':
        return 'secondary';
      case 'REJECTED':
      case 'NOT_PAID':
      case 'CANCELED':
        return 'terciary';
      default:
        return 'default';
    }
  }
}
