import { Component, ViewChild, inject } from '@angular/core';
import { ModalComponent } from '@peca/design-system';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService, Validators, numeric } from '@peca/commons';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { SellerService } from '../../services/seller.service';
import { Branch } from '../../models/branch.model';

@Component({
  selector: 'peca-add-product-manully',
  templateUrl: './add-product-manully.component.html',
  styleUrls: ['./add-product-manully.component.scss'],
  standalone: false,
})
export class AddProductManuallyComponent {
  @ViewChild('modal')
  modal!: ModalComponent;
  branch!: Branch;
  sellerService: SellerService;
  form: FormGroup;
  confirmation: ConfirmableActionService;
  toast: ToastService;

  constructor() {
    this.toast = inject(ToastService);
    this.confirmation = inject(ConfirmableActionService);
    this.sellerService = inject(SellerService);
    this.form = new FormGroup({
      externalReference: new FormControl('', [Validators.required]),
      stock: new FormControl('', [Validators.required, Validators.number.min(0)]),
      price: new FormControl('', [Validators.required, Validators.number.min(1, true)]),
    });
  }

  get controls() {
    return {
      externalReference: this.form.get('externalReference') as FormControl,
      stock: this.form.get('stock') as FormControl,
      price: this.form.get('price') as FormControl,
    };
  }

  get value() {
    return {
      externalReference: this.controls.externalReference.value,
      stock: numeric(this.controls.stock.value),
      price: numeric(this.controls.price.value),
    };
  }

  open(branch: Branch) {
    this.branch = branch;
    this.clear();
    this.modal.open();
  }

  close() {
    this.modal.close();
    this.clear();
  }

  clear() {
    this.form.patchValue({
      externalReference: '',
      stock: '',
      price: '',
    });
  }

  onClickSubmit() {
    if (this.form.invalid) return;
    const message = `Você está incluindo um produto manualmente. Sua solicitação será enviada para processamento e logo deverá refletir em nossos catálogos.`;

    this.confirmation.confirm(message, null, true).subscribe((confimation) => {
      if (!confimation.confirmed) return;
      this.sellerService.addProductManually(this.branch.id, this.value).subscribe({
        next: () => {
          this.toast.success('Produto enviado para inclusão manual com sucesso.');
          this.close();
        },
        error: () => this.toast.failure('Não foi possível adicionar o produto.'),
      });
    });
  }
}
