import { Route } from '@angular/router';
import { ProfileListComponent } from './components/platforms/profile-list/profile-list.component';
import { ProfileFormComponent } from './components/platforms/platform-form/profile-form.component';

export const routes: Route[] = [
  {
    path: '',
    component: ProfileListComponent,
  },
  {
    path: 'novo',
    component: ProfileFormComponent,
  },
  {
    path: ':profileId',
    component: ProfileFormComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

export const ProfileModuleLoad = () => import('./profile.module').then(({ ProfileModule }) => ProfileModule);
