import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'peca-retry',
  templateUrl: './retry.component.html',
  styleUrls: ['./retry.component.scss'],
  imports: [CommonModule],
})
export class RetryComponent {
  @Input()
  context: string;

  @Output()
  retry: EventEmitter<void>;

  constructor() {
    this.context = '';
    this.retry = new EventEmitter<void>();
  }

  onClickRetry() {
    this.retry.emit();
  }
}
