import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { ControlValueAccessor, ReactiveFormsModule } from '@angular/forms';
import { forwardRefProvider } from '@peca/backoffice/providers/forward-ref.provider';
import { DesignSystemModule } from '@peca/design-system';
import { Responsible } from './responsible.model';
import { ResponsibleForm } from './responsible-form';

@Component({
  selector: 'peca-responsible-form',
  templateUrl: './responsible-form.component.html',
  styleUrls: ['./responsible-form.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, DesignSystemModule],
  providers: [forwardRefProvider(ResponsibleFormComponent)],
})
export class ResponsibleFormComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  requireds: string[];
  changed!: (value: Responsible | null) => void;
  touched!: () => void;
  form: ResponsibleForm;

  constructor() {
    this.requireds = ['EMAIL', 'WHATSAPP', 'AT_LEAST_ONE_PHONE'];
    this.form = new ResponsibleForm();
  }

  ngAfterViewInit() {
    this.form.group.valueChanges.subscribe(this.onValueChanges.bind(this));
    this.form.setContactValidators(this.requireds);
  }

  onValueChanges() {
    if (!this.changed) return;

    if (this.form.group.invalid) {
      this.changed(null);
      return;
    }

    this.changed(this.form.value);
  }

  writeValue(value: Responsible | null) {
    this.form.value = value;
  }

  registerOnChange(fn: (value: Responsible | null) => void) {
    this.changed = fn;
  }

  registerOnTouched(fn: () => void) {
    this.touched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.form.group.disable();
    else this.form.group.enable();
  }
}
