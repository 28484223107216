import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Pagination, PaginationInitialState } from '@peca/design-system';
import { PersistQueryService, ToastService } from '@peca/commons';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

import { B2BService } from '../../services/b2b.service';
import { AsyncState } from '../../../../core/models/async-state.model';
import { Pageable } from '../../../../core/models/pageable.model';
import { B2B } from '../../models/b2b.model';
import { B2BQuery } from '../../models/b2b-query.model';

@Component({
  selector: 'peca-b2b-list',
  templateUrl: './b2b-list.component.html',
  styleUrls: ['./b2b-list.component.scss'],
  standalone: false,
})
export class B2bListComponent implements OnInit {
  router = inject(Router);
  service = inject(B2BService);
  toast = inject(ToastService);
  confirmable = inject(ConfirmableActionService);
  persistQueryService = inject(PersistQueryService);
  route = inject(ActivatedRoute);
  pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
  searchForm = new FormGroup({ search: new FormControl(''), active: new FormControl('') });
  b2bs$ = new BehaviorSubject<AsyncState<Pageable<B2B>>>({ loading: true });
  lastQuery: B2BQuery = { sort: 'id,asc', ...PaginationInitialState };
  breadcrumb = [{ label: 'Listagem de B2B', path: '/b2b' }];
  private readonly searchFormInitialValue = {
    search: '',
    active: '',
  };

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search, active, sort } = JSON.parse(atob(params['q']));
      this.searchForm.patchValue({
        search: search || '',
        active: active || '',
      });
    });

    const persitedQuery = this.persistQueryService.read<B2BQuery>('peca-b2b-list');
    const query = persitedQuery || this.lastQuery;
    this.fetch(query);
    this.searchForm.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
  }

  fetch(query: B2BQuery) {
    this.lastQuery = query;
    this.persistQueryService.rewriteQueryString('peca-b2b-list', this.lastQuery);
    this.service.fetch(this.lastQuery).subscribe({
      next: (data) => {
        this.paginate(data);
        this.b2bs$.next({ data });
      },
      error: () => {
        this.b2bs$.next({ failure: true });
      },
    });
  }

  updateStatus(b2bId: number, active: boolean) {
    this.service.updateStatus(b2bId, active).subscribe({
      next: () => this.toast.success('Status alterado com sucesso'),
      error: () => this.toast.failure('Falha ao alterar o status.'),
    });
  }

  paginate(page: Pageable<unknown>) {
    const pagination = {
      page: page.currentPage,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    };
    this.pagination$.next({ ...pagination, page: pagination.page + 1 });
    this.persistQueryService.rewriteQueryString('peca-b2b-list', { ...this.lastQuery, ...pagination });
  }

  onSubmitSearch() {
    if (this.searchForm.invalid) return;

    const { search, active } = this.searchForm.value;
    const query = { ...this.lastQuery, search, active, page: 0 };

    this.fetch(query as B2BQuery);
  }

  clearFilters() {
    this.searchForm.reset(this.searchFormInitialValue);
    this.onSubmitSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.searchForm.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.searchForm.markAsPristine();
    return this.searchForm.markAsDirty();
  }

  onChangeB2BStatus(b2b: B2B) {
    const message = 'Você está alterando o status de um parceiro B2B.';
    this.confirmable.confirm(message).subscribe(({ confirmed }) => {
      if (!confirmed) {
        b2b.active = !b2b.active;
        return;
      }

      this.updateStatus(b2b.id, b2b.active);
    });
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetch(query);
  }

  onClickDetails(b2b: B2B) {
    this.router.navigate(['/b2b', b2b.id]);
  }
}
