<pds-form-section title="Listagem de módulos">
  <ng-container *ngIf="modules$ | async; let modules">
    <pds-form [formGroup]="form">
      <pds-form-row grid="1">
        <pds-form-field label="Busca">
          <input type="text" formControlName="search" placeholder="Nome" />
        </pds-form-field>
      </pds-form-row>

      <pds-form-actions>
        <button pdsButton="primary" (click)="clearFilters()" type="button" [disabled]="form.pristine">Limpar filtros</button>
        <button pdsButton type="submit" (click)="onClickSearch()">Buscar</button>
      </pds-form-actions>
    </pds-form>

    <hr />

    <ng-container *ngIf="modules.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="modules.failure">
      <strong>Não foi possível obter os módulos.</strong>
    </ng-container>

    <ng-container *ngIf="modules.data && !modules.data?.length">
      <strong>Nenhum módulo cadastrado.</strong>
    </ng-container>

    <ng-container *ngIf="modules.data && modules.data?.length">
      <pds-table>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Descrição</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let module of modules.data">
              <td>{{ module.name }}</td>
              <td>{{ module.description }}</td>
              <td pdsColActions>
                <button pdsButton="icon" (click)="onClickDetails(module.id)">
                  <span class="icon">visibility</span>
                </button>
                <button pdsButton="icon" title="Remover" (click)="onClickRemove(module)">
                  <span class="icon">delete</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </pds-table>
    </ng-container>
  </ng-container>
</pds-form-section>
