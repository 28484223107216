import { Pipe, PipeTransform } from '@angular/core';
import { TypeOFIntegration } from '../models/seller-settings.model';

@Pipe({
  name: 'typeIntegration',
  standalone: false,
})
export class TypeIntegrationPipe implements PipeTransform {
  transform(value: TypeOFIntegration): string {
    switch (value) {
      case 'FULL':
        return 'Base Total';
      case 'INCREMENTAL':
        return 'Incremental';
      default:
        return value;
    }
  }
}
