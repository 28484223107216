import { Pipe, PipeTransform } from '@angular/core';
import { OrderInvoiceStatus } from '../models/order-invoice-status.model';

@Pipe({
  name: 'orderInvoiceStatus',
  standalone: false,
})
export class OrderInvoiceStatusPipe implements PipeTransform {
  transform(value: OrderInvoiceStatus) {
    switch (value) {
      case 'PENDING':
        return 'Solicitado';
      case 'PROCESSING':
        return 'Processando';
      case 'ISSUED':
        return 'Emitido';
      case 'FAILED':
        return 'Falha';
    }
  }
}
