<pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

<pds-form [formGroup]="form">
  <pds-form-section title="Detalhes">
    <pds-form-row grid="1-1-1">
      <pds-form-field label="Nome">
        <input type="text" formControlName="name" />
      </pds-form-field>
      <pds-form-field label="Referência externa">
        <input type="text" formControlName="externalReference" />
      </pds-form-field>
    </pds-form-row>
    <pds-form-row grid="1-2">
      <pds-form-field label="Nome comercial">
        <input type="text" formControlName="publicName" />
      </pds-form-field>

      <pds-form-field label="Descrição">
        <input type="text" formControlName="description" />
      </pds-form-field>
    </pds-form-row>

    <pds-form-row grid="1">
      <pds-form-field label="Observações">
        <input type="text" formControlName="observation" />
      </pds-form-field>
    </pds-form-row>
  </pds-form-section>

  <pds-form-section title="Precificação">
    <pds-form-row grid="1-1-1-1">
      <pds-form-field label="Custo fixo">
        <input type="text" formControlName="fixedPrice" pdsCurrencyMask />
      </pds-form-field>
      <pds-form-field label="Custo de ativação">
        <input type="text" formControlName="callPrice" pdsCurrencyMask />
      </pds-form-field>
      <pds-form-field label="Custo por KM">
        <input type="text" formControlName="kmPrice" pdsCurrencyMask />
      </pds-form-field>
      <pds-form-field label="Custo por parada">
        <input type="text" formControlName="stopPrice" pdsCurrencyMask />
      </pds-form-field>
    </pds-form-row>
  </pds-form-section>

  <pds-form-section title="Tempo de entrega">
    <pds-form-row grid="1-1-1-1">
      <pds-form-field label="Tempo fixo (minutos)">
        <input type="number" formControlName="fixedTime" />
      </pds-form-field>
      <pds-form-field label="Tempo por KM (minutos)">
        <input type="number" formControlName="kmTime" />
      </pds-form-field>
    </pds-form-row>
  </pds-form-section>

  <pds-form-section title="Dimensões">
    <pds-form-row grid="1-1-1-1">
      <pds-form-field label="Peso mínimo (kg)">
        <input type="number" formControlName="minWeightKg" />
      </pds-form-field>
      <pds-form-field label="Peso máximo (kg)">
        <input type="number" formControlName="maxWeightKg" />
      </pds-form-field>
    </pds-form-row>

    <pds-form-row grid="1-1-1-1">
      <pds-form-field label="Altura máxima (cm)">
        <input type="number" formControlName="maxHeightCm" />
      </pds-form-field>
      <pds-form-field label="Largura máxima (cm)">
        <input type="number" formControlName="maxWidthCm" />
      </pds-form-field>
      <pds-form-field label="Comprimento máximo (cm)">
        <input type="number" formControlName="maxLengthCm" />
      </pds-form-field>
    </pds-form-row>
  </pds-form-section>

  <pds-form-section title="Raio de atuação">
    <pds-form-row grid="1-1-1-1">
      <pds-form-field label="Distância mínima (km)">
        <input type="number" formControlName="minDistanceKm" />
      </pds-form-field>
      <pds-form-field label="Distância máxima (km)">
        <input type="number" formControlName="maxDistanceKm" />
      </pds-form-field>
    </pds-form-row>
  </pds-form-section>

  <hr />

  <pds-form-actions>
    <ng-container *ngIf="form.disabled">
      <button (click)="handleChangeStatusEvent()" pdsButton="primary">
        <span>{{ form.value.active ? 'Desativar' : 'Ativar' }}</span>
      </button>
      <button (click)="handleEnableEvent()" pdsButton>Editar</button>
    </ng-container>
    <ng-container *ngIf="form.enabled">
      <ng-container *ngIf="form.value.id">
        <button (click)="handleCancelEvent()" pdsButton="primary">Cancelar</button>
      </ng-container>
      <button (click)="handleSubmitEvent()" [disabled]="disabled" pdsButton>Salvar</button>
    </ng-container>
  </pds-form-actions>
</pds-form>
