import { Component, EventEmitter, inject, Output } from '@angular/core';
import { SearchForm } from './search-form';
import { BehaviorSubject } from 'rxjs';
import { AutocompleteItemSource } from '@peca/design-system';
import { ActivatedRoute } from '@angular/router';
import { PersistQueryService } from '@peca/commons';
import { TrackingService } from '../../../services/tracking.service';
import { TrackingQuery } from '../../../models/tracking-query.model';
import { StatusValues } from '../../../values/status.value';
import { shippingTimeValues } from '../../../values/shipping-time.value';
import { ShippingStatusPipe } from '../../../pipes/shipping-status.pipe';

@Component({
  selector: 'peca-tracking-list-search',
  standalone: false,
  templateUrl: './tracking-list-search.component.html',
  styleUrl: './tracking-list-search.component.scss',
})
export class TrackingListSearchComponent {
  @Output()
  search: EventEmitter<TrackingQuery>;
  form: SearchForm;
  buyers$: BehaviorSubject<AutocompleteItemSource>;
  service: TrackingService;
  statusSource: any[];
  salesChannels: any[];
  route: ActivatedRoute;
  persistQueryService: PersistQueryService;
  carriers: any[];
  shippingtimes: any[];

  private readonly searchFormInitialValue = {
    orderId: '',
    buyer: null,
    date: { startDate: '', endDate: '' },
    status: [],
    trackingCode: '',
    salesChannelIds: [],
    carrierIds: [],
    deliveryTime: [],
  };

  constructor() {
    this.service = inject(TrackingService);
    this.route = inject(ActivatedRoute);
    this.persistQueryService = inject(PersistQueryService);
    this.buyers$ = new BehaviorSubject<AutocompleteItemSource>({});
    this.search = new EventEmitter<TrackingQuery>();
    this.form = new SearchForm();
    this.statusSource = this.getStatusSource();
    this.shippingtimes = this.getShippingTimes();
    this.salesChannels = [];
    this.carriers = [];
  }

  ngOnInit() {
    this.service.suggestSalesChannels('').subscribe({ next: (source) => (this.salesChannels = source) });
    this.form.group.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.service.suggestCarriers('').subscribe({ next: (source) => (this.carriers = source) });

    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { orderId, buyer, startDate, endDate, status, trackingCode, salesChannelIds, carrierIds, deliveryTime } = JSON.parse(atob(params['q']));
      this.form.group.patchValue({
        buyer: buyer || this.searchFormInitialValue.buyer,
        orderId: orderId || this.searchFormInitialValue.orderId,
        date: {
          startDate: startDate || this.searchFormInitialValue.date.startDate,
          endDate: endDate || this.searchFormInitialValue.date.endDate,
        },
        status: status || this.searchFormInitialValue.status,
        trackingCode: trackingCode || this.searchFormInitialValue.trackingCode,
        salesChannelIds: salesChannelIds || this.searchFormInitialValue.salesChannelIds,
        carrierIds: carrierIds || this.searchFormInitialValue.carrierIds,
        deliveryTime: deliveryTime || this.searchFormInitialValue.deliveryTime,
      });
    });
  }

  onSubmit() {
    if (this.form.group.invalid) return;

    const { date, buyer, ...value } = this.form.values;
    this.search.next({
      ...value,
      buyer: buyer || null,
      buyerId: buyer?.id ?? null,
      startDate: date.startDate,
      endDate: date.endDate,
    });
  }

  clearFilters() {
    this.form.group.reset(this.searchFormInitialValue);
    this.onSubmit();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.values;

    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.form.group.markAsPristine();
    this.form.group.markAsDirty();
  }

  getStatusSource() {
    const status = [...StatusValues];
    const pipe = new ShippingStatusPipe();
    return status.map((i) => ({ id: i, label: pipe.transform(i) as string }));
  }

  getShippingTimes() {
    const shippingsTimes = [...shippingTimeValues];
    return shippingsTimes.map((shippingTime) => ({ id: shippingTime.id, label: shippingTime.label }));
  }

  onAutocompleteBuyers(search: string) {
    if (search.length < 3) return;

    this.buyers$.next({ loading: true });
    this.service.suggestBuyers(search).subscribe({
      next: (data) => this.buyers$.next({ data }),
      error: () => this.buyers$.next({ failure: true }),
    });
  }
}
