import { BehaviorSubject } from 'rxjs';
import { ToastService, Validators } from '@peca/commons';
import { ModalComponent, Tab } from '@peca/design-system';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';

import { OrderService } from '../../services/order.service';
import { OrderProduct } from '../../models/order-product.modal';
import { OrderSimilarProduct } from '../../models/order-similar-product.model';
import { OrderProductRequest } from '../../models/order-product-request.model';
import { OrderReplaceProductForm } from '../../forms/order-replace-product.form';
import { FormControl, FormGroup } from '@angular/forms';
import { OrderStatusType } from '../../models/order-status';

@Component({
  selector: 'peca-order-replace-seller',
  templateUrl: './order-replace-seller.component.html',
  styleUrls: ['./order-replace-seller.component.scss'],
  standalone: false,
})
export class OrderReplaceSellerComponent {
  @ViewChild('modal')
  modal!: ModalComponent;

  @Output()
  adjust: EventEmitter<void>;

  orderId!: string;
  currentProduct: OrderProduct | undefined;
  orderStatus: OrderStatusType | undefined;
  substituteProduct: OrderSimilarProduct | undefined;
  form: OrderReplaceProductForm;
  orderService: OrderService;
  products$: BehaviorSubject<AsyncState<OrderSimilarProduct[]>>;
  toast: ToastService;
  onlySameSeller: boolean;
  formCatalogIdSearch: FormGroup;
  tabs!: Tab[];

  constructor() {
    this.products$ = new BehaviorSubject<AsyncState<OrderSimilarProduct[]>>({ loading: true });
    this.form = new OrderReplaceProductForm();
    this.orderService = inject(OrderService);
    this.toast = inject(ToastService);
    this.adjust = new EventEmitter<void>();
    this.onlySameSeller = false;
    this.formCatalogIdSearch = new FormGroup({
      catalogId: new FormControl('', Validators.required),
    });
    this.initializeTabs();
  }

  initializeTabs() {
    const whitelistSimilarAndItem = ['WAITING_FOR_SELLER', 'REQUIRES_MANUAL_ADJUSTMENT', 'IN_SEPARATION', 'AWAITING_COLLECTION'];

    this.tabs = [
      {
        label: 'Vendedor',
        target: 'vendedor',
        active: true,
        roles: [],
      },
    ];

    if (this.orderStatus && whitelistSimilarAndItem.includes(this.orderStatus)) {
      const additionalTabs = [
        {
          label: 'Similar',
          target: 'similar',
          active: false,
          roles: [['vendas-troca-item-similar']],
        },
        {
          label: 'Item',
          target: 'item',
          active: false,
          roles: [['vendas-troca-item']],
        },
      ];
      this.tabs.push(...additionalTabs);
    }
  }

  handleTabSelectEvent(tab: Tab) {
    const active = this.tabs.find((tab) => tab.active);
    if (active) active.active = false;
    tab.active = true;
    if (!this.currentProduct) return this.products$.next({ failure: true });
    this.substituteProduct = undefined;
    this.form.group.patchValue({
      agreement: true,
      stock: 0,
      quantity: 0,
      unitPrice: 0,
      totalPrice: 0,
      currentTotalPrice: 0,
      reason: '',
    });
    this.formCatalogIdSearch.setValue({
      catalogId: '',
    });

    this.products$.next({ data: [] });
    if (tab.target === 'similar') {
      this.fetchSimilarProductsByBranch(this.orderId, this.currentProduct?.id);
    } else if (tab.target === 'vendedor') {
      this.fetchSelersProductsByBranch(this.orderId, this.currentProduct?.id);
    }
  }

  get formCatalogIdControls() {
    return {
      catalogId: this.formCatalogIdSearch.get('catalogId'),
    };
  }

  open(orderId: string, product: OrderProduct, orderStatus: OrderStatusType) {
    this.orderId = orderId;
    this.currentProduct = product;
    this.orderStatus = orderStatus;
    this.modal.open();
    this.initializeTabs();
    this.fetchSelersProductsByBranch(orderId, product.id);
  }

  close() {
    this.modal.close();
    this.products$.next({});
    this.substituteProduct = undefined;
    this.form.group.patchValue({
      agreement: true,
      stock: 0,
      quantity: 0,
      unitPrice: 0,
      totalPrice: 0,
      currentTotalPrice: 0,
      reason: '',
    });
    this.formCatalogIdSearch.setValue({
      catalogId: '',
    });
    this.form.controls.quantity.clearValidators();
  }

  searchItem() {
    this.products$.next({ loading: true });

    const catalogId = this.formCatalogIdControls.catalogId?.value;

    if (!this.currentProduct) return this.products$.next({ data: [], failure: true });

    this.orderService.fetchProductsByCatalogId(this.orderId, this.currentProduct?.id, catalogId).subscribe({
      next: (data: OrderSimilarProduct[]) => this.products$.next({ data }),
      error: () => this.products$.next({ data: [], loading: false, failure: true }),
    });
  }

  fetchSelersProductsByBranch(orderId: string, productId: string) {
    this.products$.next({ loading: true });
    this.orderService.fetchSelersProductsByBranch(orderId, productId).subscribe({
      next: (data: OrderSimilarProduct[]) => this.products$.next({ data }),
      error: () => this.products$.next({ failure: true }),
    });
  }

  fetchSimilarProductsByBranch(orderId: string, productId: string) {
    this.products$.next({ loading: true });
    this.orderService.fetchSimilarProductsByBranch(orderId, productId).subscribe({
      next: (data: OrderSimilarProduct[]) => {
        this.products$.next({ data });
      },
      error: () => this.products$.next({ failure: true }),
    });
  }

  onClickSelectProduct(product: OrderSimilarProduct) {
    if (!this.currentProduct) return;

    this.substituteProduct = product;
    this.form.setValue(this.currentProduct, product);
  }

  onClickRemoveSubstituteProduct() {
    this.form.group.patchValue({
      agreement: true,
      stock: 0,
      quantity: 0,
      unitPrice: 0,
      totalPrice: 0,
      currentTotalPrice: 0,
      reason: '',
    });
    this.substituteProduct = undefined;
  }

  onChangeOnlySameSeller(onlySameSeller: boolean) {
    this.onlySameSeller = onlySameSeller;
  }

  onClickSubmit() {
    const active = this.tabs.find((tab) => tab.active);
    if (!this.substituteProduct || !this.currentProduct || this.form.group.invalid || !active) return;

    const product: OrderProductRequest = {
      branchId: this.substituteProduct.branch.id,
      brandId: this.substituteProduct.brand.id,
      partNumber: this.substituteProduct.partNumber,
      productId: this.currentProduct.id,
      orderId: this.orderId,
      quantity: this.form.value.quantity,
      reason: this.form.value.reason,
    };

    if (active.target === 'vendedor') {
      this.orderService.updateProductBranch(product).subscribe({
        next: () => this.onSuccessSave('Vendedor alterado com sucesso.'),
        error: () => this.toast.failure('Não foi possível alterar o vendedor do pedido.'),
      });
      return;
    }

    this.orderService.updateProduct(product).subscribe({
      next: () => this.onSuccessSave('Produto alterado com sucesso.'),
      error: () => this.toast.failure('Não foi possível alterar o produto do pedido.'),
    });
  }

  onSuccessSave(msgSuccess: string) {
    this.toast.success(msgSuccess);
    this.adjust.emit();
    this.close();
  }
}
