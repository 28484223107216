<ng-container *ngIf="products">
  @if (products.loading) {
  <strong>Carregando...</strong>
  } @else if (products.failure) {
  <strong>Falha ao carregar os produtos.</strong>
  } @else if (products.data && !products.data.length) {
  <strong>Nenhum produto encontrado.</strong>
  } @else if (products.data && products.data.length) {
  <p class="product-substitute-label">A linhas na cor <span></span> destacam vendedores que já estão no pedido. Priorize-os para otimizar o frete.</p>
  <pds-form-row>
    <pds-checkbox label="Exibir somente produtos do mesmo vendedor." [(ngModel)]="onlySameSeller" />
  </pds-form-row>
  <pds-table [height]="256">
    <table>
      <thead>
        <tr>
          <th rowspan="2" style="width: 24%">Partnumber</th>
          <th rowspan="2">Marca</th>
          <th colspan="3">Vendedor</th>
          <th rowspan="2">QMV</th>
          <th rowspan="2">Estoque</th>
          <th rowspan="2">Preço</th>
          <th rowspan="2"></th>
        </tr>
        <tr>
          <th>Nome</th>
          <th>Filial</th>
          <th>Und. de negócio</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of products.data" [class.--already-on-order]="product.alreadyOnOrder" [hidden]="onlySameSeller && product.branch.id !== currentProduct?.branch?.id">
          <td>
            <div class="product-substitute-description">
              <strong>{{ product.partNumber }}</strong>
              <small>{{ product.description }}</small>
            </div>
          </td>
          <td>{{ product.brand.name }}</td>
          <td>{{ product.branch.commercialName }}</td>
          <td>{{ product.branch.externalReference }}</td>
          <td>{{ product.branch.businessUnit }}</td>
          <td>{{ product.qmv }}</td>
          <td>{{ product.stock }}</td>
          <td>{{ product.salePrice | currency : 'BRL' }}</td>
          <td pdsColActions>
            <button pdsButton="icon" title="Selecionar substituto" (click)="handleClickSelectProducts(product)">
              <span class="icon">sync</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </pds-table>
  }
</ng-container>
