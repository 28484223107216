<pds-modal #modal title="Observações" [width]="1024" (dismiss)="onDismiss()">
  <pds-form [formGroup]="form">
    <pds-form-row>
      <pds-form-field label="Observações do pedido">
        <input type="text" formControlName="publicObservations" />
      </pds-form-field>
    </pds-form-row>
    <hr />
    <pds-form-row>
      <pds-alert *ngIf="order?.processedDate" title="Atenção" type="warning">
        <p>O pedido já foi processado e não é possível alterar as observações fiscais.</p>
      </pds-alert>
      <pds-form-field label="Observações Fiscais">
        <input type="text" formControlName="invoiceObservations" />
      </pds-form-field>
    </pds-form-row>
    <pds-form-actions>
      <button pdsButton type="submit" [disabled]="!form.valid" (click)="onClickSubmit()">salvar</button>
    </pds-form-actions>
  </pds-form>
</pds-modal>
