import { Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TimerService } from '../services/timer.service';

@Directive({
  selector: '[pecaTimer]',
})
export class TimerDirective {
  @Input() timerId!: string;
  @Input() initialTime!: string;

  subscription!: Subscription;
  public render2: Renderer2;
  public elRef: ElementRef;
  private timerService: TimerService;

  constructor() {
    this.timerService = inject(TimerService);
    this.render2 = inject(Renderer2);
    this.elRef = inject(ElementRef);
  }

  ngOnInit() {
    this.render2.addClass(this.elRef.nativeElement, 'bkoTimer');
    this.initializeTimer();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  initializeTimer() {
    this.timerHandler(this.initialTime);
    const ineterval = this.timerService.getTimer();
    this.subscription = ineterval.subscribe({
      next: () => this.timerHandler(this.initialTime),
      error: this.displayError.bind(this),
    });
  }

  displayError(error: any) {
    this.elRef.nativeElement.innerHTML = 'Erro ao gerar timer';
  }

  timerHandler(initialTime: string) {
    const initialTimeDate = new Date(initialTime);

    if (isNaN(initialTimeDate.getTime())) {
      this.displayError('Data inválida');
      return;
    }

    const diff = this.calculateDiff(initialTimeDate);
    let formattedTime = this.formatSecondsToDate(diff);

    if (initialTimeDate.getTime() < new Date().getTime() || diff === 0) {
      formattedTime = `- ${formattedTime}`;
      this.setClass(this.elRef.nativeElement, 'late');
    } else {
      this.setClass(this.elRef.nativeElement, 'onTime');
    }
    if (this.elRef.nativeElement.innerHTML === formattedTime) return;
    this.elRef.nativeElement.innerHTML = formattedTime;
  }

  formatSecondsToDate(diff: number): string {
    const differenceHours = Math.floor(diff / (1000 * 60 * 60));
    const differenceMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${this.padWithZero(differenceHours)}:${this.padWithZero(differenceMinutes)}`;
  }

  padWithZero(value: number): string {
    return value.toString().padStart(2, '0');
  }

  calculateDiff(initialTime: Date): number {
    const initialTimeSeconds = initialTime.getTime();
    const currentDateSeconds = new Date().getTime();

    if (initialTimeSeconds > currentDateSeconds) {
      return initialTimeSeconds - currentDateSeconds;
    }

    return currentDateSeconds - initialTimeSeconds;
  }

  setClass(elRef: ElementRef, className: 'onTime' | 'late') {
    this.render2.removeClass(elRef, 'onTime');
    this.render2.removeClass(elRef, 'late');
    this.render2.addClass(elRef, className);
  }
}
