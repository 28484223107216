import { Component, Input } from '@angular/core';

@Component({
  selector: 'pds-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: false,
})
export class AlertComponent {
  @Input()
  title: string;

  @Input()
  type: 'warning' | 'error' | 'success' | 'info' | 'default';

  @Input()
  icon: string;

  constructor() {
    this.title = 'Atenção';
    this.type = 'default';
    this.icon = 'info';
  }
}
