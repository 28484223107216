import { Component, OnInit, inject } from '@angular/core';
import { Tab, BreadcrumbItem } from '@peca/design-system';
import { ToastService } from '@peca/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { ConfirmableActionService } from '../../../../core/services/confirmable-action.service';
import { Carrier } from '../../models/carrier.model';
import { CarrierService } from '../../services/carrier.service';
import { CarrierForm } from '../carrier-form/carrier-form';
import { AsyncState } from '../../../../../app/core/models/async-state.model';

@Component({
  selector: 'peca-carrier-update',
  templateUrl: './carrier-update.component.html',
  styleUrls: ['./carrier-update.component.scss'],
  standalone: false,
})
export class CarrierUpdateComponent implements OnInit {
  readonly confirmable: ConfirmableActionService;
  readonly form: CarrierForm;
  readonly service: CarrierService;
  readonly route: ActivatedRoute;
  readonly toast: ToastService;
  readonly router: Router;
  readonly carrier$: BehaviorSubject<AsyncState<Carrier>>;
  readonly tabs: Tab[];
  breadcrumbItems: BreadcrumbItem[] = [
    {
      label: 'Listagem de Tranportadoras',
      path: '/transportadoras',
    },
    {
      label: 'Ver Transportadora',
    },
  ];

  constructor() {
    this.confirmable = inject(ConfirmableActionService);
    this.form = new CarrierForm();
    this.service = inject(CarrierService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.carrier$ = new BehaviorSubject<AsyncState<Carrier>>({ loading: true });
    this.tabs = [
      {
        label: 'Cadastro geral',
        target: 'details',
        active: true,
        roles: [],
      },
      {
        label: 'Tipos de Entrega',
        target: 'deliveryTypes',
        active: false,
        roles: [],
      },
    ];
  }

  ngOnInit() {
    const { carrierId } = this.route.snapshot.params;
    this.fetchCarrierDetails(carrierId);
  }

  // #region fetchCarrierDetails
  fetchCarrierDetails(id: string) {
    this.service.fetchCarrierDetails(id).subscribe({
      next: this.onFetchCarrierDetailsSuccess.bind(this),
      error: this.onFetchCarrierDetailsFailure.bind(this),
    });
  }

  onFetchCarrierDetailsSuccess(carrier: Carrier) {
    this.form.setValue(carrier);
    this.form.instance.disable();
    this.carrier$.next({ data: carrier });
    this.breadcrumbItems[1].label = carrier.commercialName;
  }

  onFetchCarrierDetailsFailure(e: unknown) {
    this.toast.failure('Falha ao carregar os detalhes da transportadora.');
    this.carrier$.next({ failure: true });
  }
  // #endregion

  // #region updateCarrier
  updateCarrier(id: string, carrier: Carrier) {
    this.form.instance.disable();
    this.service.updateCarrier(id, carrier).subscribe({
      next: this.onUpdateCarrierSuccess.bind(this),
      error: this.onUpdateCarrierFailure.bind(this),
    });
  }
  onUpdateCarrierSuccess() {
    this.toast.success('Transportadora atualizada com sucesso.');
    this.router.navigate(['/transportadoras']);
  }
  onUpdateCarrierFailure(e: unknown) {
    this.form.instance.enable();
    this.toast.failure('Falha ao atualizar a transportadora.');
  }
  // #endregion

  handleEnableClickEvent() {
    this.form.instance.enable();
  }

  handleSubmitEvent() {
    if (this.form.instance.invalid) return;

    this.form.instance.enable();
    const { carrierId } = this.route.snapshot.params;
    this.updateCarrier(carrierId, this.form.value);
  }

  handleTabSelectEvent(tab: Tab) {
    const active = this.tabs.find((tab) => tab.active);
    if (active) active.active = false;
    tab.active = true;
  }

  onClickChangeStatus() {
    const { id, active, companyName } = this.form.value;
    const op = active ? 'desativando' : 'ativando';
    const name = companyName;

    this.confirmable.confirm(`Você está ${op} a transportadora "${name}"`).subscribe((confirmation) => {
      if (!confirmation.confirmed) return;

      this.service.updateStatus(id, !active).subscribe({
        next: () => {
          this.toast.success(`Status alterado com sucesso.`);
          this.form.controls.active.setValue(!active);
        },
        error: (e: unknown) => {
          this.toast.failure(`Falha ao alterar status`);
        },
      });
    });
  }
}
