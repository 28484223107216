import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleName',
  standalone: false,
})
export class RoleNamePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'portal':
      case 'reports':
        return 'Dashboard';
      case 'reports-admin':
        return 'Relatórios';
      case 'portal-sellers-admin':
        return 'Portal do Seller';
      case 'backoffice-admin':
        return 'Backoffice';
      case 'b2b':
        return 'B2B';
      case 'canal':
        return 'Canais de Vendas';
      case 'compradores':
        return 'Compradores';
      case 'compras':
        return 'Pedidos de Compra';
      case 'cupons':
        return 'Cupons';
      case 'fretes':
        return 'Fretes';
      case 'oficina':
        return 'Oficina';
      case 'produtos':
        return 'Produtos';
      case 'transportadoras':
        return 'Transportadoras';
      case 'usuarios':
        return 'Usuários';
      case 'vendas':
        return 'Pedidos de Vendas';
      case 'vendedores':
        return 'Vendedores';
      case 'devolucoes':
        return 'Devoluções';
      default:
        return value;
    }
  }
}
