import { Component, ViewChild, OnInit, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService, ToastService } from '@peca/commons';
import { AsyncState } from '../../../../../core/models/async-state.model';
import { ProfileForm } from './profile-form';
import { ProfileService } from '../../../services/profile.service';
import { ProfileDetails } from '../../../models/profile.model';
import { AutocompleteItemSource, BreadcrumbItem } from '@peca/design-system';
import { ProfileFeatureFormComponent } from './profile-feature-form/profile-feature-form.component';
import { Feature } from '../../../../permission/models/feature/feature.model';
import { FeatureAccordion } from '../../../models/profile-features.model';

@Component({
  selector: 'peca-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
  standalone: false,
})
export class ProfileFormComponent implements OnInit {
  @ViewChild('profileFeatureForm')
  profileFeatureForm!: ProfileFeatureFormComponent;
  service: ProfileService;
  route: ActivatedRoute;
  toast: ToastService;
  router: Router;
  analytics: AnalyticsService;

  form: ProfileForm;
  profile$: BehaviorSubject<AsyncState<ProfileDetails>>;
  breadcrumb: BreadcrumbItem[];
  features$: BehaviorSubject<AutocompleteItemSource>;
  featuresSelected$: BehaviorSubject<Feature[]>;

  constructor() {
    this.service = inject(ProfileService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.analytics = inject(AnalyticsService);

    this.breadcrumb = [{ label: 'Listagem de perfis', path: '/perfis' }];
    this.form = new ProfileForm();
    this.profile$ = new BehaviorSubject<AsyncState<ProfileDetails>>({ loading: true });
    this.features$ = new BehaviorSubject<AutocompleteItemSource>({});
    this.featuresSelected$ = new BehaviorSubject<Feature[]>([]);
  }

  get profileId() {
    const { profileId } = this.route.snapshot.params;
    return profileId as string;
  }

  get isUpdateOperation() {
    return !!this.profileId;
  }

  ngOnInit() {
    if (this.isUpdateOperation) {
      this.analytics.view('perfil_form_update');
      this.fetchProfile(this.profileId);
      return;
    }

    this.analytics.view('perfil_form_create');
    this.breadcrumb.push({ label: 'Novo perfil' });
    this.profile$.next({ loading: false });
    this.form.group.enable();
  }

  fetchProfile(profileId: string) {
    this.service.findById(profileId).subscribe({
      next: (profile: ProfileDetails) => {
        this.analytics.callback('perfil_busca_details', { profileId, success: true });
        this.form.group.disable();
        this.form.setValue(profile);
        this.featuresSelected$.next(profile.features);
        this.breadcrumb[1] = { label: profile.name, path: `/perfis/${this.profileId}` };
        this.profile$.next({ loading: false });
      },
      error: () => {
        this.analytics.callback('perfil_busca_details', { profileId, success: false });
        this.profile$.next({ failure: true });
      },
    });
  }

  onClickEnable() {
    this.form.group.enable();
  }

  onClickCancel() {
    this.fetchProfile(this.profileId);
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;

    const permission = this.profileFeatureForm.permissions$.value;

    const featuresIds = this.collectFeaturesIds(permission);

    const payload = this.form.getValue();
    payload.features = featuresIds;

    this.service.save(payload).subscribe({
      next: () => {
        this.analytics.callback('perfil_salva', { payload, success: true });
        this.toast.success('Perfil salvo com sucesso.');
        this.router.navigate(['perfis']);
      },
      error: () => {
        this.analytics.callback('perfil_salva', { payload, success: false });
        this.toast.failure('Falha ao salvar perfil.');
      },
    });
  }

  removeIfHasChange(featuresIdsWithoutChange: string[], feature: FeatureAccordion): void {
    const index = featuresIdsWithoutChange.findIndex((featureId) => featureId === feature.id);
    if (index !== -1) {
      featuresIdsWithoutChange.splice(index, 1);
    }
  }

  collectFeaturesIds(permission: any): string[] {
    const featuresWithoutChange = this.featuresSelected$.value;
    const featuresIdsWithoutChange = featuresWithoutChange.map((fetures) => fetures.id);

    const featuresIds: string[] = [];

    if (permission.platforms) {
      for (const platform of permission.platforms) {
        if (!platform.modules) continue;

        for (const module of platform.modules) {
          if (!module.features) continue;

          for (const feature of module.features) {
            this.removeIfHasChange(featuresIdsWithoutChange, feature);
            if (!feature.active) {
              continue;
            }

            featuresIds.push(feature.id);
          }
        }
      }
    }

    return [...featuresIds, ...featuresIdsWithoutChange];
  }
}
