import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsService, PersistQueryService, ToastService } from '@peca/commons';
import { Pagination, PaginationInitialStateV2, BreadcrumbItem } from '@peca/design-system';
import { AsyncState } from '../../../../../core/models/async-state.model';
import { Pageable } from '../../../../../core/models/pageable.model';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { ProfileService } from '../../../services/profile.service';
import { ProfileQuery } from '../../../models/profile-query.model';
import { Profile } from '../../../models/profile.model';

@Component({
  selector: 'peca-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
  standalone: false,
})
export class ProfileListComponent implements OnInit {
  private persistQueryService: PersistQueryService;
  private route: ActivatedRoute;
  private router: Router;
  private service: ProfileService;
  private lastQuery: ProfileQuery;
  private readonly searchFormInitialValue;
  private confirmable: ConfirmableActionService;
  private toast: ToastService;
  private analytics: AnalyticsService;

  form: FormGroup;
  profiles$: BehaviorSubject<AsyncState<Profile[]>>;
  breadcrumbItems: BreadcrumbItem[] = [{ label: 'Listagem de perfis', path: '/perfis' }];
  pagination$: BehaviorSubject<Pagination>;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.persistQueryService = inject(PersistQueryService);
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.service = inject(ProfileService);
    this.confirmable = inject(ConfirmableActionService);
    this.toast = inject(ToastService);
    this.lastQuery = {
      sort: 'audit.createdAt,asc',
      ...PaginationInitialStateV2,
    };
    this.searchFormInitialValue = {
      search: '',
    };

    this.form = new FormGroup({
      search: new FormControl(''),
    });
    this.profiles$ = new BehaviorSubject<AsyncState<Profile[]>>({ loading: true });
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialStateV2);
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.route.queryParams.subscribe((params) => this.updateFormWithQuery(params));
    this.analytics.view('perfis');

    const persistedQuery = this.persistQueryService.read<Profile>('peca-profile-list');
    const query = persistedQuery || this.lastQuery;
    this.fetchProfiles(query);
  }

  updateFormWithQuery(params: Params) {
    if (!params['q']) return;
    const { search } = JSON.parse(atob(params['q']));
    this.form.patchValue({
      search: search || '',
    });
  }

  fetchProfiles(query: ProfileQuery) {
    this.lastQuery = query;
    this.persistQueryService.rewriteQueryString('peca-profile-list', this.lastQuery);
    this.profiles$.next({ loading: true });
    this.service.fetchPlatforms(query).subscribe({
      next: (platforms) => {
        this.paginate(platforms);
        this.analytics.callback('perfil_busca', { query, success: true });
        this.profiles$.next({ data: platforms.items });
      },
      error: () => {
        this.analytics.callback('perfil_busca', { query, success: false });
        this.profiles$.next({ failure: true });
      },
    });
  }

  paginate(page: Pageable<unknown>) {
    const pagination = {
      page: page.currentPage,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    };
    this.pagination$.next({ ...pagination, page: pagination.page + 1 });
    this.persistQueryService.rewriteQueryString('peca-profile-list', { ...this.lastQuery, ...pagination });
  }

  onClickDetails(id: string) {
    this.analytics.click('perfil_click_detalhes');
    this.router.navigate(['/perfis', id]);
  }

  onClickRemove(profile: Profile) {
    const message = `Você está excluindo um perfil. Essa ação afetará todos os usuários associados a este perfil.`;

    this.confirmable.confirm(message, profile, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        return;
      }

      this.service.delete(profile.id).subscribe({
        next: () => {
          this.analytics.callback('perfil_delete', { profile, success: true });
          this.fetchProfiles(this.lastQuery);
          this.toast.success(`Perfil deletado com sucesso.`);
        },
        error: () => {
          this.analytics.callback('perfil_delete', { profile, success: false });
          this.toast.failure(`Falha ao deletar a perfil.`);
        },
      });
    });
  }

  onClickSearch() {
    const query = { ...this.lastQuery, ...this.form.value };
    this.fetchProfiles(query);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.onClickSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.form.markAsPristine();
    return this.form.markAsDirty();
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchProfiles(query);
  }
}
