import { Component, Input, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryType } from '../../models/delivery-type.model';

@Component({
  selector: 'peca-carrier-delivery-type-details[deliveryType]',
  templateUrl: './carrier-delivery-type-details.component.html',
  styleUrls: ['./carrier-delivery-type-details.component.scss'],
  standalone: false,
})
export class CarrierDeliveryTypeDetailsComponent {
  router: Router;
  route: ActivatedRoute;

  @Input()
  deliveryType!: DeliveryType;

  constructor() {
    this.router = inject(Router);
    this.route = inject(ActivatedRoute);
  }

  get id() {
    return this.deliveryType.id as string;
  }

  get carrierId() {
    return this.route.snapshot.params['carrierId'] as string;
  }

  handleEditEvent() {
    const id = this.id;
    const carrierId = this.carrierId;
    const route = `/transportadoras/${carrierId}/tipo-de-entrega/${id}`;
    this.router.navigate([route]);
  }

  handleViewRegionsEvent() {
    const id = this.id;
    const carrierId = this.carrierId;
    const route = `/transportadoras/${carrierId}/tipo-de-entrega/${id}/regioes-de-atuacao`;
    this.router.navigate([route]);
  }
}
