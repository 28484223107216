import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';

type PDSChipDirectiveType = 'default' | 'primary' | 'secondary' | 'terciary' | '';

@Directive({
  selector: 'span[pdsChip]',
  standalone: false,
})
export class PDSChipDirective implements OnInit {
  @Input()
  pdsChip: PDSChipDirectiveType = 'default';
  @Input()
  icon: string | undefined;
  elementRef = inject(ElementRef);
  renderer = inject(Renderer2);

  get hostElement() {
    return this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.renderer.addClass(this.hostElement, 'pds-chip');
    this.renderer.addClass(this.hostElement, `pds-chip-${this.pdsChip || 'default'}`);

    if (this.icon) {
      this.appendIconElement();
    }
  }

  private appendIconElement() {
    const content = this.hostElement.innerHTML;
    const icon = this.renderer.createElement('span');
    const contentWrapper = this.renderer.createElement('span');
    const hasChildren = this.hostElement.childNodes.length;

    this.renderer.addClass(icon, 'icon');
    icon.innerHTML = this.icon;

    if (hasChildren) {
      this.hostElement.childNodes.forEach((child: any) => {
        this.renderer.appendChild(this.hostElement, child);
      });
    } else {
      contentWrapper.innerHTML = content;
      this.renderer.appendChild(this.hostElement, contentWrapper);
    }

    this.renderer.appendChild(this.hostElement, icon);
  }
}
