import { inject, provideAppInitializer } from '@angular/core';
import { KeycloakService, KeycloakOptions } from 'keycloak-angular';

export const keyclockProviderFactory = (config: KeycloakOptions) => {
  return provideAppInitializer(() => {
    const initializerFn = (
      (keycloak: KeycloakService) => () =>
        keycloak.init(config)
    )(inject(KeycloakService));
    return initializerFn();
  });
};
