import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsService, PersistQueryService, ToastService } from '@peca/commons';
import { Pagination, PaginationInitialState } from '@peca/design-system';
import { PermissionModuleService } from '../../../services/module.service';
import PermissionModuleQuery from '../../../models/module/module-query.model';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { PermissionModule } from '../../../models/module/module.model';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

@Component({
  selector: 'peca-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss'],
  standalone: false,
})
export class ModuleListComponent implements OnInit {
  private persistQueryService: PersistQueryService;
  private route: ActivatedRoute;
  private router: Router;
  private service: PermissionModuleService;
  private lastQuery: PermissionModuleQuery;
  private readonly searchFormInitialValue;
  private confirmable: ConfirmableActionService;
  private toast: ToastService;
  private analytics: AnalyticsService;

  form: FormGroup;
  modules$: BehaviorSubject<AsyncState<PermissionModule[]>>;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.persistQueryService = inject(PersistQueryService);
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.service = inject(PermissionModuleService);
    this.confirmable = inject(ConfirmableActionService);
    this.toast = inject(ToastService);
    this.lastQuery = { sort: 'audit.createdAt,asc', ...PaginationInitialState };
    this.searchFormInitialValue = {
      search: '',
    };

    this.form = new FormGroup({
      search: new FormControl(''),
    });
    this.modules$ = new BehaviorSubject<AsyncState<PermissionModule[]>>({ loading: true });
  }

  get platformId() {
    const { platformId } = this.route.snapshot.params;
    return platformId as string;
  }

  ngOnInit() {
    this.analytics.view('modulo_listagem');
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.route.queryParams.subscribe((params) => this.updateFormWithQuery(params));

    const persistedQuery = this.persistQueryService.read<PermissionModuleQuery>('peca-module-list');

    const query = persistedQuery || this.lastQuery;
    this.fetchModules(query);
  }

  updateFormWithQuery(params: Params) {
    if (!params['q']) return;
    const { search } = JSON.parse(atob(params['q']));
    this.form.patchValue({
      search: search || '',
    });
  }

  fetchModules(query: PermissionModuleQuery) {
    this.lastQuery = query;
    this.modules$.next({ loading: true });
    this.service.fetchModules(query, this.platformId).subscribe({
      next: (module) => {
        this.analytics.callback('modulo_busca', { query, success: true });
        this.modules$.next({ data: module });
      },
      error: () => {
        this.analytics.callback('modulo_busca', { query, success: true });
        this.modules$.next({ failure: true });
      },
    });
  }

  onClickDetails(id: string) {
    this.analytics.click('modulo_click_detalhes');
    this.router.navigate(['/plataformas', this.platformId, id]);
  }

  onClickRemove(module: PermissionModule) {
    const message = `Você está deletando um módulo.`;

    this.confirmable.confirm(message, module, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        return;
      }

      this.service.delete(this.platformId, module.id).subscribe({
        next: () => {
          this.analytics.callback('modulo_delete', { module, success: true });
          this.toast.success(`Módulo deletada com sucesso.`);
          this.fetchModules(this.lastQuery);
        },
        error: () => {
          this.analytics.callback('modulo_delete', { module, success: false });
          this.toast.failure(`Falha ao deleta a módulo.`);
        },
      });
    });
  }

  onClickSearch() {
    const query = { ...this.lastQuery, ...this.form.value };
    this.fetchModules(query);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.onClickSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.form.markAsPristine();
    return this.form.markAsDirty();
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchModules(query);
  }
}
