import { Component, Input } from '@angular/core';

@Component({
  selector: 'pds-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss'],
  standalone: false,
})
export class FormRowComponent {
  @Input()
  grid: '1-1' | '2-1' | '1-2' | '2-2' | '1-1-1' | '2-1-1' | '1-1-2' | '1-2-1' | '1' | '1-1-1-1' | '1-1-1-1-1';

  constructor() {
    this.grid = '1';
  }
}
