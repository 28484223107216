import { Pipe, PipeTransform } from '@angular/core';
import { ShippingStatus } from '../models/tracking.model';

@Pipe({
  name: 'shippingStatus',
  standalone: false,
})
export class ShippingStatusPipe implements PipeTransform {
  transform(value: ShippingStatus, output: 'label' | 'color' = 'label') {
    switch (value) {
      case 'PENDING':
        return output === 'label' ? 'Pendente' : '#D3D3D3';
      case 'SEARCHING_CARRIER':
        return output === 'label' ? 'Buscando Transportadora' : '#B0C4DE';
      case 'COLLECTION_ROUTE':
        return output === 'label' ? 'Rota de Coleta' : '#ADD8E6';
      case 'SHIPPING_ROUTE':
        return output === 'label' ? 'Rota de Envio' : '#B0E0E6';
      case 'CANCELED':
        return output === 'label' ? 'Cancelado' : '#FFDAB9';
      case 'FAILED':
        return output === 'label' ? 'Falhou' : '#FFB6C1';
      case 'DELIVERED':
        return output === 'label' ? 'Entregue' : '#E0FFFF';
      default:
        return output === 'label' ? value : '#f4f4f4';
    }
  }
}
