<pds-module-header title="Vendas" ctaPath="/vendas" ctaLabel="Voltar" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <ng-container *ngIf="order$ | async; let order">
    <ng-container *ngIf="order.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="order.failure">
      <strong>Falha ao carregar pedido.</strong>
    </ng-container>

    <ng-container *ngIf="!order.loading && !order.failure && order.data">
      <div class="order-details-card">
        <div class="order-details-card__header">
          <div class="order-details-card__header__col --part-number">{{ order.data.commerceReference }}</div>
          <div class="order-details-card__header__col --buyer">{{ order.data.buyer.commercialName }}</div>
          <div class="order-details-card__header__col --status">
            <span [pdsChip]="order.data.internalStatus | orderStatusColor">{{ order.data.internalStatus | orderStatus }}</span>
          </div>

          <div class="order-details-card__header__col --value">
            <span>{{ order.data.finalPrice | currency : 'BRL' }}</span>
            <ng-container *ngIf="order.data.discounts?.amount">
              <span pdsChip="secondary">{{ order.data.discounts.amount | orderDiscount | currency : 'BRL' }}</span>
            </ng-container>
          </div>

          <div class="order-details-card__header__col --datetime">{{ order.data.audit?.createdAt | date : 'dd/MM/yy HH:mm' }}</div>
        </div>

        <div class="order-details-card__content">
          <peca-order-summary [order]="order.data" />

          <peca-order-steps [order]="order.data" />

          <pds-tabs [tabs]="tabs" (selected)="onTabSelected($event)">
            <router-outlet (activate)="onChildActivate($event)" />
          </pds-tabs>

          <div class="order-details-pricing">
            <div class="order-details-pricing__col">
              <div class="order-details-pricing__col__label">Produtos</div>
              <div class="order-details-pricing__col__value">{{ order.data | orderPricing : 'PRODUCTS' | currency : 'BRL' }}</div>
            </div>
            <div class="order-details-pricing__col">
              <div class="order-details-pricing__col__label">Frete</div>
              <div class="order-details-pricing__col__value">{{ order.data | orderPricing : 'FREIGHT' | currency : 'BRL' }}</div>
            </div>
            <div class="order-details-pricing__col">
              <div class="order-details-pricing__col__label">Sub total</div>
              <div class="order-details-pricing__col__value">{{ order.data | orderPricing : 'SUBTOTAL' | currency : 'BRL' }}</div>
            </div>
            <div class="order-details-pricing__col">
              <div class="order-details-pricing__col__label">Descontos</div>
              <div class="order-details-pricing__col__value">{{ order.data | orderPricing : 'DISCOUNTS' | currency : 'BRL' }}</div>
            </div>
            <div class="order-details-pricing__col">
              <div class="order-details-pricing__col__label">Total</div>
              <div class="order-details-pricing__col__value">{{ order.data | orderPricing : 'TOTAL' | currency : 'BRL' }}</div>
            </div>
          </div>

          <div class="order-details-card__content__observations">
            <div class="order-details-card__content__observations__col">
              <h4>Observações</h4>
              <p>{{ order.data.publicObservations || '-' }}</p>
            </div>
            <div class="order-details-card__content__observations__col">
              <h4 style="display: flex; align-items: center; gap: 8px">Observações Fiscais <span style="cursor: pointer; font-size: 16px" class="icon" (click)="invoiceObservations.open()">help</span></h4>
              <p>{{ order.data.invoiceObservations || '-' }}</p>
            </div>
            <div class="order-details-card__content__observations__col">
              <h4>Observações Internas</h4>
              <p>{{ order.data.internalObservations || '-' }}</p>
            </div>
          </div>

          <hr />

          <pds-form-actions>
            <button pdsButton *allowTo="[['vendas-editar-pedido', 'vendas-alterar-precos']]" (click)="onClickChangePrices()" title="Editar valores">Editar valores</button>
            <button pdsButton (click)="onClickAddObservation()" title="adicionar observação">adicionar observação</button>
            <button pdsButton (click)="onClickChangeStatus()" title="alterar status">alterar status</button>
            <button pdsButton (click)="onClickCancelOrder()" title="cancelar pedido">cancelar pedido</button>
            <button pdsButton (click)="onClickEmitInvoice()" title="emitir nota fiscal">emitir nota fiscal</button>
            <button pdsButton (click)="onClickReturnOrder()" title="devolução">devolução</button>
          </pds-form-actions>
        </div>
      </div>
    </ng-container>

    <peca-order-change-prices-modal #changePricesModal (adjust)="onAdjust()" />
    <peca-order-observation #orderObservation (adjust)="onAdjust()" />
    <peca-order-change-status #changeStatusComponent (adjust)="onAdjust()" />
    <peca-order-return #orderReturnComponent (adjust)="onAdjust()" />

    <pds-modal #invoiceObservations title="Observações Fiscais" [width]="420">
      <p>Observações fiscais são as informações adicionais da nota fiscal. Caso adicionada alguma observação, ela será adicionada a mensagem padrão e ficará como a mensagem abaixo:</p>
      <code>
        <p>
          Email do Destinatario: {{ buyerEmail }}; Inf. Contribuinte: Observacoes Em caso de DEVOLUCAO, por exigencia do SEFAZ para empresas contribuintes de ICMS e OBRIGATORIO a emissao da NF de devolucao. Pedido Interno:
          {{ order.data?.commerceReference }} POLITICA DE TROCA E DEVOLUCAO O prazo de devolucao da compra e de 7 dias contados a partir da data de entrega. O produto NAO PODE apresentar qualquer sinal de uso, marcas, manchas e etc. Deve
          ser devolvido dentro de sua embalagem original. A devolucao devera ser solicitada somente atraves do nosso canal de atendimento via Whatsapp: 11-98813-5570. No caso de PNEUS, NAO E POSSIVEL realizar a troca por devolucao ou
          arrependimento. {{ order.data?.invoiceObservations || '' }}
        </p>
      </code>
    </pds-modal>
  </ng-container>
</pds-module-header>
