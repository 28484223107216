import { Pipe, PipeTransform } from '@angular/core';
import { ShippingStatus } from '../models/tracking.model';

@Pipe({
  name: 'shippingStatusColor',
  standalone: false,
})
export class ShippingStatusColorPipe implements PipeTransform {
  transform(value: ShippingStatus) {
    switch (value) {
      case 'PENDING':
      case 'SEARCHING_CARRIER':
      case 'COLLECTION_ROUTE':
      case 'SHIPPING_ROUTE':
        return 'default';
      case 'CANCELED':
        return 'terciary';
      case 'FAILED':
        return 'primary';
      case 'DELIVERED':
        return 'secondary';
      default:
        return 'default';
    }
  }
}
