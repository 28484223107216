<pds-modal *ngIf="products.state$ | async as products" #modal title="Devolução de Pedido" [width]="1024" (dismiss)="onDismiss()">
  <ng-container *ngIf="products.data && products.data.length">
    <pds-form [formGroup]="orderReturnForm.group">
      <pds-form-row grid="1-1-1">
        <pds-form-field label="O cliente está com a peça?">
          <select formControlName="customerHavePart">
            <option value="" disabled>Selecione</option>
            <option value="false">Não</option>
            <option value="true">Sim</option>
          </select>
        </pds-form-field>
        <pds-form-field label="Método de pagamento">
          <input type="text" [value]="payment?.paymentMethod?.name" readonly disabled />
        </pds-form-field>
      </pds-form-row>
      <pds-form-row grid="1-1-1">
        <pds-form-field label="Categoria da devolução">
          <select formControlName="category">
            <option value="" disabled>Selecione</option>
            <option value="EXCHANGE">Troca</option>
            <option value="EXCHANGE_WARRANTY">Garantia</option>
            <option value="WITHDRAWAL">Desistência</option>
          </select>
        </pds-form-field>

        <pds-form-field label="Motivo">
          <select formControlName="reason">
            <option value="" disabled>Selecione</option>
            <option value="PURCHASE_REGRET">Arrependimento da Compra</option>
            <option value="APPLICATION_ERROR">Erro de Aplicação</option>
            <option value="PART_WARRANTY">Garantia da Peça</option>
            <option value="LATE_DELIVERY">Atraso de Entrega</option>
            <option value="STOCK_BREAK">Quebra de Estoque</option>
            <option value="OTHERS">Outros</option>
          </select>
        </pds-form-field>

        <pds-form-field label="Justificativa">
          <input type="text" placeholder="Descreva a justificativa da devolução" formControlName="justification" />
        </pds-form-field>
      </pds-form-row>

      <pds-form-row>
        <pds-checkbox label="O estorno será realizado através de crédito Peça Aí via Conta Corrente após a o processamento do pedido de devolução." formControlName="creditRefund" />
      </pds-form-row>
      <pds-form-row>
        <p class="order-return-disclaimer" *ngIf="!orderReturnForm.controls.bankSlipPayment.value; else noBankSlipPayment">
          <strong>Atenção:</strong> o box não assinalado, irá considerar e realizar o estorno através da mesma forma de pagamento utilizado no pedido (Pix ou Cartão de Crédito) quando a devolução for aprovada.
        </p>

        <ng-template #noBankSlipPayment>
          <p class="order-return-disclaimer"><strong>Atenção:</strong> Devoluções de pedidos pagos em conta corrente necessariamente são estornados em crédito.</p>
        </ng-template>
      </pds-form-row>
      <hr />
    </pds-form>
    <pds-form>
      <pds-form-section title="Produtos do Pedido">
        <p>Para garantir o registro correto do pedido, edite a lista abaixo apenas com os produtos e quantidades para devolução.</p>
        <pds-table>
          <table>
            <thead>
              <tr>
                <th style="width: 50%">Descrição</th>
                <th style="width: 10%">PN</th>
                <th style="width: 15%">Marca</th>
                <th>qtd.</th>
                <th>qtd. devolvida</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of products.data">
                <td>{{ product.description }}</td>
                <td>{{ product.partNumber }}</td>
                <td>{{ product.brand }}</td>
                <td>{{ product.quantity - (product.returnedQuantity || 0) }}</td>
                <td><input type="number" [(ngModel)]="product.quantityReturned" min="0" max="{{ product.quantity - product.returnedQuantity || 0 }}" (ngModelChange)="onChangeQuantity(product)" /></td>
              </tr>
            </tbody>
          </table>
          <span *ngIf="quantityError" class="validation-error">{{ quantityError }}</span>
        </pds-table>
      </pds-form-section>
      <pds-form-section *ngIf="orderReturnForm.returned.length" title="Produtos Devolvidos">
        <p>Abaixo estão os produtos devolvidos.</p>
        <pds-table>
          <table>
            <thead>
              <tr>
                <th style="width: 50%">Descrição</th>
                <th style="width: 10%">PN</th>
                <th style="width: 15%">Marca</th>
                <th>qtd.</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of orderReturnForm.returned">
                <td>{{ product.description }}</td>
                <td>{{ product.partNumber }}</td>
                <td>{{ product.brand }}</td>
                <td>{{ product.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </pds-table>
      </pds-form-section>

      <pds-form-actions>
        <button pdsButton [disabled]="orderReturnForm.invalid" (click)="onSubmit()">confirmar</button>
      </pds-form-actions>
    </pds-form>
  </ng-container>

  <ng-container *ngIf="products.loading">
    <strong>carregando...</strong>
  </ng-container>

  <ng-container *ngIf="products.failed">
    <strong>Não foi possível carregar os produtos deste pedido.</strong>
  </ng-container>

  <ng-container *ngIf="products.data && !products.data.length">
    <strong>Este pedido não possuí produtos.</strong>
  </ng-container>
</pds-modal>
