// TODO REFATORAR
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MultipleSelectPipe } from './multiple-select.pipe';
import { MultipleItem, MultipleItemKey } from './multiple-select.model';

@Component({
  selector: 'pds-multiple-select[source]',
  imports: [CommonModule, MultipleSelectPipe],
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleSelectComponent),
      multi: true,
    },
  ],
})
export class MultipleSelectComponent implements ControlValueAccessor, OnInit {
  private onChange!: (value: Array<MultipleItemKey>) => void;
  private onTouched!: () => void;
  private _value: Array<MultipleItemKey>;
  readonly active$: BehaviorSubject<boolean>;
  readonly disabled$: BehaviorSubject<boolean>;

  @Input()
  placeholder: string;

  @Input('source')
  source$: BehaviorSubject<MultipleItem[]>;

  constructor() {
    this._value = [];
    this.active$ = new BehaviorSubject<boolean>(false);
    this.placeholder = 'Selecione';
    this.source$ = new BehaviorSubject<MultipleItem[]>([]);
    this.disabled$ = new BehaviorSubject<boolean>(false);
  }

  ngOnInit() {
    this.source$.subscribe((source) => {
      const selecteds = source.filter((item) => item.selected).map((item) => item.id);
      this._value = selecteds;
    });
  }

  get value(): Array<MultipleItemKey> {
    return this._value;
  }

  set value(val: Array<MultipleItemKey>) {
    if (val !== this._value) {
      this._value = val;
      this.onChange(this._value);
    }
  }

  handleSelectEvent(item: MultipleItem) {
    item.selected = !item.selected;

    const selecteds = this.source$.value.filter(({ selected }) => selected).map(({ id }) => id);

    this.source$.next([...this.source$.value]);
    this.value = selecteds;
  }

  handleOpenEvent() {
    if (this.disabled$.value) return;
    this.active$.next(!this.active$.value);
    this.onTouched();
  }

  writeValue(value: Array<MultipleItemKey>) {
    if (!value || !value.length) {
      this.clear();
      this._value = [];
      return;
    }

    this._value = [];
    this.source$.next([
      ...this.source$.value.map((item) => ({
        ...item,
        selected: value.some((id) => id === item.id),
      })),
    ]);
  }

  registerOnChange(fn: (value: Array<MultipleItemKey>) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled$.next(isDisabled);
  }

  private clear() {
    const values = this.source$.value.map((value) => ({
      ...value,
      selected: false,
    }));

    this.source$.next([...values]);
    this.active$.next(false);
  }
}
