import { Component, Input } from '@angular/core';
import { TrackingDetails } from '../../../models/tracking-details.model';

@Component({
  selector: 'peca-tracking-summary',
  standalone: false,
  templateUrl: './tracking-summary.component.html',
  styleUrl: './tracking-summary.component.scss',
})
export class TrackingSummaryComponent {
  @Input()
  trackingDetails!: TrackingDetails;
}
