<pds-form-section title="Nova funcionalidade">
  <ng-container *ngIf="features$ | async; let features">
    <pds-form [formGroup]="form">
      <pds-form-row grid="1">
        <pds-form-field label="Busca">
          <input type="text" formControlName="search" placeholder="Nome" />
        </pds-form-field>
      </pds-form-row>

      <pds-form-actions>
        <button pdsButton="primary" (click)="clearFilters()" type="button" [disabled]="form.pristine">Limpar filtros</button>
        <button pdsButton type="submit" (click)="onClickSearch()">Buscar</button>
      </pds-form-actions>
    </pds-form>

    <hr />

    <ng-container *ngIf="features.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="features.failure">
      <strong>Não foi possível obter as funcionalidades.</strong>
    </ng-container>

    <ng-container *ngIf="features.data && !features.data?.length">
      <strong>Nenhuma funcionalidade cadastrada.</strong>
    </ng-container>

    <ng-container *ngIf="features.data && features.data?.length">
      <pds-table>
        <table>
          <thead>
            <tr>
              <th>Funcionalidade</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let feature of features.data">
              <td>{{ feature.role }}</td>
              <td pdsColActions>
                <button pdsButton="icon" (click)="onClickDetails(feature.id)">
                  <span class="icon">visibility</span>
                </button>
                <button pdsButton="icon" title="Remover" (click)="onClickRemove(feature)">
                  <span class="icon">delete</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </pds-table>
    </ng-container>
  </ng-container>
</pds-form-section>
