export interface Pagination {
  size: number;
  page: number;
  total: number;
  disabled?: boolean;
  items: number;
  sort?: string;
}

export const PaginationInitialState = {
  size: 12,
  page: 0,
  total: 1,
  items: 0,
  disabled: true,
};

export const PaginationInitialStateV2 = {
  size: 12,
  page: 1,
  total: 1,
  items: 0,
  disabled: true,
};
