<div class="order-summary">
  <div class="order-summary__row">
    <div class="order-summary__col">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">NFe</h4>
        <div class="order-summary__headline__value">
          @if (!order.invoice) {
          <span pdsChip>-</span>
          } @else if (order.invoice.nfNumber) {
          <span pdsChip="secondary" (click)="onClickPreviewInvoice(order)" icon="download">
            <span>Nº {{ order.invoice.nfNumber }}</span>
          </span>
          } @else {
          <span [pdsChip]="order.invoice.status | orderInvoiceStatusColor">{{ order.invoice.status | orderInvoiceStatus }}</span>
          }
        </div>
      </div>
    </div>
    <div class="order-summary__col">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">Un. de negócio</h4>
        <div class="order-summary__headline__value">{{ order.businessUnit?.acronym || '-' }}</div>
      </div>
    </div>
    <div class="order-summary__col">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">Razão Social / Nome</h4>
        <div class="order-summary__headline__value">{{ order.buyer.companyName }}</div>
      </div>
    </div>
    <div class="order-summary__col">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">CNPJ / CPF</h4>
        <div class="order-summary__headline__value">{{ order.buyer.cnpj }}</div>
      </div>
    </div>
    <div class="order-summary__col">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">Pedido Otimizado</h4>
        <div>
          <span [pdsChip]="order.optimized ? 'secondary' : 'default'">
            {{ order.optimized ? 'Sim' : 'Não' }}
          </span>
        </div>
      </div>
    </div>
    <div class="order-summary__col" *ngIf="order.parentOrder">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">Pedido original</h4>
        <div class="order-summary__headline__value">
          <a [routerLink]="['/vendas', order.parentOrder.id]" style="color: var(--color-gray-500)">
            {{ order.parentOrder.commerceReference }}
          </a>
        </div>
      </div>
    </div>

    <div class="order-summary__col" *ngIf="order.misplacedOrder">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">Pedido original</h4>
        <div class="order-summary__headline__value">
          <a [routerLink]="['/vendas', order.misplacedOrder.id]" style="color: var(--color-gray-500)">
            {{ order.misplacedOrder.commerceReference }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="order-summary__row">
    <div class="order-summary__col">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">Contato</h4>
        <div class="order-summary__headline__value">{{ order.buyer.contact || '-' }}</div>
      </div>
    </div>
    <div class="order-summary__col">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">Endereço de Entrega</h4>
        <div class="order-summary__headline__value">
          <ng-container *ngIf="order.shippingAddress; else noAddressTemplate">
            {{ order.shippingAddress | address }}
          </ng-container>
          <ng-template #noAddressTemplate>-</ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="order-summary__row" *ngIf="order.discounts?.vouchers?.length">
    <div class="order-summary__col">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">Descontos</h4>
        <div class="order-summary__headline__value --vouchers">
          <ng-container *ngFor="let vouhcer of order.discounts.vouchers">
            <a [routerLink]="['/cupons', vouhcer.id]">{{ vouhcer.code }}</a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="order-summary__row">
    <div class="order-summary__col">
      <div class="order-summary__headline">
        <h4 class="order-summary__headline__title">Canal de Venda</h4>
        <div class="order-summary__headline__value">
          <div class="order-summary__sales-channel">
            {{ order.salesChannel.name }}
            <span *ngIf="order.externalReference">[Ref. {{ order.externalReference }}]</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<peca-document-preview #documentPreview [disableDeleteAction]="true" />
