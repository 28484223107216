import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';

import { environment } from '../../../../environments/environment';
import { delay, Observable, of } from 'rxjs';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import FeatureQuery from '../models/feature/feature-query.model';
import { Feature, FeatureDetail } from '../models/feature/feature.model';

@Injectable({ providedIn: 'root' })
export class FeatureService extends BaseHttpClient {
  private resource: string;

  constructor() {
    super();
    this.resource = `${environment.gateway.url}/backoffice/v2/platforms`;
  }

  fetchFeatures(query: FeatureQuery, plataformId: string, moduleId: string) {
    const params = this.prepareQuery(query);
    return this.http.get<Feature[]>(`${this.resource}/${plataformId}/modules/${moduleId}/features`, { params });
  }

  findById(plataformId: string, moduleId: string, featureId: string): Observable<FeatureDetail> {
    return this.http.get<FeatureDetail>(`${this.resource}/${plataformId}/modules/${moduleId}/features/${featureId}`);
  }

  delete(platformId: string, moduleId: string, featureId: string) {
    return this.http.delete(`${this.resource}/${platformId}/modules/${moduleId}/features/${featureId}`);
  }

  updateFeatureState(featureId: string, enabled: boolean) {
    const body = { enabled };
    return this.http.patch(`${this.resource}/${featureId}`, body);
  }

  save(feature: Feature, platformId: string, moduleId: string): Observable<Feature> {
    const payload = {
      description: feature.description,
      role: feature.role,
    };

    if (feature?.id) {
      return this.http.put<Feature>(`${this.resource}/${platformId}/modules/${moduleId}/features/${feature.id}`, payload);
    } else {
      return this.http.post<Feature>(`${this.resource}/${platformId}/modules/${moduleId}/features`, payload);
    }
  }
}
