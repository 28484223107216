<div class="tracking-summary">
  <div class="tracking-summary__row">
    <div class="tracking-summary__col">
      <div class="tracking-summary__headline">
        <h4 class="tracking-summary__headline__title">Tipo de entrega</h4>
        <div class="tracking-summary__headline__value">{{ trackingDetails.deliveryTypeName }}</div>
      </div>
    </div>
    <div class="tracking-summary__col">
      <div class="tracking-summary__headline">
        <h4 class="tracking-summary__headline__title">Un. de negócio</h4>
        <div class="tracking-summary__headline__value">{{ trackingDetails.businessUnit.name || '-' }}</div>
      </div>
    </div>
    <div class="tracking-summary__col">
      <div class="tracking-summary__headline">
        <h4 class="tracking-summary__headline__title">Entrega no CD</h4>
        <div class="tracking-summary__headline__value">{{ trackingDetails.deliveryAtCD ? 'Sim' : 'Não' }}</div>
      </div>
    </div>
  </div>
</div>
