import { Component, OnInit, inject } from '@angular/core';
import { SellerService } from '../../services/seller.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, catchError, map, switchMap, tap, throwError } from 'rxjs';
import { Async } from '@peca/backoffice/utils/async.util';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'peca-seller-details-products',
  templateUrl: './seller-details-products.component.html',
  styleUrls: ['./seller-details-products.component.scss'],
  standalone: false,
})
export class SellerDetailsProductsComponent implements OnInit {
  sellerService: SellerService;
  route: ActivatedRoute;
  products: Async<any[]>;
  sellerId: string;
  form: FormGroup;

  constructor() {
    this.sellerService = inject(SellerService);
    this.route = inject(ActivatedRoute);
    this.products = new Async<any[]>();
    this.sellerId = '';
    this.form = new FormGroup({ search: new FormControl('', []) });
  }

  get sellerId$() {
    return this.route.parent?.params.pipe(map((params) => params['sellerId'] as string)) as Observable<string>;
  }

  get products$() {
    return this.products.state$;
  }

  ngOnInit() {
    this.sellerId$
      .pipe(
        tap(() => this.products.loading()),
        tap((id) => (this.sellerId = id)),
        switchMap((id) => this.sellerService.fetchSellerBranches(id)),
        catchError((e) => {
          this.products.failed();
          return throwError(() => new Error(e));
        })
      )
      .subscribe((branches) => this.products.loaded(branches));
  }
}
