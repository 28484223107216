import { Component, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService, ToastService } from '@peca/commons';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { PermissionModuleForm } from './feature-form';
import { PermissionContainerComponent } from '../../permission-container/permission-container.component';
import { FeatureDetail } from '../../../models/feature/feature.model';
import { FeatureService } from '../../../services/feature.service';
import { PermissionModuleService } from '../../../services/module.service';
import { PermissionModuleDetail } from '../../../models/module/module.model';
import { query } from '@angular/animations';

@Component({
  selector: 'peca-feature-form',
  templateUrl: './feature-form.component.html',
  styleUrls: ['./feature-form.component.scss'],
  standalone: false,
})
export class FeatureFormComponent {
  form: PermissionModuleForm;
  serviceFeature: FeatureService;
  serviceModule: PermissionModuleService;
  route: ActivatedRoute;
  toast: ToastService;
  router: Router;
  feature$: BehaviorSubject<AsyncState<FeatureDetail>>;
  permissionContainer: PermissionContainerComponent;
  analytics: AnalyticsService;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.serviceFeature = inject(FeatureService);
    this.serviceModule = inject(PermissionModuleService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.permissionContainer = inject(PermissionContainerComponent);
    this.form = new PermissionModuleForm();
    this.feature$ = new BehaviorSubject<AsyncState<FeatureDetail>>({ loading: true });
  }

  get platformId() {
    const { platformId } = this.route.snapshot.params;
    return platformId as string;
  }

  get moduleId() {
    const { moduleId } = this.route.snapshot.params;
    return moduleId as string;
  }

  get featureId() {
    const { featureId } = this.route.snapshot.params;
    return featureId as string;
  }

  get isUpdateOperation() {
    return !!this.featureId;
  }

  ngOnInit() {
    this.permissionContainer.breadcrumb = [{ label: 'Listagem de Plataformas', path: '/plataformas' }];
    this.permissionContainer.title = 'Funcionalidades';

    const lastPath = `/plataformas/${this.platformId}/${this.moduleId}`;
    this.permissionContainer.actionButtons = [
      {
        style: 'primary',
        path: lastPath,
        text: 'Voltar',
      },
    ];

    if (this.isUpdateOperation) {
      this.analytics.view('funcionalidade_form_update');
      this.fetchFeature(this.platformId, this.moduleId, this.featureId);
      return;
    }

    this.analytics.view('funcionalidade_form_create');
    this.fetchModule(this.platformId, this.moduleId);
  }

  fetchModule(platformId: string, moduleId: string) {
    this.serviceModule.findById(platformId, moduleId).subscribe({
      next: (module: PermissionModuleDetail) => {
        this.permissionContainer.breadcrumb.push(
          { label: module.platform.name, path: `/plataformas/${module.platform.id}` },
          { label: module.name, path: `/plataformas/${module.platform.id}/${module.id}` },
          { label: 'Nova funcionalidade' }
        );
        this.form.group.enable();
        this.feature$.next({ loading: false });
      },
      error: () => {
        this.feature$.next({ failure: true });
      },
    });
  }

  fetchFeature(platformId: string, moduleId: string, featureId: string) {
    this.serviceFeature.findById(platformId, moduleId, featureId).subscribe({
      next: (feature: FeatureDetail) => {
        this.analytics.view('funcionalidade_busca', { platformId, moduleId, featureId, success: true });
        this.onSuccessFetchFeature(feature);
      },
      error: () => {
        this.analytics.view('funcionalidade_busca', { platformId, moduleId, featureId, success: false });
        this.feature$.next({ failure: true });
      },
    });
  }

  onSuccessFetchFeature(feature: FeatureDetail) {
    this.form.group.disable();
    this.form.setValue(feature);
    this.feature$.next({ loading: false });

    this.permissionContainer.breadcrumb.push(
      { label: feature.module.platform.name, path: `/plataformas/${feature.module.platform.id}` },
      { label: feature.module.name, path: `/plataformas/${feature.module.platform.id}/${feature.module.id}` },
      { label: feature.role }
    );
    return;
  }

  onClickEnable() {
    this.form.group.enable();
    this.form.controls.role.disable();
  }

  onClickCancel() {
    this.router.navigate(['plataformas', this.platformId, this.moduleId]);
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;

    const payload = this.form.getValue();

    this.serviceFeature.save(payload, this.platformId, this.moduleId).subscribe({
      next: () => {
        this.analytics.view('funcionalidade_salva', { payload, success: true });
        this.toast.success('Funcionalidade salva com sucesso.');
        this.router.navigate(['plataformas', this.platformId, this.moduleId]);
      },
      error: (e) => {
        this.analytics.view('funcionalidade_salva', { payload, success: false });
        this.toast.failure('Falha ao salvar Funcionalidade.');
      },
    });
  }
}
