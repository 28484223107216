import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryDirection } from '../models/delivery-direction.model';

@Pipe({
  name: 'direction',
  standalone: false,
})
export class DeliveryDirectionPipe implements PipeTransform {
  transform(value: DeliveryDirection) {
    switch (value) {
      case 'NORTH':
        return 'Norte';
      case 'NORTHEAST':
        return 'Nordeste';
      case 'EAST':
        return 'Leste';
      case 'SOUTHEAST':
        return 'Sudeste';
      case 'SOUTH':
        return 'Sul';
      case 'SOUTHWEST':
        return 'Sudoeste';
      case 'WEST':
        return 'Oeste';
      case 'NORTHWEST':
        return 'Noroeste';
    }
  }
}
