import { Component, OnInit, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService, ToastService } from '@peca/commons';
import { AsyncState } from '../../../../../core/models/async-state.model';
import { PlatformService } from '../../../services/platform.service';
import { PlatformForm } from './platform-form';
import { PermissionContainerComponent } from '../../permission-container/permission-container.component';
import Platform from '../../../models/platforms/platform.model';

@Component({
  selector: 'peca-platform-form',
  templateUrl: './platform-form.component.html',
  styleUrls: ['./platform-form.component.scss'],
  standalone: false,
})
export class PlatformFormComponent implements OnInit {
  form: PlatformForm;
  service: PlatformService;
  route: ActivatedRoute;
  toast: ToastService;
  router: Router;
  platform$: BehaviorSubject<AsyncState<Platform>>;
  permissionContainer = inject(PermissionContainerComponent);
  analytics: AnalyticsService;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.service = inject(PlatformService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.form = new PlatformForm();
    this.platform$ = new BehaviorSubject<AsyncState<Platform>>({ loading: true });
  }

  get platformId() {
    const { platformId } = this.route.snapshot.params;
    return platformId as string;
  }

  get isUpdateOperation() {
    return !!this.platformId;
  }

  ngOnInit() {
    this.permissionContainer.title = 'Plataformas';
    this.permissionContainer.breadcrumb = [{ label: 'Listagem de Plataformas', path: '/plataformas' }];

    this.permissionContainer.actionButtons = [
      {
        style: 'primary',
        path: `/plataformas`,
        text: 'Voltar',
      },
    ];

    if (this.isUpdateOperation) {
      this.permissionContainer.actionButtons[1] = {
        style: 'default',
        path: `/plataformas/${this.platformId}/novo`,
        text: 'Novo módulo',
      };

      this.analytics.view('plataforma_form_update');
      const platformId = this.platformId;
      this.fetchPlatform(platformId);
      return;
    }

    this.analytics.view('plataforma_form_create');
    this.permissionContainer.breadcrumb.push({ label: 'Nova plataforma' });
    this.platform$.next({ loading: false });
    this.form.group.enable();
  }

  fetchPlatform(platformId: string) {
    this.service.findById(platformId).subscribe({
      next: (platform: Platform) => {
        this.analytics.callback('plataforma_busca_details', { platformId, success: true });
        this.form.group.disable();
        this.form.setValue(platform);
        this.permissionContainer.breadcrumb.push({ label: platform.name, path: `/plataformas/${this.platformId}` });
        this.platform$.next({ loading: false });
      },
      error: (e: unknown) => {
        this.analytics.callback('plataforma_busca_details', { platformId, success: false });
        this.platform$.next({ failure: true });
      },
    });
  }

  onClickEnable() {
    this.form.group.enable();
  }

  onClickCancel() {
    this.ngOnInit();
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;

    const payload = this.form.getValue();

    this.service.save(payload, this.platformId).subscribe({
      next: () => {
        this.analytics.callback('plataforma_salva', { payload, success: true });
        this.toast.success('Plataforma salva com sucesso.');
        this.router.navigate(['plataformas']);
      },
      error: (e) => {
        this.analytics.callback('plataforma_salva', { payload, success: false });
        this.toast.failure('Falha ao salvar plataforma.');
      },
    });
  }
}
