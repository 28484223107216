import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'formValidation',
  standalone: false,
})
export class FormValidationPipe implements PipeTransform {
  transform(errors: ValidationErrors | null | undefined) {
    if (!errors) return null;

    const [message] = Object.keys(errors).map((key) => errors[key].message);

    if (!message) return 'Campo inválido';

    return message;
  }
}
