import { Route } from '@angular/router';
import { PlatformListComponent } from './components/platforms/platforms-list/platform-list.component';
import { PlatformFormComponent } from './components/platforms/platform-form/platform-form.component';
import { ModuleFormComponent } from './components/modules/modules-form/module-form.component';
import { PermissionContainerComponent } from './components/permission-container/permission-container.component';
import { FeatureFormComponent } from './components/features/feature-form/feature-form.component';

export const routes: Route[] = [
  {
    path: '',
    component: PlatformListComponent,
  },
  {
    path: '',
    component: PermissionContainerComponent,
    children: [
      {
        path: 'nova',
        component: PlatformFormComponent,
      },
      {
        path: ':platformId',
        component: PlatformFormComponent,
      },
      {
        path: ':platformId/novo',
        component: ModuleFormComponent,
      },
      {
        path: ':platformId/:moduleId',
        component: ModuleFormComponent,
      },
      {
        path: ':platformId/:moduleId/nova',
        component: FeatureFormComponent,
      },
      {
        path: ':platformId/:moduleId/:featureId',
        component: FeatureFormComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

export const PermissionModuleLoad = () => import('./permission.module').then(({ PermissionModule }) => PermissionModule);
