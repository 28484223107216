import { AfterViewInit, Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

interface RangeDate {
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'pds-range-form',
  templateUrl: './range-form.component.html',
  styleUrls: ['./range-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeFormComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class RangeFormComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  disabled: boolean;
  changed!: (value: RangeDate) => void;
  touched!: () => void;
  form: FormGroup;
  destroyRef$: Subject<void>;

  constructor() {
    this.disabled = false;
    this.form = new FormGroup({ startDate: new FormControl(''), endDate: new FormControl('') });
    this.destroyRef$ = new Subject<void>();
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.complete();
  }

  ngAfterViewInit() {
    this.form.valueChanges.pipe(takeUntil(this.destroyRef$)).subscribe((value) => this.changed(value));
  }

  writeValue(value: RangeDate) {
    this.form.patchValue(value);
  }

  registerOnChange(fn: (value: RangeDate) => void) {
    this.changed = fn;
  }

  registerOnTouched(fn: () => void) {
    this.touched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
