import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Async } from '@peca/backoffice/utils/async.util';
import { AnalyticsService } from '@peca/commons';
import { OrderReturnLog } from '../../../models/orders-return-logs.model';
import { OrdersReturnService } from '../../../services/orders-return.service';

@Component({
  selector: 'peca-orders-return-details-logs',
  templateUrl: './orders-return-details-logs.component.html',
  styleUrls: ['./orders-return-details-logs.component.scss'],
  standalone: false,
})
export class OrderReturnDetailsLogsComponent implements OnInit, OnDestroy {
  destroyRef$: Subject<void>;
  orderReturnService: OrdersReturnService;
  route: ActivatedRoute;
  logs: Async<OrderReturnLog[]>;
  analytics: AnalyticsService;

  constructor() {
    this.destroyRef$ = new Subject<void>();
    this.orderReturnService = inject(OrdersReturnService);
    this.route = inject(ActivatedRoute);
    this.logs = new Async<OrderReturnLog[]>();
    this.analytics = inject(AnalyticsService);
  }

  get id() {
    return this.route.snapshot.parent?.params['id'] as string;
  }

  ngOnInit() {
    const id = this.id;
    this.fetchOrderReturnLogs(id);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  fetchOrderReturnLogs(id: string) {
    this.logs.loading();
    this.orderReturnService.fetchOrderReturnLogs(id).subscribe({
      next: (response) => {
        this.logs.loaded(response);
      },
      error: () => {
        this.logs.failed();
      },
    });
  }

  onRetryEvent() {
    this.fetchOrderReturnLogs(this.id);
  }
}
