import { FormControl, FormGroup } from '@angular/forms';
import Platform from '../../../models/platforms/platform.model';
import { Validators } from '@peca/commons';
import { Validators as NgValidators } from '@angular/forms';

export class PlatformForm {
  readonly group: FormGroup;

  constructor() {
    this.group = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.length.max(30)]),
      description: new FormControl('', [Validators.required, Validators.length.max(250)]),
      uniqueName: new FormControl('', [Validators.required, Validators.length.max(50), NgValidators.pattern(/^[a-zA-Z0-9-]+$/)]),
    });
  }

  get controls() {
    return {
      id: this.group.get('id') as FormControl,
      name: this.group.get('name') as FormControl,
      description: this.group.get('description') as FormControl,
      uniqueName: this.group.get('uniqueName') as FormControl,
    };
  }

  get enabled() {
    return this.group.enabled;
  }

  getValue() {
    return {
      id: this.controls.id.getRawValue(),
      name: this.controls.name.getRawValue(),
      description: this.controls.description.getRawValue(),
      uniqueName: this.controls.uniqueName.getRawValue(),
    } as Platform;
  }

  setValue(value: Platform) {
    this.controls.id.setValue(value.id);
    this.controls.name.setValue(value.name);
    this.controls.description.setValue(value.description);
    this.controls.uniqueName.setValue(value.uniqueName);
  }
}
