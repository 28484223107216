import { BehaviorSubject, debounceTime, Subject, switchMap } from 'rxjs';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { forwardRefProvider } from '@peca/backoffice/providers/forward-ref.provider';
import { PlatformService } from 'apps/backoffice/src/app/modules/permission/services/platform.service';
import { PermissionModuleService } from 'apps/backoffice/src/app/modules/permission/services/module.service';
import { FeatureService } from 'apps/backoffice/src/app/modules/permission/services/feature.service';
import { FeatureAccordion, ModuleAccordion, PermissionAccordion, PlatformAccordion } from '../../../../models/profile-features.model';
import { ToastService } from '@peca/commons';
import { ActivatedRoute } from '@angular/router';
import { Feature } from 'apps/backoffice/src/app/modules/permission/models/feature/feature.model';

@Component({
  selector: 'peca-profile-feature-form',
  templateUrl: './profile-feature-form.component.html',
  styleUrls: ['./profile-feature-form.component.scss'],
  providers: [forwardRefProvider(ProfileFeatureFormComponent)],
  standalone: false,
})
export class ProfileFeatureFormComponent {
  @Input('profileFeatures')
  profileFeatures!: Feature[];
  @Input('disabled')
  disabled!: boolean;
  route: ActivatedRoute;
  toast: ToastService;
  servicePlatform: PlatformService;
  serviceModule: PermissionModuleService;
  serviceFeature: FeatureService;

  featureToggleSubject: Subject<{ feature: FeatureAccordion; checked: boolean }>;
  permissions$: BehaviorSubject<PermissionAccordion>;

  constructor() {
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.servicePlatform = inject(PlatformService);
    this.serviceModule = inject(PermissionModuleService);
    this.serviceFeature = inject(FeatureService);

    this.permissions$ = new BehaviorSubject<PermissionAccordion>({ loading: true, platforms: [] });
    this.featureToggleSubject = new Subject<{ feature: FeatureAccordion; checked: boolean }>();
  }

  get profileId() {
    const { profileId } = this.route.snapshot.params;
    return profileId;
  }

  get isUpdateForm() {
    return !!this.profileId;
  }

  ngAfterContentInit() {
    this.fetchPlatforms();
  }

  fetchPlatforms() {
    this.permissions$.next({ loading: true });
    this.servicePlatform.fetchPlatforms({}).subscribe({
      next: (data: any) => this.permissions$.next({ platforms: data }),
      error: () => this.permissions$.next({ failure: true }),
    });
  }

  onClickExpandPlatform(platform: PlatformAccordion, index: number) {
    if (!platform.modules) {
      this.fetchModules(platform, index);
    }

    platform.expanded = !platform.expanded;
  }

  fetchModules(platform: PlatformAccordion, index: number) {
    platform.loading = true;
    this.serviceModule.fetchModules({}, platform.id).subscribe({
      next: (data: any) => {
        platform.modules = data;
      },
      error: () => (platform.failure = true),
      complete: () => (platform.loading = false),
    });
  }

  onClickExpandModule(platformId: string, module: any) {
    if (!module.features) {
      this.fetchFeatures(platformId, module);
    }

    module.expanded = !module.expanded;
  }

  fetchFeatures(platformId: string, module: ModuleAccordion) {
    module.loading = true;
    this.serviceFeature.fetchFeatures({}, platformId, module.id).subscribe({
      next: (features: Feature[]) => {
        const newFeatures = features.map((feature, i) => {
          const newFeature = { ...feature } as FeatureAccordion;
          if (this.profileFeatures.find((profileFeature) => profileFeature.id === feature.id)) {
            newFeature.active = true;
          }
          return newFeature;
        });

        module.features = newFeatures;
      },
      error: () => (module.failure = true),
      complete: () => (module.loading = false),
    });
  }

  onFeatureToggle(feature: FeatureAccordion, checked: boolean) {
    this.featureToggleSubject.next({ feature, checked });
  }

  onClickNoFeatures(module: ModuleAccordion) {
    if (!module.features) return;
    module.features = module.features.map((feature: FeatureAccordion) => ({ ...feature, active: false }));
  }

  onClickAllFeatures(module: ModuleAccordion) {
    if (!module.features) return;
    module.features = module.features.map((feature: FeatureAccordion) => ({ ...feature, active: true }));
  }

  onRetry() {
    this.fetchPlatforms();
  }
}
