<pds-module-header title="Perfis" ctaLabel="Voltar" ctaPath="/perfis">
  <pds-new-breadcrumb [items]="breadcrumb"></pds-new-breadcrumb>

  <pds-panel *ngIf="profile$ | async; let profile">
    <ng-container *ngIf="profile.loading"><strong>carregando...</strong></ng-container>
    <ng-container *ngIf="profile.failure"><strong>Não foi possível carregar os dados do perfil.</strong></ng-container>

    <ng-container *ngIf="!profile.loading && !profile.failure">
      <pds-form [formGroup]="form.group">
        <pds-form-section title="Informações do perfil">
          <pds-form-row grid="1-1">
            <pds-form-field label="Nome">
              <input type="text" formControlName="name" />
            </pds-form-field>
            <pds-form-field label="Descrição">
              <input type="text" formControlName="description" />
            </pds-form-field>
          </pds-form-row>
        </pds-form-section>

        <pds-form-section title="Funcionalidades">
          <peca-profile-feature-form #profileFeatureForm [disabled]="form.group.disabled" [profileFeatures]="(featuresSelected$ | async) || []" />
        </pds-form-section>

        <pds-form-actions>
          <ng-container *ngIf="isUpdateOperation && !form.group.enabled">
            <button pdsButton (click)="onClickEnable()">Editar</button>
          </ng-container>

          <ng-container *ngIf="form.group.enabled">
            <ng-container *ngIf="form.getValue().id && form.group.enabled">
              <button (click)="onClickCancel()" pdsButton="primary">Cancelar</button>
            </ng-container>
            <button pdsButton (click)="onClickSubmit()" [disabled]="form.group.invalid">salvar</button>
          </ng-container>
        </pds-form-actions>
      </pds-form>
    </ng-container>
  </pds-panel>
</pds-module-header>
