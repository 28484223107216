import { Component, EventEmitter, inject, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { CarrierQuery } from '../../models/carrier-query.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'peca-carrier-search',
  templateUrl: './carrier-search.component.html',
  styleUrls: ['./carrier-search.component.scss'],
  standalone: false,
})
export class CarrierSearchComponent {
  @Output()
  search: EventEmitter<CarrierQuery>;

  form: FormGroup;
  route: ActivatedRoute;
  private readonly searchFormInitialValue = { search: '' };

  constructor() {
    this.route = inject(ActivatedRoute);
    this.search = new EventEmitter<CarrierQuery>();
    this.form = new FormGroup({ search: new FormControl('') });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search } = JSON.parse(atob(params['q']));
      this.form.patchValue({
        search: search || '',
      });
    });
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
  }

  handleSearchClickEvent() {
    this.search.next(this.form.value);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.handleSearchClickEvent();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.form.markAsPristine();
    return this.form.markAsDirty();
  }
}
