<section class="pds-module-header">
  <div class="pds-module-header__header">
    <div class="pds-module-header__header__title">
      <h1>{{ title }}</h1>
    </div>

    <div class="pds-module-header__actions">
      <ng-container *ngTemplateOutlet="ctaTemplate"></ng-container>
    </div>

    <ng-container *ngIf="ctaPath">
      <a pdsButton="default" modifiers="modifier || ''" [routerLink]="ctaPath">{{ ctaLabel }}</a>
    </ng-container>
  </div>

  <ng-content></ng-content>
</section>
