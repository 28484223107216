import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderSimilarProduct } from '../../models/order-similar-product.model';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { OrderProduct } from '../../models/order-product.modal';

@Component({
  standalone: false,
  selector: 'peca-order-table-replace-seller',
  templateUrl: './order-table-replace-seller.component.html',
  styleUrl: './order-table-replace-seller.component.scss',
})
export class OrderTableReplaceSellerComponent {
  @Input('products') products!: AsyncState<OrderSimilarProduct[]> | null;
  @Output('clickSelectProduct') clickSelectProduct = new EventEmitter<OrderSimilarProduct>();
  @Input('currentProduct') currentProduct!: OrderProduct | undefined;
  @Input('onlySameSeller') onlySameSeller!: boolean;

  handleClickSelectProducts(product: OrderSimilarProduct) {
    this.clickSelectProduct.emit(product);
  }
}
