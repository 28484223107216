<pds-panel *ngIf="platform$ | async; let platform">
  <ng-container *ngIf="platform.loading"><strong>carregando...</strong></ng-container>
  <ng-container *ngIf="platform.failure"><strong>Não foi possível carregar os dados da plataforma.</strong></ng-container>

  <ng-container *ngIf="!platform.loading && !platform.failure">
    <pds-form [formGroup]="form.group">
      <pds-form-section title="Informações da plataforma">
        <pds-form-row grid="1-1-1">
          <pds-form-field label="Nome">
            <input type="text" formControlName="name" />
          </pds-form-field>
          <pds-form-field label="Descrição">
            <input type="text" formControlName="description" />
          </pds-form-field>
          <pds-form-field label="Nome único">
            <input type="text" formControlName="uniqueName" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-actions>
        <ng-container *ngIf="isUpdateOperation && !form.group.enabled">
          <button pdsButton (click)="onClickEnable()">Editar</button>
        </ng-container>

        <ng-container *ngIf="form.group.enabled">
          <ng-container *ngIf="form.getValue().id && form.group.enabled">
            <button (click)="onClickCancel()" pdsButton="primary">Cancelar</button>
          </ng-container>
          <button pdsButton (click)="onClickSubmit()" [disabled]="form.group.invalid">salvar</button>
        </ng-container>
      </pds-form-actions>

      @if(isUpdateOperation){
      <hr />
      <peca-module-list></peca-module-list>
      }
    </pds-form>
  </ng-container>
</pds-panel>
