import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class TimerService {
  timer$: Observable<number>;

  constructor() {
    this.timer$ = interval(1000);
  }

  getTimer() {
    return this.timer$;
  }
}
