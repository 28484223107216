<pds-panel *ngIf="feature$ | async; let feature">
  <ng-container *ngIf="feature.loading"><strong>carregando...</strong></ng-container>
  <ng-container *ngIf="feature.failure"><strong>Não foi possível carregar as funcionalidades.</strong></ng-container>

  <ng-container *ngIf="!feature.loading && !feature.failure">
    <pds-form [formGroup]="form.group">
      <pds-form-section title="Informações da funcionalidade">
        <pds-form-row grid="1-1">
          <pds-form-field label="Funcionalidade">
            <input type="text" formControlName="role" />
          </pds-form-field>
          <pds-form-field label="Descrição">
            <input type="text" formControlName="description" />
          </pds-form-field>
        </pds-form-row>

        <hr />
      </pds-form-section>

      <pds-form-actions>
        <ng-container *ngIf="isUpdateOperation && !form.group.enabled">
          <button pdsButton (click)="onClickEnable()">Editar</button>
        </ng-container>

        <ng-container *ngIf="form.group.enabled">
          <ng-container *ngIf="form.getValue().id && form.group.enabled">
            <button (click)="onClickCancel()" pdsButton="primary">Cancelar</button>
          </ng-container>
          <button pdsButton (click)="onClickSubmit()" [disabled]="form.group.invalid">salvar</button>
        </ng-container>
      </pds-form-actions>
    </pds-form>
  </ng-container>
</pds-panel>
